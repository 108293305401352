import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";

const WhyChooseEv = () => {
  
  return (
    <>
        
      <div className="auto-container pt-5">
        <div className="justify-content-md-center row">
          <div className="col-md-11 col-sm-12">
            <div className="sec-title centered">
              {/* <h2 className="title">EV AMC Service</h2> */}
              <h2 className="text-center">Why Choose VehicleCare For <font color="#52ae49">EV AMC</font>  Service?</h2>
            </div>

            <div className="detail">
              <div className="d-flex justify-content-center col inner-box">
                <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                  <div className="row">
                    <Col lg={6} sm={12}>
                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            Quality Service
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">Being in the car repair and servicing business for many years, VehicleCare has a strong brand value that promises its customers quality in terms of service and experience. We are proud of our excellent reputation for our reliability, honesty, and high-quality services. We prioritise our customers above everything else.</li>
                        </ul>
                      </div>

                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            Comprehensive Maintenance Coverage
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">VehicleCare AMC (Annual Maintenance Contract) Service offers comprehensive coverage for all aspects of electric vehicle maintenance. From routine inspection to major repairs, our AMC covers all major aspects of EV maintenance.</li>
                        </ul>
                      </div>

                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            Certified Professionals
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">We only employ the best and most skilled professionals who are certified in dealing with EV vehicle maintenance. Our technicians specialise in dealing with everything related to EV repair like motors, controllers, chargers, Li-ion batteries, differentials, etc. It is our priority that every customer that comes through our partners goes happy and satisfied.</li>
                        </ul>
                      </div>

                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            Genuine Parts Certified Professionals
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">All the parts of EV Vehicles provided by VehicleCare are genuine and all the products used by VehicleCare are of high-grade industry level. </li>
                        </ul>
                      </div>
                    </Col>

                    <Col lg={6} sm={12}>
                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            Cost-Effective Solution
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">Choosing a VehicleCare Annual Maintenance Contract is not an expense but an investment. This investment promises you a return on the good and long-term health of your EV vehicle and reliability for your EV fleet. It helps you prevent costly repairs and minimise downtime. Our services help you save money and maximise return on your fleet investment.</li>
                        </ul>
                      </div>

                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            Tailored Solution For EV Fleet
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">Electric Vehicles have unique maintenance requirements, and we understand that. That’s why VehicleCare our EV AMC service has been designed in a specific way to meet the needs of EV fleets, ensuring optimal performance, efficiency, and longevity. </li>
                        </ul>
                      </div>

                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            State-Of-The-Art Technology
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">We have a pan-India presence and all our workshops are equipped with the latest tools and machines that enable our professionals to perform on-the-spot repairs, even in the remotest locations.</li>
                        </ul>
                      </div>

                      <div class="card border-dark-subtle mb-2">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item bg-success">
                            <b><font color="#fffff" size="3">
                            Warranty
                            </font></b>
                          </li>
                          <li class="list-group-item bg-body-tertiary">We give our customers a Comprehensive warranty of 3 to 6 months on all repair services, ensuring quality and reliability.</li>
                        </ul>
                      </div>
                    </Col>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
    </>
  );
};

export default WhyChooseEv;
