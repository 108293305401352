import React from "react";
import { Modal } from "react-bootstrap";

function CustomModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose} keyboard={props.isCloseButton || false} backdrop="static" centered size={props.modalSize}>
      <Modal.Header closeButton={props.isCloseButton || false} style={{ borderBottom: "none" }}>
        {props.title ? <Modal.Title>{props.title}</Modal.Title> : ""}
        {props.subtitle ? <h3>{props.subtitle}</h3> : ""}
      </Modal.Header>
      <Modal.Body style={{ overflowY: "auto", maxHeight: "70vh" }}>{props.content}</Modal.Body>
    </Modal>
  );
}

export default CustomModal;


