import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { DentPaint, MechnicalRepair, InsuranceClaim } from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import { getNavigateUrl } from "../../../../../utils/customHelper";

const ThreeEasySteps = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="sec-title centered">
            {/* <h1 className="title">Solutions & Services</div> */}
            <h2>3 easy steps to make a claim: </h2>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <div className="row clearfix">
                <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box text-center">
                    <div className="image">
                      <Link to={getNavigateUrl("/services/denting-painting")}>
                        <img src={DentPaint} alt="Dent & Paint" className="w-50 mx-auto pt-4" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={getNavigateUrl("/services/denting-painting")}> Dent & Paint</Link>
                      </h5>
                      <div className="text">
                        We use spectrometer powered by Nippon Paint (Digital Tool to match the colour) that ensures highest accuracy colour matching on any car with 2 years of warranty.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box text-center">
                    <div className="image">
                      <Link to="/makeclaim">
                        <img src={InsuranceClaim} alt="Insurance Claim" className="w-50 mx-auto pt-4" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to="/makeclaim"> Insurance Claim </Link>
                      </h5>
                      <div className="text">To avoid action of obtaining financial compensation for your car repair we take insurance claim procedure to help you.</div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box text-center">
                    <div className="image">
                      <Link to={getNavigateUrl("/services/mechanical-repairs")}>
                        <img src={MechnicalRepair} alt="Mechnical Repair" className="w-50 mx-auto pt-4" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={getNavigateUrl("/services/mechanical-repairs")}> Mechnical Repair </Link>
                      </h5>
                      <div className="text">VehicleCare use the top-notch quality with OEM/OES genuine spare’s part only. </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ThreeEasySteps;
