import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { CART_LIST } from "../../../../store/types";

function TabsContent(props) {
  const servicePackagesList = props.servicePackageList.servicePackageList;
  const { selected_cateogary: cateogary_name, serviceList } = props.serviceList;
  const cateogary_id = serviceList?.filter((service) => service.name.toLowerCase() === cateogary_name?.replace(/-/g, " ").toLowerCase())?.[0]?.id;
  let cartList = props.cartList.cartList || [];
  const addToCartList = (id) => {
    const mainService = [1, 2, 3];
    let existService = cartList.filter((elem) => mainService.indexOf(parseInt(elem)) > -1);
    if (mainService.indexOf(parseInt(id)) > -1 && existService.length) {
      removeCart(existService[0]);
    }
    props.updateCart({ type: CART_LIST, payload: [...cartList, id] });
  };
  const removeCart = (id) => {
    cartList = cartList.filter((elem) => elem !== id);
    props.updateCart({ type: CART_LIST, payload: cartList });
  };

  const [isShown, setIsShown] = useState(false);
  const [noShow, setNoShow] = useState(true);
  const handleClick = (e) => {
    setIsShown((current) => !current);
    setNoShow((current) => !current);
  };
  const replaceImage = (error) => {
    error.target.src = require(`../../../../assets/images/service/service.png`);
  };

  const servicePackageData = servicePackagesList.filter((data) => data.cateogary_id === cateogary_id);
  return (
    <>
      <div className="blog-detail" style={{ textAlign: "left" }}>
        <div className="comments-area">
          {servicePackageData?.length ? (
            servicePackageData?.map((services) => {
              return (
                <>
                  <div className="comment-box">
                    <div className="comment">
                      <div className="author-thumb d-none d-md-block d-lg-block">
                        <img src={services.icon} onError={replaceImage} alt="" />
                      </div>
                      <div className="comment-inner">
                        <div className="comment-info clearfix mb-4">
                          <strong>{services.name} </strong>
                        </div>
                        {noShow && (
                          <Row>
                            {services.custom_points.slice(0, 5)?.map((points) => {
                              return (
                                <Col lg={6} md={6} sm={6} className="list-style-two" key={points.id}>
                                  <li>{points.name}</li>
                                </Col>
                              );
                            })}
                            {services.custom_points.length > 5 ? (
                              <Col lg={6} md={6} sm={6} className="list-style-two">
                                <button type="button" className="view_more" onClick={() => handleClick()}>
                                  + View More...
                                </button>
                              </Col>
                            ) : (
                              <></>
                            )}
                          </Row>
                        )}
                        {isShown && (
                          <Row>
                            {services.custom_points?.map((points) => {
                              return (
                                <Col lg={6} md={6} sm={6} className="list-style-two" key={points.id}>
                                  <li>{points.name}</li>
                                </Col>
                              );
                            })}
                          </Row>
                        )}
                        {/* </div> */}

                        <a className="comment-reply" href="/">
                          {"\u20A8"} {services.price}
                        </a>
                        <div className="place-order pull-right">
                          {!cartList.filter((element) => element === services.id).length ? (
                            <button type="button" className="theme-btn btn-style-nine btn-sm px-3 py-2" onClick={() => addToCartList(services.id)}>
                              <span className="txt">Add to cart +</span>
                            </button>
                          ) : (
                            <button type="button" className="theme-btn btn-style-nine btn-sm px-3 py-2" onClick={() => removeCart(services.id)}>
                              <span className="txt">Remove cart -</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <h3 className="text-center text-danger pt-5">Loading Content Please Wait...</h3>
          )}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  stateObj.cartList = state.default.cartListReducer;
  stateObj.serviceList = state.default.serviceListReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  updateCart: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(TabsContent);
