import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { SelectCar, LiveTracking, Warranty, FreePickupDrop } from "../../../../constants/ImageConstants";
import Toast from 'react-bootstrap/Toast';

const ServiceSteps = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="sec-title centered">
            <h2> Service In 4 Easy Steps </h2>
          </div>

          <div className="d-flex justify-content-center col">
            <Col xs={12} lg={11}>
              <Row className="mx-auto">

                <Col xs={12} lg={3} className="mb-1 px-lg-1">
                  <Toast>
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={4} lg={4}><img className="mx-auto w-100" src={SelectCar} alt="Select Your Car" /></Col>
                        <Col xs={8} lg={8} className="ps-0"><strong className="me-auto d-block text-dark">Select Your Car</strong>
                          <small className="d-block">Choose from our portfolio of cars.</small>
                        </Col>
                      </Row>
                    </Toast.Header>
                  </Toast>
                </Col>

                <Col xs={12} lg={3} className="mb-1 px-lg-1">
                  <Toast>
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={4} lg={4}><img className="mx-auto w-100" src={FreePickupDrop} alt="Free Pickup & Drop" /></Col>
                        <Col xs={8} lg={8} className="ps-0"><strong className="me-auto d-block text-dark">Free Pick-Up & Drop</strong>
                          <small className="d-block">Get free pick up & drop on all booked services.</small>
                        </Col>
                      </Row>
                    </Toast.Header>
                  </Toast>
                </Col>

                <Col xs={12} lg={3} className="mb-1 px-lg-1">
                  <Toast>
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={4} lg={4}><img className="mx-auto w-100" src={LiveTracking} alt="Hassel-Free" /></Col>
                        <Col xs={8} lg={8} className="ps-0"><strong className="me-auto d-block text-dark">Live Tracking</strong>
                          <small className="d-block">Get real-time updates on your car location & status.</small>
                        </Col>
                      </Row>
                    </Toast.Header>
                  </Toast>
                </Col>

                <Col xs={12} lg={3} className="mb-1 px-lg-1">
                  <Toast>
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={4} lg={4}><img className="mx-auto w-100" src={Warranty} alt="Warranty" /></Col>
                        <Col xs={8} lg={8} className="ps-0"><strong className="me-auto d-block text-dark">30 Days Warranty</strong>
                          <small className="d-block">Stay assured. Get 30 days warranty on all car services.</small>
                        </Col>
                      </Row>
                    </Toast.Header>
                  </Toast>
                </Col>

              </Row>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ServiceSteps;
