import { CART_LIST } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    cartList: [],
  },
  action = {}
) => {
  let cartItem = window.sessionStorage.getItem("cartItem");
  if (!state.cartList.length && cartItem) {
    state.cartList = JSON.parse(cartItem);
  }
  switch (action.type) {
    case CART_LIST:
      window.sessionStorage.setItem("cartItem", JSON.stringify(action.payload));
      return { ...state, cartList: action.payload };
    default:
      return state;
  }
};
