/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { ContactUsImg, Successful } from "../../../../constants/ImageConstants";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { getNavigateUrl } from "../../../../utils/customHelper";
import CustomModal from "../../../../components/common/CustomModal";
import { HelpFormListAction } from "../../../../store/action";
import { HELP_FORM_LIST, SHOW_HIDE_LOADER } from "../../../../store/types";

const ContactForm = (props) => {
  const navigate = useNavigate();
  const { helpFormList, submitHelpForm, customDispatch } = props;
  console.log(helpFormList);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    userName: "",
    phone: "",
    email: "",
    userMsg: "",
  });
  const handleClose = () => {
    navigate(getNavigateUrl("/"));
  };
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "userName") {
      if (!inputValue.match(/^[a-z A-Z\s]+$/) && inputValue.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span className="text-danger"></span>;
    }
    if (name === "phone") {
      if (!inputValue.match(/^[0-9]+$/) && inputValue.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span className="text-danger"></span>;
    }
    if (name === "email") {
      if (!inputValue.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) && inputValue.length > 0) {
        return <span className="text-danger">Invalid email address</span>;
      }
      return <span className="text-danger"></span>;
    }
    return <></>;
  };
  const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const [validated, setValidated] = useState(false);
  const GetModalContent = () => {
    return (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="col-4 text-center">
              <img src={Successful} alt="" className="w-100" />
            </div>

            <div className="col-8 text-left my-auto">
              <h4>
                <b>
                  <font color="black">Congratulations!!</font>
                </b>
              </h4>
              <h5 style={{ color: "#40c057" }}>
                <b>Your Enquiry is successfully Registered.</b>
              </h5>
              <h6>Our Executive will contact you soon.</h6>
              <button type="button" className="theme-btn btn-style-nine my-3 mx-5" onClick={() => navigate(getNavigateUrl("/"))}>
                <span className="txt">Home</span>
              </button>
            </div>
          </div>
        </div>
      </Row>
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
      const apiParam = {
        task: "getHelpForm",
        name: data.userName,
        email: data.email,
        phone: data.phone,
        message: data.userMsg,
      };
      submitHelpForm(apiParam);
    }
    setValidated(true);
  };
  useEffect(() => {
    if (helpFormList !== null) {
      setShow(true);
      customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
      customDispatch({ type: HELP_FORM_LIST, payload: null });
    }
  }, [helpFormList, customDispatch]);
  return (
    <>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="form-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="sec-title">
                <div className="title">Reach out to us</div>
                <h2 className="">Contact Us</h2>
              </div>

              <div className="contact-form">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group className="column col-md-6 form-group">
                      <Form.Label className="d-none d-md-block d-lg-block">Name</Form.Label>
                      <Form.Control type="text" name="userName" placeholder="Your Name" required value={data.userName} onChange={(e) => handleChange(e)} />
                      <Form.Control.Feedback type="invalid">Please enter your Name</Form.Control.Feedback>
                      <ErrorOutput case={data.userName} name={"userName"} />
                    </Form.Group>

                    <Form.Group className="column col-md-6 form-group">
                      <Form.Label className="d-none d-md-block d-lg-block">Phone</Form.Label>
                      <Form.Control type="text" maxLength={10} name="phone" placeholder="Your Phone" required value={data.phone} onChange={(e) => handleChange(e)} />
                      <Form.Control.Feedback type="invalid">Please enter your Phone</Form.Control.Feedback>
                      <ErrorOutput case={data.phone} name={"phone"} />
                    </Form.Group>
                  </Row>

                  <Form.Group className="form-group">
                    <Form.Label className="d-none d-md-block d-lg-block">Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Your Email" required value={data.email} onChange={(e) => handleChange(e)} />
                    <Form.Control.Feedback type="invalid">Please enter your Email</Form.Control.Feedback>
                    <ErrorOutput case={data.email} name={"email"} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-none d-md-block d-lg-block">Message For Us</Form.Label>
                    <Form.Control as="textarea" name="userMsg" placeholder="Type your message here..." value={data.userMsg} onChange={(e) => handleChange(e)} />
                    <Form.Control.Feedback type="invalid">Please enter your Message</Form.Control.Feedback>
                    <ErrorOutput case={data.userMsg} name={"userMsg"} />
                  </Form.Group>

                  <div className="form-group">
                    <button className="theme-btn btn-style-nine" type="submit" name="submit-form">
                      <span className="txt">Contact Now</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <div className="map-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="map-boxed">
                <div className="map-outer">
                  <img src={ContactUsImg} alt="contact us" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal show={show} modalSize="lg" content={<GetModalContent />} isCloseButton={true} handleClose={handleClose} />
    </>
  );
};
const mapStateToProps = (state) => {
  return state.default.helpFormListReducer;
};
const mapDispatchToProps = (dispatch) => ({
  submitHelpForm: (params) => HelpFormListAction.getHelpForm(params, dispatch),
  customDispatch: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
