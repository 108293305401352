import { FAQ_LIST } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    faqList: [],
  },
  action = {}
) => {
  switch (action.type) {
    case FAQ_LIST:
      return { ...state, faqList: action.payload };
    default:
      return state;
  }
};
