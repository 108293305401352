import React from "react";
import { Row } from "react-bootstrap";
import { Failed } from "../../../constants/ImageConstants";

const ServicePaymentFail = () => {
  return (
    <Row>
      {/* Error Section */}
      <section className="error-section h-100">
        <div className="auto-container">
          <div className="content my-auto">
            <img src={Failed} className="img-fluid mb-4 w-25" alt="Payment Failed" />
            <h2>Payment Failed</h2>
            <div className="text">Unfortunately payment is failed.</div>
            <div className="text">But Your service is Booked as CASH.</div>
            <a href="/" className="theme-btn btn-style-two">
              <span className="txt">Back</span>
            </a>
          </div>
        </div>
      </section>
    </Row>
  );
};

export default ServicePaymentFail;
