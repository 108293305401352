import { INSURANCE_COMPANY_LIST } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
    state = {
        insuranceCompanyList: [],
    },
    action = {}
) => {
    switch(action.type){
        case INSURANCE_COMPANY_LIST:
            return {...state, insuranceCompanyList: action.payload};
        default:
            return state;
    }
};