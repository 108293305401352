import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Link } from "react-router-dom";
import { TotalEnergiesVehicleCare } from "../../../../constants/ImageConstants";
import { getNavigateUrl } from "../../../../utils/customHelper";

const BannerSection = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="content-boxed">
            <div className="">
              <div className="row clearfix">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 text-left mb-0 text-center">
                  {/* <h1>Vehiclecare For Fleet</h1> */}

                  {/* <h1 className="title">
                    Quartz: Driving lubricants innovation for all types of
                    engines
                  </div> */}
                  <img src={TotalEnergiesVehicleCare} alt="" />
                  <div className="btns-box mt-0">
                    <Link className="theme-btn btn-style-nine me-3" to={getNavigateUrl("/services/periodic-car-services")}>
                      <span className="txt">Book Service</span>
                    </Link>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12 mb-0">
                  <div className="inner-column">
                    <div className="image">
                      <img src="https://totalenergies.in/system/files/styles/cover_slider_762px/private/atoms/image/totalenergies_lubricant_home_quartz.jpg?itok=ZIm7mzgB" alt="" />
                      {/* <img src={TotalEnergies-VehicleCare} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BannerSection;
