import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const Dcx = ({ isMerchant = false }) => {
  // const navigate = useNavigate();
  // const customNavigation = () => {
  //   if (isMerchant) {
  //     navigate("/claim");
  //   } else {
  //     navigate("/makeclaim");
  //   }
  // };
  return (
    <Row>
      <Col>
        
        {/*Insurance Companies as our Valued Partners  */}
        <div className="auto-container pb-5">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="content-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title text-left px-2">
                    {/* <div className="title">Digital Claim eXperience </div> */}
                    <h2 className="">Insurance Companies as our Valued Partners</h2>
                  </div>
                  <div className="text px-2">
                    At VehicleCare, we value our partnerships with leading insurance companies. By collaborating closely with insurers, we aim to enhance the overall claims experience for our customers. Our partnerships enable us to seamlessly process cashless insurance claims, providing you with a hassle-free and efficient service.
                  </div>

                  <div className="text px-2">
                    VehicleCare’s Strategic alliance with leading insurance companies play a pivotal role in fulfilling our promise and cashless car insurance claims to our customers.
                  </div>
                </div>

                <div className="detail px-5">
                  <div className="d-flex justify-content-center col inner-box">
                    <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                      <div className="sec-title mb-2">
                        <h4 className="text-left text-dark">
                          <b>
                          What Makes our Collaboration with Insurance Companies so Special?
                          </b>
                        </h4>
                      </div>

                      <p className="mb-1">
                        <b><font color="#ed1c24" size="4">1. </font></b>
                        Network Of Trust
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">2. </font></b>
                        Streamlined Communication
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">3. </font></b>
                        Cashless Transaction Integration
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">4. </font></b>
                        Enhanced Service Option
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">5. </font></b>
                        Transparent Documentation
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">6. </font></b>
                        Keeping Updated with Best Industry Practices and Emerging Trends
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* DCX */}
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="content-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title text-left">
                    {/* <div className="title">Digital Claim eXperience </div> */}
                    <h2 className="">What is <font color="#ed1c24" >DCX?</font></h2>
                  </div>
                  <div className="text px-2">
                    DCX is VehicleCare’s AI-enabled innovative service for cashless insurance claims, crafted especially for you. Whether it’s an unexpected car accident, standalone damage or any other issue where you need to claim Insurance for your car, we are well-equipped to deal with every single case.
                  </div>

                  <div className="text px-2">
                    VehicleCare DCX service shields you from the trouble of going through the painful process involved in the car insurance claim process. The specially curated DCX service by VehicleCare streamlines the whole process of car insurance claims.
                  </div>
                </div>

                <div className="detail px-5">
                  <div className="d-flex justify-content-center col inner-box">
                    <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                      <div className="sec-title mb-2">
                        <h4 className="text-left text-dark">
                          <b>
                          Benefits of VehicleCare DCX Service
                          </b>
                        </h4>
                      </div>

                      <p className="mb-1">
                        <b><font color="#ed1c24" size="4">1. </font></b>
                        Efficiency Through Digital Innovation: VehicleCare's Digital Claim Experience service is driven by digital innovation, where we leverage advanced AI technology and digital workshops resulting in a highly efficient and streamlined process, therefore, reducing assessment time and the claim process.
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">2. </font></b>
                        Multi-Brand Expertise: One of the key advantages of choosing VehicleCare is our expertise in servicing a wide range of vehicle brands. Our skilled technicians are well-versed in handling repairs for various makes and models, ensuring that your vehicle receives the specialized care it deserves. 
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">3. </font></b>
                        Seamless Cashless Claims: VehicleCare has strong partnerships with leading insurance companies, which enable us to offer seamless cashless claims. This means you can enjoy the convenience of a hassle-free claims process without the need for out-of-pocket expenses, making the entire experience stress-free.
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">4. </font></b>
                        Transparent Communication and Cost Estimation: Transparency is one of the core values at VehicleCare. We ensure open and clear communication with customers throughout the insurance claims process. We provide detailed cost estimates before proceeding with any repairs, ensuring that you are informed and in control of the financial aspect of the claims.
                      </p>

                      <p className="mb-1"><b><font color="#ed1c24" size="4">5. </font></b>
                        Comprehensive End-to-End Solution: VehicleCare doesn't just stop at damage assessment; we provide a comprehensive end-to-end solution for car insurance claims. From the initial reporting of the incident to the final repair and delivery of your vehicle, our DCX service covers every aspect and guides you through the whole process, offering you a complete and worry-free experience.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Dcx;
