import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import Accordion from "react-bootstrap/Accordion";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const ServiceFaq = (props) => {
  const active = { backgroundColor: "black", color: "white" };
  const inactive = {};
  const [toggle, setToggle] = useState(true);

  const [isActive, setIsActive] = useState(false);

  function handleToggle(val) {
    if (toggle === val) {
      setIsActive(!isActive);
    } else {
      setIsActive(true);
    }
    setToggle(val);
  }

  const { vehicleData, cityData } = props;
  const { selectedCity } = cityData;
  const urlParam = useParams();
  const cgID = urlParam.id;
  const { brand, model, variant } = vehicleData;
  const getCustomHelmetDetails = (cgID) => {
    let returnVal = [];
    if (variant === null || variant === undefined) return returnVal;
    switch (cgID) {
      case "periodic-car-services":
        returnVal = [
          {
            title: `Why should I get Periodic Car Service for my ${brand?.name} ${model?.name} car from VehicleCare?`,
            description: `Regular periodic car service from VehicleCare ensures your ${brand?.name} ${model?.name}'s optimal performance and longevity. VehicleCare uses advanced diagnostics, genuine parts and follows manufacturers guidelines with meticulous attention to details. VehicleCare guarantees a smooth and safe driving experience, enhancing your car's overall reliability and resale value.`,
          },
          {
            title: `What is the recommended frequency for servicing my ${brand?.name} ${model?.name} with VehicleCare?`,
            description: `VehicleCare recommends servicing your ${brand?.name} ${model?.name} at least once a year or after every 10,000kms, whichever comes first, in order to maintain vehicle’s optimal performance.`,
          },
          {
            title: `Do I need to service my ${brand?.name} ${model?.name} Car even if it's relatively new?`,
            description: `Yes, regular Servicing is crucial for your vehicle regardless of your car's age. It helps identify the potential issues in your car early, preventing costly repairs and ensuring long term reliability.`,
          },
          {
            title: `What does a periodic car service for my ${brand?.name} ${model?.name} include?`,
            description: `VehicleCare's ${brand?.name} ${model?.name} service includes a comprehensive inspection, fluid checks, filter replacements, brake inspection, tire rotation, and other essential tasks outlined in the manufacturer's service schedule.`,
          },
          {
            title: `How long does a typical ${brand?.name} ${model?.name} service at VehicleCare take?`,
            description: `The duration may vary for every Car, but on average, a periodic service for an ${brand?.name} ${model?.name} takes around 3 to 4 hours. However, this can change based on additional repairs or any other services needed.`,
          },
          {
            title: `How to know if my ${brand?.name} ${model?.name} Car needs standard service or comprehensive service?`,
            description: `VehicleCare’s standard service is a general service package that includes basic car services and inspection and is recommended after every 10,000 kms. Whereas, a comprehensive service is required after every 40,000 kms and is a more elaborate service package which includes top to bottom servicing, replacement and maintenance.`,
          },
          {
            title: `What makes VehicleCare ${brand?.name} ${model?.name} periodic service unique from ${brand?.name} ${model?.name} authorised service centres?`,
            description: `VehicleCare is simple, pocket-friendly, efficient and reliable. You can book car service for your ${brand?.name} ${model?.name} from the tip of your finger, from home, office or anywhere, pick-up and drop location is your choice. You get great deals and discounts every time you opt for a service from VehicleCare. Just book a service from VehicleCare app or website and relax.`,
          },
          {
            title: `Is there a warranty on the ${brand?.name} ${model?.name} periodic service from VehicleCare?`,
            description: `VehicleCare offers a limited warranty on parts and labour for the services performed during the periodic maintenance of your ${brand?.name} ${model?.name}.`,
          },
          {
            title: `How do I know that VehicleCare uses genuine parts for my ${brand?.name} ${model?.name} Car Service?`,
            description: `VehicleCare takes pride in delivering only the top quality industry standard and genuine car parts from its channel of authorised dealers. Therefore, any car parts at VehicleCare are 100% genuine and come with a limited warranty period.`,
          },
          {
            title: `How can I check the status of my ${brand?.name} ${model?.name} service at VehicleCare?`,
            description: `VehicleCare provides regular updates during the service process, and you can contact our 24x7 customer support directly for real-time information about your ${brand?.name} ${model?.name}'s service status or track your service on the VehicleCare app or through Whatsapp`,
          },
        ];
        break;
      case "denting-painting":
        returnVal = [
          {
            title: `What types of vehicles does VehicleCare cater to in ${selectedCity} for denting and painting services?`,
            description: `VehicleCare specialises in denting and painting services for a wide range of vehicles, including cars, SUVs, and luxury models like ${brand?.name}.`,
          },
          {
            title: `What kind of damages can be repaired on my ${brand?.name} ${model?.name} at VehicleCare workshops at ${selectedCity}?`,
            description: `VehicleCare ${selectedCity} workshops are equipped to repair any kind of sheet metal damages in your car, like dents and tear, or paint damages like scratches, blemishes, cuffs or paint chips, plastic or fibre paint damage on your ${brand?.name} ${model?.name}.`,
          },
          {
            title: `Does VehicleCare use genuine manufacturer-approved paints for denting and painting services?`,
            description: `Yes, VehicleCare strictly adheres to using genuine and manufacturer-approved paints, ensuring an exact color match and maintaining the integrity of your vehicle's finish.`,
          },
          {
            title: `Can VehicleCare match the paint color exactly to my ${brand?.name} ${model?.name} include?`,
            description: `We use premium industry grade paints over grade A- primer and specialised color palettes approved by ${brand?.name} which offers exact match with your original ${brand?.name} ${model?.name} color.`,
          },
          {
            title: `How much time does denting-painting repair take?`,
            description: `A single panel on your ${brand?.name} ${model?.name} Car may take upto 24-48 hrs due to the elaborative procedures involved with is followed by different steps like, rubbing, polishing, buffing. A full Car body denting and painting for ${brand?.name} ${model?.name} may take upto a week depending upon different other factors.`,
          },
          {
            title: `Are there different charges for denting and painting?`,
            description: `The charges of both denting and painting may vary depending upon the degree of damage to the car. A minor dent on your car body also includes the painting charge, however, for big dents and damages, denting and painting services can be charged separately.`,
          },
          {
            title: `Is there any warranty on my car painting services at VehicleCare workshops?`,
            description: `Yes, VehicleCare provides warranty on every car painting service.`,
          },
          {
            title: `Can VehicleCare assist with insurance claims for denting and painting services?`,
            description: `Absolutely. Our team is experienced in coordinating with insurance providers to simplify the claims process for our customers.`,
          },
          {
            title: `Is VehicleCare equipped to handle specific requirements for high-end vehicles like ${brand?.name} during denting and painting services?`,
            description: `Yes, VehicleCare has expertise in handling high-end vehicles, including ${brand?.name}. Our technicians are trained to meet the specific standards and requirements of luxury car repairs.`,
          },
          {
            title: `What do customers say about their experiences with VehicleCare's denting and painting services in ${selectedCity}?`,
            description: `Customers consistently praise VehicleCare for its professionalism, attention to detail, and the overall quality of denting and painting services. You can find testimonials on our website or inquire at our service centre for customer feedback.`,
          },
        ];
        break;
      case "car-ac-services":
        returnVal = [
          {
            title: `Why is regular car AC maintenance important?`,
            description: `Regular maintenance ensures optimal performance, prevents breakdowns, and contributes to a comfortable driving experience. It also helps identify and address issues before they become major problems.`,
          },
          {
            title: `How often should I service my car's AC system?`,
            description: `VehicleCare recommends servicing your car's AC system annually or if you notice reduced cooling efficiency, strange odors, or unusual sounds. Such occurrences happen mostly because of AC gas leakage issues.`,
          },
          {
            title: `Why AC in my car is making noises?`,
            description: `Reason for noise in your car AC could be becaus of clogged evaporator coil or a faulty compressor. A noisy car AC is a direct indication that you should get it checked immediately.`,
          },
          {
            title: `Why is my car ac not blowing air properly?`,
            description: `Low air blow from car AC could be an indication of problem with the evaporator coil or a dirty or clogged air filter or clogged drainpipe. It could also be because of broken fab blower inside car AC vent. To avoid further damage, we highly recommend you to visit your nearest VehicleCare workshop in ${selectedCity} and get it fixed.`,
          },
          {
            title: `Is VehicleCare specialized in servicing specific car brands for AC systems?`,
            description: `Yes, VehicleCare specializes in servicing a wide range of car brands, including high-end models like ${brand?.name}, ensuring expert care for diverse AC systems.`,
          },
          {
            title: `What all are included in the comprehensive Car AC Service provided by VehicleCare?`,
            description: `VehicleCare's comprehensive car AC service includes a thorough inspection, refrigerant level check, pressure testing, condenser and evaporator cleaning, thermostat checks, air filter replacement, and overall performance testing. The service also includes AC gas recharge, pressure test, condenser cleaning, compressor oil replacement,etc. `,
          },
          {
            title: `Can I book a car AC service appointment online?`,
            description: `Yes, VehicleCare provides an online booking system, allowing you to conveniently schedule your car AC service appointment.`,
          },
        ];
        break;
      case "mechanical-repairs":
        returnVal = [
          {
            title: `Does VehicleCare have expertise to do Mechanical services for my ${brand?.name} ${model?.name} Car?`,
            description: `Yes, VehicleCare specializes in providing expert mechanical repairs specifically tailored for ${brand?.name} ${model?.name} cars, ensuring precision and adherence to ${brand?.name} standards.our team consists of certified technicians with specialized training in ${brand?.name} models, including the ${brand?.name} ${model?.name}.`,
          },
          {
            title: ` What types of mechanical issues can VehicleCare address for ${brand?.name} ${model?.name} cars?`,
            description: `VehicleCare can handle a wide range of mechanical issues for ${brand?.name} ${model?.name} cars, including engine repairs, transmission services, brake system repairs, suspension work, and more.`,
          },
          {
            title: `Does VehicleCare use genuine ${brand?.name} parts for repairs on ${brand?.name} ${model?.name} vehicles?`,
            description: `Yes, we use only genuine and authorized ${brand?.name} parts during mechanical repairs for ${brand?.name} ${model?.name} cars to ensure quality and compatibility.`,
          },
          {
            title: ` Is there a warranty on parts and labor for mechanical repairs on ${brand?.name} ${model?.name} cars?`,
            description: `Yes, VehicleCare offers warranties on both parts and  mechanical repair services for ${brand?.name} ${model?.name} cars, providing peace of mind to the vehicle owner.`,
          },
          {
            title: `Can VehicleCare assist with regular maintenance recommendations for my ${brand?.name} ${model?.name}? `,
            description: `Certainly, our skilled team of professional technicians provides valuable guidance on regular maintenance practices to help ${brand?.name} ${model?.name} owners keep their vehicles in optimal condition.`,
          },
          {
            title: ` Are there transparent estimates provided for mechanical repairs on ${brand?.name} ${model?.name} cars?`,
            description: `Yes, transparency is a key aspect of our service. VehicleCare provides clear and detailed estimates upfront, ensuring ${brand?.name} ${model?.name} owners are informed about the repair costs.`,
          },
          {
            title: `Why should I prefer VehicleCare over the authorized ${brand?.name} service center?`,
            description: `While authorized ${brand?.name} service centers have their merits, the preference for VehicleCare depends on individual priorities, such as cost considerations, rewards and offers, personalized service,  pick and drop services, 24x7 customer support,etc. It's advisable to weigh the pros and cons based on your specific needs and expectations for ${brand?.name} car maintenance. We also have customer reviews and testimonials that give you a better idea about the advantages of VehicleCare mechanical services over others. `,
          },
        ];
        break;
      case "wheel-care":
        returnVal = [
          {
            title: `How do I know it's time to change my tires?`,
            description: `It's time to change your tire when you observe low tread depth (less than 2/32 inches), visible damage such as cuts or bulges, or if the tire is more than six years old. Regular inspection for these signs are crucial to ensure safe driving.`,
          },
          {
            title: `Why is wheel balancing important?`,
            description: `Wheel balancing ensures even weight distribution on tires, preventing vibrations, improving vehicle stability, and extending tire life. It enhances overall driving comfort.`,
          },
          {
            title: `How often should I get my wheels balanced?`,
            description: `VehicleCare recommends wheel balancing every 6,000 to 8,000 miles or as part of regular tire rotations to maintain optimal performance.`,
          },
          {
            title: `What are the signs that my wheels need alignment?`,
            description: `Signs include uneven tire wear, vehicle pulling to one side, steering wheel vibrations, or if you've recently hit a pothole or curb. Regular check-ups are advisable.`,
          },
          {
            title: `Can wheel alignment issues affect fuel efficiency?`,
            description: `Yes, misaligned wheels can cause increased rolling resistance, leading to decreased fuel efficiency. Proper alignment ensures optimal wheel contact with the road, improving fuel economy of the vehicle.`,
          },
          {
            title: ` What type of tires does VehicleCare recommend for replacement?`,
            description: `VehicleCare recommends genuine and manufacturer-approved tires to maintain the vehicle's performance, safety, and alignment specifications. VehicleCare offers a wide range of genuine tyres from leading brands.`,
          },
          {
            title: ` Does VehicleCare provide wheel services for my ${brand?.name} ${model?.name} Car?`,
            description: `Yes, VehicleCare offers wheel care services for a wide range of car brands, including specialized services for luxury models like ${brand?.name}.`,
          },
          {
            title: `Why should I choose VehicleCare for my car wheels?`,
            description: `VehicleCare provides its customers the option of choosing their preferred car tyres from a wide range of brands and models. With us you get 100% quality assurance, genuine spares and brand warranty. We only offer genuine manufacturer approved wheels and related accessories.`,
          },
          {
            title: `Does VehicleCare offer any warranty on tyres?`,
            description: `Yes, all the tires bought from VehicleCare workshops come with full brand warranty.`,
          },
        ];
        break;
      case "car-cleaning":
        returnVal = [
          {
            title: `How often should I get my car professionally cleaned?`,
            description: `VehicleCare recommends professional cleaning every 1-2 months, depending on usage and environmental conditions, to maintain your car's appearance and value.`,
          },
          {
            title: `Are the cleaning products used by VehicleCare eco-friendly?
            `,
            description: `Yes, VehicleCare prioritizes eco-friendly products, ensuring effective cleaning without compromising environmental sustainability. We use only the best car care products which offer excellent performance and great results. The interior and exterior wash solutions, wax and polishes and cleaners are industry level top quality products.`,
          },
          {
            title: ` Can VehicleCare accommodate special requests for cleaning specific areas of the car?
            `,
            description: `Absolutely, VehicleCare offers customizable cleaning services, and customers can discuss specific requirements for tailored solutions.`,
          },
          {
            title: ` What is the difference between teflon coating and anti-rust coating?
            `,
            description: `Teflon coating provides a protective layer against dirt, stains, and enhances paint gloss, while anti-rust coating is designed to prevent corrosion by forming a barrier against moisture and salt. Teflon protects the paint, while anti-rust safeguards metal components. It is a car underbody treatment, which is applied to the underbody of the car. Both teflon and anti-rust treatment contribute to a vehicle's overall longevity and appearance.`,
          },
          {
            title: `How much time does it take for the comprehensive car cleaning service to get completed for my ${brand?.name} ${model?.name}? `,
            description: `It usually takes around 2-3 hours for complete car cleaning service. However, the  duration may vary based on the specific services requested and the condition of the vehicle. VehicleCare aims for efficient yet thorough cleaning, ensuring customer satisfaction.`,
          },
          {
            title: `Is interior cleaning safe for electronic components in the car?
            `,
            description: `Yes, VehicleCare employs techniques and products that are safe for electronic components during interior cleaning, ensuring no damage to sensitive systems.`,
          },
          {
            title: `Can car cleaning services remove scratches and swirl marks from the paint?
            `,
            description: `Yes, VehicleCare's car cleaning service includes polishing and buffing to reduce or eliminate minor scratches and swirl marks, restoring the vehicle's paint finish. `,
          },
          {
            title: ` Does VehicleCare offer pickup and delivery services for car cleaning?`,
            description: `Absolutely! We offer you pickup and delivery service at your doorstep. Just download VehicleCare App or Visit the website and place your request and leave the rest to us.`,
          },
          {
            title: ` Is VehicleCare equipped to deliver a quality Car Cleaning experience for my ${brand?.name} ${model?.name} car?`,
            description: `Yes, all VehicleCare workshops are fully equipped to deliver top quality car cleaning to luxury car brands like ${brand?.name}. Our professional technicians are equipped to meet the brand standards so that your car feels like it just came out of the showroom.`,
          },
        ];
        break;
      case "car-detailing-&-spa":
        returnVal = [
          {
            title: `What is VehicleCare Car detailing and Spa Services?
            `,
            description: `VehicleCare's car detailing service is a meticulous process that includes thorough cleaning, restoration, and protection for both the interior and exterior of your vehicle. Specializing in luxury cars like the ${brand?.name} A3, it enhances the appearance, preserves the condition, and protects the value of your vehicle through expert detailing techniques and high-quality products.`,
          },
          {
            title: `What all services are included in VehicleCare car detailing services?`,
            description: `There are different services like, Teflon coating, PPF, Ceramic Coating, etc services are given under car detailing service which helps your car retain showroom like shine.`,
          },
          {
            title: ` How often should I get my car detailed?`,
            description: `VehicleCare recommends detailing every 6-12 months, but frequency depends on factors like usage, climate, and individual preferences.`,
          },
          {
            title: `Can car detailing remove deep scratches?`,
            description: `While detailing can improve the appearance, deep scratches may require paint repair. VehicleCare offers paint correction services to minimize scratches.`,
          },
          {
            title: `How long does a full car detailing service take?
            `,
            description: ` The duration varies based on the detailing package and the condition of the vehicle. We aim to deliver the best satisfying service in the least time possible. VehicleCare aims for thoroughness while completing services efficiently.`,
          },
          {
            title: `What's the difference between waxing and sealing in detailing? `,
            description: ` Waxing provides a protective layer for the paint, while sealing uses synthetic compounds for longer-lasting protection. Both enhance shine and protect the paint surface and increase the life and durability of the vehicle.`,
          },
          {
            title: ` Can VehicleCare detail luxury or high-end vehicles?`,
            description: ` Absolutely, VehicleCare specializes in luxury car detailing, providing tailored services for high-end vehicles to meet specific aesthetic and preservation needs. We only manufacture approved genuine products for car detailing. `,
          },
          {
            title: `Can car detailing prevent rust on the vehicle?
            `,
            description: `While detailing focuses on preserving the paint and appearance, anti-rust coatings are specialized treatments to prevent corrosion. VehicleCare offers both services at the best price possible.`,
          },
          {
            title: `Can I book a car detailing service online with VehicleCare?
            `,
            description: `Yes, VehicleCare provides an online booking system, allowing customers to conveniently schedule car detailing services through their website or contact their service center directly.`,
          },
          {
            title: `How much time does it takes for my car Spa to complete?`,
            description: `The duration for your car spa from VehicleCare depends on the specific services and the condition of your car. Generally, it can take a few hours for a thorough and meticulous rejuvenation.`,
          },
        ];
        break;
      case "custom-repairs":
        returnVal = [
          {
            title: `Can VehicleCare handle both cosmetic and mechanical custom repairs for ${brand?.name} ${model?.name}?`,
            description: `Absolutely, VehicleCare provides a comprehensive approach, addressing both cosmetic enhancements and mechanical modifications for your car based on individual customer preferences. VehicleCare deals with all ranges of cars.`,
          },
          {
            title: `Can VehicleCare assist with performance upgrades for my ${brand?.name} ${model?.name}?`,
            description: ` Yes, VehicleCare offers performance-oriented custom repairs, including upgrades to enhance the power, handling, or overall performance of your ${brand?.name} ${model?.name}. Our skilled technicians are employed with state of the art equipment and specialized tools to cater to all your car needs irrespective of make and model.`,
          },
          {
            title: `What is the typical duration for custom repairs on an ${brand?.name} ${model?.name} at VehicleCare?
            `,
            description: ` The duration varies based on the scope of custom repairs. VehicleCare aims for prompt service without compromising the quality of work on ${brand?.name} ${model?.name} vehicles.`,
          },
          {
            title: `Can VehicleCare provide a cost estimate for custom repairs on my ${brand?.name} ${model?.name}?
            `,
            description: ` Yes, VehicleCare offers transparent and detailed cost estimates for custom repairs, ensuring clarity on pricing.`,
          },
          {
            title: `Why should I choose VehicleCare for custom repairs on my ${brand?.name} ${model?.name} in ${selectedCity}?`,
            description: ` VehicleCare's expertise, commitment to quality, genuine parts usage, transparent pricing, pick and drop facility, timely delivery and customer centric approach,makes it a trusted choice for custom repairs for all types of vehicle including ${brand?.name} ${model?.name}. Our custom repair services are available at all VehicleCare ${selectedCity} workshops.`,
          },
        ];
        break;

      default:
        break;
    }
    return returnVal;
  };
  const faqArr = getCustomHelmetDetails(cgID);
  return (
    <Row>
      <Col>
        <div className="auto-container" id="faq-page">
          <div className="row clearfix">
            <div className="accordian-column col-lg-12 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <h2 className="fs-3">
                    Frequently Asked Questions on VehicleCare{" "}
                    {cgID
                      .toLowerCase()
                      .split("-")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")}
                    :
                  </h2>
                </div>

                <ul className="accordion-box">
                  <Accordion defaultActiveKey={0} flush>
                    {faqArr?.map((value, index) => (
                      // <Accordion.Item eventKey={index} className="mb-1">
                      //   <Accordion.Header>{value.title}</Accordion.Header>
                      //   <Accordion.Body>{value.description}</Accordion.Body>
                      // </Accordion.Item>

                      <div className="accordion block mb-1" key={index}>
                        <div className="acc-btn" onClick={() => handleToggle(index)} style={index === toggle ? active : inactive}>
                          {value.title}{" "}
                          <div className="icon-outer">
                            <b className="icon">{index === toggle && isActive ? "-" : "+"} </b>{" "}
                          </div>{" "}
                        </div>
                        {index === toggle && isActive ? (
                          <div className="content p-0">
                            <div className="accordian-text"> {value.description} </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </Accordion>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer.cartList;
  stateObj.passingData = state.default.vehicleReducer;
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  stateObj.serviceList = state.default.serviceListReducer;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.cityData = state.default.cityListReducer;
  return stateObj;
};
export default connect(mapStateToProps)(ServiceFaq);
