import { APPOINTMENT_DATA } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
    state = {
        appointmentData: [],
    },
    action = {}
) => {
    switch(action.type){
        case APPOINTMENT_DATA:
            return {...state, appointmentData: action.payload};
        default:
            return state;
    }
};

