import React, { useState } from 'react';
import { Row } from "react-bootstrap";
import "./index.scss";

const InsuranceFaq = () => {
  const [activeId, setActiveId] = useState('0');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <>
     
      <Row>
        <section className="" id="faq-page">
          <div className="auto-container">
            <div className="row clearfix">
              
              <div className="accordian-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
        
                  <div className="sec-title">
                    <div className="title">Frequently Asked Questions</div>
                    <h2>Just Find Your Answers Below</h2>
                  </div>
          
                  <ul className="accordion-box" defaultActiveKey={activeId}>
                    
                    <li className={activeId === '0' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '0' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('0')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>How does the DCX service streamline insurance claims?
                      </div>
                      <div className="acc-content" eventKey="0">
                        <div className="content">
                          <div className="accordian-text">The DCX service utilizes advanced AI and digital workshops to expedite the entire claims process, from assessment to repairs. </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '1' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '1' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('1')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>Is VehicleCare affiliated with specific insurance companies?
                      </div>
                      <div className="acc-content" eventKey="1">
                        <div className="content">
                          <div className="accordian-text">Yes, we collaborate with a network of leading insurance companies to offer cashless insurance claims.</div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '2' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '2' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('2')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div> How do I initiate a cashless insurance claim with VehicleCare?
                      </div>
                      <div className="acc-content" eventKey="2">
                        <div className="content">
                          <div className="accordian-text">You can initiate a cashless insurance claim by contacting our dedicated claims assistance team, who will guide you through the entire process.</div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '3' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '3' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('3')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>What information do I need to provide for a cashless claim?
                      </div>
                      <div className="acc-content" eventKey="3">
                        <div className="content">
                          <div className="accordian-text">You will need to provide your insurance details, policy number, a description of the incident, Photographs and videos of the damaged vehicle, driver’s licence, FIR copy, A copy of the vehicle Registration Certificate, Medical report etc.  </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '4' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '4' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('4')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>Can I choose VehicleCare for insurance claims even if my policy is with a different company?</div>
                      <div className="acc-content" eventKey="4">
                        <div className="content">
                          <div className="accordian-text">Absolutely. Our DCX service is designed to cater to customers with insurance policies from various providers. </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '5' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '5' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('5')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>How does AI play a role in the claims process?
                      </div>
                      <div className="acc-content" eventKey="5">
                        <div className="content">
                          <div className="accordian-text">AI technology is used for accurate damage assessment, ensuring a precise and efficient claims process. </div>
                        </div>
                      </div>
                    </li>

                    <li className={activeId === '6' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '6' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('6')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>Are there any additional costs associated with the DCX service?</div>
                      <div className="acc-content" eventKey="6">
                        <div className="content">
                          <div className="accordian-text">Our goal is transparency. Any additional costs beyond the insurance coverage will be communicated to you before proceeding with repairs.</div>
                        </div>
                      </div>
                    </li>

                    <li className={activeId === '7' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '7' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('7')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div> What types of vehicles does VehicleCare service for insurance claims?</div>
                      <div className="acc-content" eventKey="7">
                        <div className="content">
                          <div className="accordian-text">We cater to a wide range of vehicles of different make and models, and luxury cars like Audi, BMW, Mercedes, etc</div>
                        </div>
                      </div>
                    </li>

                    <li className={activeId === '8' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '8' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('8')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div> How long does it take to process a cashless insurance claim with VehicleCare?</div>
                      <div className="acc-content" eventKey="8">
                        <div className="content">
                          <div className="accordian-text">The processing time varies based on the complexity of the claim. However, our streamlined process aims for prompt resolution.</div>
                        </div>
                      </div>
                    </li>

                    <li className={activeId === '9' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '9' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('9')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div> Is VehicleCare DCX Service available at all VehicleCare Workshops?</div>
                      <div className="acc-content" eventKey="9">
                        <div className="content">
                          <div className="accordian-text">Absolutely! VehicleCare DCX service is available at all our workshops across the country and our customer care helpline is open 24x7 for better assistance.</div>
                        </div>
                      </div>
                    </li>

                    <li className={activeId === '10' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '10' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('10')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div> Can I track the status of my insurance claim online?</div>
                      <div className="acc-content" eventKey="10">
                        <div className="content">
                          <div className="accordian-text">Yes, our online portal allows you to track the status of your insurance claim in real time, providing you with updates at every stage of the process.</div>
                        </div>
                      </div>
                    </li>
                    
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </Row>

    </>
  );
};

export default InsuranceFaq;
