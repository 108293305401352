import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { WhyUs } from "./../../../../constants/ImageConstants";

const WeProvide = () => {
  return (
    
    <Row>
      <div className="auto-container">
        <div className="row clearfix remove">
        
          <div className="skill-column col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column">
              
              <div className="sec-title">
                <div className="title">We Provide</div>
                <h2>We Are Qualified & Professional</h2>
                <div className="text">Our commitment to you is to provide honest, friendly, and on-time service. Visit a locally owned and operated business that has been serving the community.</div>
              </div>
              
              <div className="skills">

                <div className="skill-item">
                  <div className="skill-header clearfix">
                    <div className="skill-title">Quality Services</div>
                    <div className="skill-percentage"><div className="count-box"><span className="count-text" data-speed="2000" data-stop="80">80</span>%</div></div>
                  </div>
                  <div className="skill-bar">
                    <div className="bar-inner"><div className="bar progress-line" data-width="80" style={{ width: '80%' }}></div></div>
                  </div>
                </div>

                <div className="skill-item">
                  <div className="skill-header clearfix">
                    <div className="skill-title">Experienced Technicials</div>
                    <div className="skill-percentage"><div className="count-box"><span className="count-text" data-speed="2000" data-stop="90" >90</span>%</div></div>
                  </div>
                  <div className="skill-bar">
                    <div className="bar-inner"><div className="bar progress-line" data-width="90" style={{ width: '90%' }}></div></div>
                  </div>
                </div>

                <div className="skill-item">
                  <div className="skill-header clearfix">
                    <div className="skill-title">Long Term Warranty</div>
                    <div className="skill-percentage"><div className="count-box"><span className="count-text" data-speed="2000" data-stop="70">70</span>%</div></div>
                  </div>
                  <div className="skill-bar">
                    <div className="bar-inner"><div className="bar progress-line" data-width="70" style={{ width: '70%' }}></div></div>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
          
          <div className="image-column col-lg-7 col-md-12 col-sm-12">
            <div className="inner-column wow slideInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div className="image">
                <img src={WhyUs} alt="" />
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </Row>
  );
};

export default WeProvide;
