import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { SeamlessDigitalDriven, DynamicRepairHandling, DataDriverRepairManagement } from "../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import { getNavigateUrl } from "../../../utils/customHelper";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 140) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

const NewWayHandleDamageRepair = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="sec-title centered">
            {/* <h1 className="title">Solutions & Services</div> */}
            <h2>Innovative Approach to Car Damage Repair </h2>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <div className="row clearfix">
                <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={getNavigateUrl("/services/denting-painting")}>
                        <img className="w-50 mx-auto pt-4" src={SeamlessDigitalDriven} alt="" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5 className="text-center">
                        <Link to={"/services"}> Seamless Digital Driver Experience</Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          We offer a completely digital and hassle-free repair process for our customers. With our web app, drivers can easily upload photos of the damage and receive a quick damage
                          assessment. Our digital platform guides them through the entire repair process, from reporting the damage to tracking repair progress.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/makeclaim"}>
                        <img className="w-50 mx-auto pt-4" src={DataDriverRepairManagement} alt="" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5 className="text-center">
                        <Link to={"/services"}> Data-driven Repair Management </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          Our repair management system is driven by data, which enables us to provide insightful and efficient repair solutions for each damage. We use advanced analytics to assess the
                          repair requirements and allocate the best repairer for the job. Our data-driven approach ensures transparency and accountability throughout the repair process.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={getNavigateUrl("/services/mechanical-repairs")}>
                        <img className="w-50 mx-auto pt-4" src={DynamicRepairHandling} alt="" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5 className="text-center">
                        <Link to={"/services"}> Dynamic Repair Handling </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          Our independent and dynamic network of repairers enables us to optimize repair allocation and handling. We match each repair job with the most suitable repairer, ensuring the
                          best possible outcome for each customer. Our dynamic repair handling system streamlines workflows and minimizes manual activities, ensuring a faster and more cost-effective
                          repair process.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NewWayHandleDamageRepair;
