import { REVIEW_LIST } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    reviewList: [],
  },
  action = {}
) => {
  switch (action.type) {    
    case REVIEW_LIST:    
      return { ...state, reviewList: action.payload };
    default:      
      return state;
  }
};
