// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyA1maZ4DWkW4jn2HCy7lze3pgpWCvZatqc",
  authDomain: "arvind-car-project.firebaseapp.com",
  databaseURL: "https://arvind-car-project.firebaseio.com",
  projectId: "arvind-car-project",
  storageBucket: "arvind-car-project.appspot.com",
  messagingSenderId: "653032809543",
  appId: "1:653032809543:web:3c7df690a2c6ad4ddcbd62",
  measurementId: "G-DLGBE9P2YZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const messaging = getMessaging();
export const generateRecaptcha = (setIsCaptchaVerifier) => {
  setIsCaptchaVerifier(true);
  window.recaptchaVerifier = new RecaptchaVerifier(
    "custom-captcha-verifier",
    {
      size: "invisible",
      callback: (response) => {},
    },
    auth
  );
};
export const requestForToken = () => {
  const token = window.localStorage.getItem("notification_token");
  if (token) return false;
  getToken(messaging, { vapidKey: "BHB_TBJmB2CIXF_1kkERfdTgYSuSVew-0XCeHzB-re-513sbGw5kyOfI3fvDf3oeXJzYOc8u_mbW2nG2mHGvJ54" })
    .then((currentToken) => {
      if (currentToken) {
        window.localStorage.setItem("notification_token", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

export default app;
