import React , { useState } from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 ,Faqbg } from "./../../../constants/ImageConstants";

import OurApp from "./../../../components/common/ourApp";

import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import Testimonial from "../../../components/common/Testimonial";
import BlogMultiImageCarousel from "./../../../components/common/blogMultiImageCarousel";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import CarAcRepairPriceList from "./CarAcRepairPriceList";

const CarAcRepairNearMe = (props) => {
  const selectedCityName = props.cityListReducer.selectedCity;
  const faqList =[
    {
        id:1,
        title:"What all do you check during inspection of our car?",
        summary:"We first start with a document check and test drive and then use the latest tools to assess the exterior, engine, tire, transmission, electrical, and interior. We also inspect the suspension, steering mechanism, brakes, and other accessories of your car during the test drive."   
    },
    {
        id:2,
        title:"Will you charge for our car inspection?",
        summary:"Yes, the charges for our car inspection is Rs 399." 
       
    },
    {
        id:3,
        title:"Do VehicleCare provide home inspection for car?",
        summary:"We provide Home inspection for you your car, you just have to call or WhtsApp us and our mechanic will reach you within 15 minutes." 
       
    },
    {
        id:4,
        title:"How long will it take to repair my car?",
        summary:"There is time when you could not see your car for 5-6 hours. But in some cases the repair could take anywhere from 5-6 hours to a week or more." 
       
    },
    {
      id:5,
      title:"Can i book my appointment thorugh mobile APP ?",
      summary:"Yes you can book your appointment through Mobile App. You can also book via email." 
     
  },
  {
    id:6,
    title:"How many mechanic are there in vehiclecare ?",
    summary:"There are plenty of Mechanics in VehicleCare and our expert mechanics have more than ten years of experience." 
   
},
{
  id:7,
  title:"What all kind of services do VehicleCare provide?",
  summary:"There's a wide range of car services which are available.We offer Car service, Wheel Care, Car AC Repair, Custom Repair and much more." 
 
},
{
  id:7,
  title:"what sort of spare parts and engine oils are used in the servicing?",
  summary:"We use only genuine spares sourced from OEM/OES across all our workshops. Further, all products used in car services are authentic and top-rated." 
 
}
];

const [toggle, setToggle] = useState(0);
let handleToggle = (id) => {
  if (toggle === id) {
    setToggle(null);
    return false;
  }
  setToggle(id);
};
const active = { backgroundColor: "black", color: "white" };
  const inactive = {};
  return (
    <>
      <Helmet>
        <title>Find The Expert Car Ac Repair Near Me - VehicleCare</title>
        <meta name="description" content="Can You Search for The Best Car Ac Repair Near Me From Servicing, Repairs, And Accident Claims To Meticulous washing, Denting, Painting, And More" data-react-helmet="true" />
      
        <meta property="og:title" content="Find The Expert Car Ac Repair Near Me - VehicleCare"/>
        <meta property="og:description" content="Can You Search for The Best Car Ac Repair Near Me From Servicing, Repairs, And Accident Claims To Meticulous washing, Denting, Painting, And More"/>
        <meta property="og:url" content={`https://vehiclecare.in/car-mechanic-near-me`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="VehicleCare"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
        <meta property="twitter:title" content="Find The Expert Car Ac Repair Near Me - VehicleCare"/>
        <meta property="twitter:description" content="Can You Search for The Best Car Ac Repair Near Me From Servicing, Repairs, And Accident Claims To Meticulous washing, Denting, Painting, And More"/>
        <meta property="twitter:url" content={`https://vehiclecare.in/car-mechanic-near-me`}/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:site" content="@vehiclecare1"/>
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                  <a href="https://vehiclecare.in/car-wash-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24">  car wash, </font></a>
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="services-section-four pt-0">
          <ServiceSteps />
        </div>

        {/* price list */}
        <div className="blog-section pt-0">
          <CarAcRepairPriceList />
        </div>

        <div className="price-section " style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        <div className="services-section-four">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Why Choose VehicleCare </h2>
            </div>
          </div>
          <WhyChooseVc />
        </div>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix d-flex justify-content-center px-2">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">
                    <div className="sec-title mb-4">
                      <h2 className="text-center">Looking For Nearby <font color="#ed1c24">Car AC Repair Service?</font></h2>
                      <div className="text text-left mb-0">
                        As the temperature rises, your comfort behind the wheel becomes a top priority. A well-functioning car AC is crucial for a safe and comfortable driving experience. It prevents fatigue, maintains optimal visibility by preventing fogging, and ensures the well-being of the driver and the passengers by regulating interior temperatures.
                      </div>

                      <div className="text text-left mt-2 mb-0">Welcome to VehicleCare, where we bring you unparalleled expertise in car AC repair services.Our dedicated team at VehicleCare understands the importance of a well-functioning air conditioning system in your car, and we are proud to offer a comprehensive solution with our specialized "Car AC repair near me" services. VehicleCare’s wide range of assortment of Car AC service is a promise of reliability so that your car remains in the best condition for the rest of its life. </div>
                   
                    </div>

                    <div className="detail">
                      <div className="justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          
                          <div className="sec-title mb-4">
                            <h2 className="text-left fs-4 text-capitalize">Choose VehicleCare for Car AC Service Nearby and Experience the Difference.</h2>
                          </div>
                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              1. Advanced Diagnostics:
                              </font>
                            </b>
                            <span>
                              {" "}
                               Our certified technicians utilize cutting-edge diagnostic tools to identify the root cause of your car's AC problems accurately. This ensures that we address the issue efficiently & effectively and save you time and money. At VehicleCare our professionally trained technicians use advanced machinery and best industry equipment to identify the key issues. 
                            </span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              2. Genuine Spare Parts:
                              </font>
                            </b>
                            <span> VehicleCare prioritizes the longevity and performance of your car's AC system by exclusively using genuine spare parts in all kind of repair services including car ac services. We understand the critical role that quality components play in ensuring optimal functionality and longevity of air conditioning in your vehicle. Our commitment to using genuine parts underscores our dedication to delivering trustworthy and durable solutions, providing you with peace of mind and assurance that your car's AC will continue to operate at its best.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              3. Multi-Brand Expertise:
                              </font>
                            </b>
                            <span> Whether you drive a compact car, an SUV, or a luxury vehicle, our technicians have the expertise to address a wide range of AC system complexities across various brands.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              4.Transparent and Fair Pricing:
                              </font>
                            </b>
                            <span> VehicleCare takes pride in prioritizing transparency in pricing for its customers and providing clear and fair rates for AC repair services without hidden fees.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              5. Punctual Time-Based Delivery:
                              </font>
                            </b>
                            <span>We respect your time, and our commitment to punctual time-based delivery ensures that our technicians arrive at the specified time, ready to restore your car's AC system to optimal performance whenever you look for a “car ac service near me” or “ car service near me”. </span>
                          </p>

                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-4 mt-4">
                            <h2 className="text-left fs-4 text-capitalize">VehiclCare’s Tips to maintain Car AC health</h2>
                          </div>

                          <p><font color="#ed1c24" size="4"><b>1.</b> </font> Always park your car in shaded areas so that whenever you start your car and turn on the AC, it will cool faster.</p> 

                          <p><font color="#ed1c24" size="4"><b>2.</b> </font> Before starting the car, make sure to properly ventilate the car cabin.
                            Regularly check for refrigerant leaks, as they can compromise the effectiveness of your AC system.</p>

                          <p><font color="#ed1c24" size="4"><b>3.</b> </font> Keep your AC system's refrigerant at the recommended levels it will prevent unnecessary strain on the compressor.</p>

                          <p><font color="#ed1c24" size="4"><b>4.</b> </font> Clean condenser coils regularly as over time, condenser coils can accumulate dirt and debris therefore reducing their efficiency. </p>

                          <p><font color="#ed1c24" size="4"><b>5.</b> </font> Even in cooler months, run your car's AC for a few minutes to lubricate internal components. This practice helps prevent the seals from drying out and maintains the overall health of the system.</p>

                          <p><font color="#ed1c24" size="4"><b>6.</b> </font> Avoid running the AC at its maximum setting continuously. Opt for a comfortable but moderate setting to reduce wear on the components.</p>

                          <p><font color="#ed1c24" size="4"><b>7.</b> </font> If you notice any unusual noises or odors coming from your AC system, contact VehicleCare promptly. Timely attention to these issues can prevent further damage and maintain the longevity of your car's AC.</p>

                          <p><font color="#ed1c24" size="4"><b>8.</b> </font> Schedule periodic maintenance services with VehicleCare. Our expert technicians can provide comprehensive check-ups, ensuring that your car's AC system remains in top condition for the long term.</p>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section  */}
        <div className={`acordian-section  ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>

          <Col  className={`auto-container`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    {/* <div className="title">Contact Support</div> */}
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <ul className="accordion-box">
                    {faqList?.map((value, index) => {
                      return (
                        <div className="accordion block mb-1" id="acc_faq" key={index}>
                          <div
                            className="acc-btn"
                            onClick={() => handleToggle(index)}
                            style={index === toggle ? active : inactive}
                          >
                            <h2>{value.title}{" "}</h2>
                            <div className="icon-outer">
                              <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
                            </div>{" "}
                          </div>
                          {index === toggle ? (
                            <div className="content px-2 py-0">
                              <div className="accordian-text"> {value.summary} </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="title-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Contact Support</div>
                    <h2>Have Any Questions?</h2>
                  </div>
                  <div className="inquiry-box">
                    <div className="box-inner">
                      <div className="upper-box">
                        <div className="icon flaticon-telephone"></div>
                        <div className="inquiry-title">For Any Inquiries Call Now!</div>
                        <a href="tel:9911-020-209" className="phone">
                          9911-020-209
                        </a>
                      </div>
                      <div className="lower-box">
                        <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                    <Link to={"/getintouch"} className="get-tech">
                      Get You Tech Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* FAQ Section  */}

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name="Gurgaon" />
        </div>

        {/* Blog Section */}
        <div className="blog-section pb-0">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>



      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(CarAcRepairNearMe);
