import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNavigateUrl } from "../../../../utils/customHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import { Successful, TotalEnergiesVehicleCare, ZoomCar } from "../../../../constants/ImageConstants";
import VehicleSelector from "../../../../components/common/vehicleSelector";
import { auth, generateRecaptcha } from "../../../../server/firebase";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
import { MEMBER_ENROLL, SHOW_HIDE_LOADER } from "../../../../store/types";
import CustomModal from "../../../../components/common/CustomModal";
import { OrderAction, PaymentAction } from "../../../../store/action";
import { initializePayment } from "../../../../utils/paymentHelper";
import moment from "moment";

const Banner = (props) => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [optVerified, setOptVerified] = useState(false);
  const [final, setfinal] = useState("");
  const { vehicleData, partner, customDispatch, createMemberEnrollment, enrollSuccessData, paymentSessionData, createPaymentSession } = props;
  const { brand, model, variant } = vehicleData;
  const navigate = useNavigate();
  const paymentReturnUrl = "enrollPaymentresponse";
  const [data, setData] = useState({
    userPhone: "",
    carReg: "",
    userEmail: "",
    userName: "",
  });
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "userPhone") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span></span>;
    }
    if (name === "carReg") {
      if (!inputValue?.match(/^[a-z0-9A-Z]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Invalid</span>;
      }
      return <span></span>;
    }
    if (name === "userEmail") {
      if (!inputValue?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) && inputValue?.length > 0) {
        return <span className="text-danger">Invalid email address</span>;
      }
      return <span></span>;
    }
    if (name === "userName") {
      if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span></span>;
    }
    if (name === "userVehicle") {
      if (data.userPhone && data.carReg && data.userEmail && data.userName && vehicleData?.variant === null) {
        return <span className="text-danger">Select Your Vehicle</span>;
      }
      return <span></span>;
    }

    return <></>;
  };
  const GetPartnerImage = () => {
    let imageUrl = "";
    if (partner === "VC ZOOM") {
      imageUrl = ZoomCar;
    } else {
      imageUrl = TotalEnergiesVehicleCare;
    }
    return <img src={imageUrl} className="w-25" alt="" style={{ position: "absolute", top: "-1em", right: "0" }} />;
  };
  const handleClose = () => {
    if (optVerified) {
      navigate(getNavigateUrl("/"));
    }
    setShow(false);
  };
  const handleClick = (e) => {
    if (e.target.name === "userPhone" && e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      enrollFormSubmit();
    }
    setValidated(true);
  };
  const enrollFormSubmit = () => {
    const apiParam = {
      task: "createMemberEnrollment",
      brand_id: brand.id,
      model_id: model.id,
      variant_id: variant.id.toString(),
      name: data.userName,
      email: data.userEmail,
      mobile: data.userPhone,
      car_reg: data.carReg,
      source: partner,
    };
    createMemberEnrollment(apiParam);
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        enrollFormSubmit();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  // eslint-disable-next-line no-unused-vars
  const sendOtp = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${data.userPhone}`;
    if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        setShow(true);
      })
      .catch((err) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(err);
      });
  };
  const GetModalContent = () => {
    return true;
    return !optVerified ? (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.userPhone} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input type="text" className="form-control" maxLength={6} placeholder="Enter OTP Code" value={optCode} onChange={(event) => setOptCode(event.target.value)} />
            </div>
          </div>
          <button onClick={onCodeSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    ) : (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="col-4 text-center">
              <img src={Successful} alt="" className="w-100" />
            </div>

            <div className="col-8 text-left my-auto">
              <h4>
                <b>
                  <font color="black">Congratulations!!</font>
                </b>
              </h4>
              <h5 style={{ color: "#40c057" }}>
                <b>Your Enquiry is successfully Registered.</b>
              </h5>
              <h6>Our Executive will contact you soon.</h6>
              <button type="button" className="theme-btn btn-style-nine my-3 mx-5" onClick={() => navigate(getNavigateUrl("/"))}>
                <span className="txt">Home</span>
              </button>
            </div>
          </div>
        </div>
      </Row>
    );
  };
  useEffect(() => {
    if (paymentSessionData.sessionResponse !== null) {
      initializePayment(paymentSessionData.sessionResponse.data.payment_session_id, paymentReturnUrl);
    }
    if (enrollSuccessData.memberEnrollData !== null) {
      setShow(true);
      setOptVerified(true);
      customDispatch({ type: MEMBER_ENROLL, payload: null });
      const params = {
        task: "getpaymentGatewayOrderDetails",
        order_id: `VC-Enroll-${moment().format("DD-MM-YY-HH-mm-ss")}-${enrollSuccessData.memberEnrollData.order_id}`,
        order_amount: 599,
        customer_id: `${enrollSuccessData.memberEnrollData.order_id}`,
        customer_phone: data.userPhone,
      };
      createPaymentSession(params);
    }
  }, [enrollSuccessData, paymentSessionData, navigate, customDispatch, createPaymentSession, data]);
  return (
    <>
      <Row>
        <Col>
          <div className="auto-container">
            <div className="content-boxed">
              <div className="">
                <div className="row clearfix">
                  <div id="landingForm" className="content-column appointment-form banner-form col-lg-5 col-md-12 col-sm-12 text-left">
                    <Form noValidate validated={validated} onSubmit={submitForm}>
                      <h1 className="fs-2">A Simpler Way To Car Service.</h1>

                      <div className="row">
                        <div className="form-group mt-3 col-lg-6 col-md-6 col-sm-12 px-0">
                          <input
                            type="text"
                            name="userName"
                            className="form-control border-0 phoneNo"
                            aria-describedby="inputGroupPrepend"
                            required
                            value={data.userName}
                            placeholder="Enter Name"
                            onChange={(e) => handleClick(e)}
                          />
                          <Form.Control.Feedback type="invalid">Please enter your name</Form.Control.Feedback>
                          <ErrorOutput case={data.userName} name={"userName"} />
                        </div>
                        <div className="form-group mt-3 col-lg-6 col-md-6 col-sm-12">
                          <input
                            type="text"
                            name="userEmail"
                            className="form-control border-0 phoneNo"
                            aria-describedby="inputGroupPrepend"
                            required
                            placeholder="Enter Email"
                            value={data.userEmail}
                            onChange={(e) => handleClick(e)}
                          />
                          <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                          <ErrorOutput case={data.userEmail} name={"userEmail"} />
                        </div>
                      </div>
                      <VehicleSelector navigate={true} />
                      <ErrorOutput case={data.userVehicle} name={"userVehicle"} />

                      <div className="row">
                        <div className="form-group mt-3 col-lg-6 col-md-6 col-sm-12 px-0">
                          <input
                            type="text"
                            name="userPhone"
                            value={data.userPhone}
                            aria-describedby="inputGroupPrepend"
                            className="form-control border-0 phoneNo"
                            placeholder="Enter Mobile"
                            required
                            onChange={(e) => handleClick(e)}
                          />
                          <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                          <ErrorOutput case={data.userPhone} name={"userPhone"} />
                        </div>
                        <div className="mt-3 form-group col-lg-6 col-md-6 col-sm-12">
                          <input
                            type="text"
                            name="carReg"
                            className="form-control border-0 phoneNo"
                            aria-describedby="inputGroupPrepend"
                            required
                            placeholder="Enter Car Registration"
                            value={data.carReg}
                            onChange={(e) => handleClick(e)}
                          />
                          <Form.Control.Feedback type="invalid">Please enter car registration</Form.Control.Feedback>
                          <ErrorOutput case={data.carReg} name={"carReg"} />
                        </div>
                        <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                          <button className="theme-btn btn-style-nine getQuote" href="">
                            <span className="txt">Submit</span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-none d-md-block px-5">
                    <div className="inner-column">
                      <GetPartnerImage />
                      <h1 className="fs-2">Benefits we offer</h1>

                      <div id="demo" className="">
                        <p>
                          <font color="#FF5757" size="4">
                            <b>1.</b>
                          </font>{" "}
                          Flat 500 off on Vehicle Service.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>2.</b>
                          </font>{" "}
                          One Panel free on dent paint of 2 joint panels.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>3.</b>
                          </font>{" "}
                          Dent paint panel at flat 1999.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>4.</b>
                          </font>{" "}
                          Car Washing at 149 Only.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>5.</b>
                          </font>{" "}
                          Free health Inspection and check-ups of vehicles.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>6.</b>
                          </font>{" "}
                          Warranty on repairs.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>7.</b>
                          </font>{" "}
                          50% off on file charges in insurance claims.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>8.</b>
                          </font>{" "}
                          Cashless insurance claims.
                        </p>
                        <p>
                          <font color="#FF5757" size="4">
                            <b>9.</b>
                          </font>{" "}
                          Dry Cleaning at 499 Only.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div id="custom-captcha-verifier"></div>
      <CustomModal show={show} modalSize={optVerified ? "lg" : "md"} content={<GetModalContent />} isCloseButton={true} handleClose={handleClose} />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.paymentSessionData = state.default.paymentReducer;
  stateObj.enrollSuccessData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createMemberEnrollment: (params) => OrderAction.createMemberEnrollment(params, dispatch),
  createPaymentSession: (params) => PaymentAction.createPaymentSession(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
