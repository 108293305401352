import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { Successfull } from "./../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const PaymentSuccessfull = (props) => {
  return (
    <>
      <Helmet>
        <title>Car Service Simplified - VehicleCare</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />
      </Helmet>
      <Row>
        <section className="error-section">
          <div className="auto-container">
            <div className="content">
              <img src={Successfull} alt="Payment Successfull" className="mb-4 w-25" />
              <h2>Payment Successfull</h2>
              <div className="text">Thank you for your payment</div>
              <h4 className="text-warning">
                Your Enrollment Service Coupon code is <br />
                <span className="text-danger">{props.code}</span>
              </h4>
              <Link to={"/"} className="theme-btn btn-style-nine">
                <span className="txt">Go to Home Page</span>
              </Link>
            </div>
          </div>
        </section>
      </Row>
    </>
  );
};

export default PaymentSuccessfull;
