import { PAYMENT_SESSION, PAYMENT_STATUS } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { sessionResponse: null, statusResponse: null }, action = {}) => {
  switch (action.type) {
    case PAYMENT_SESSION:
      return { ...state, sessionResponse: action.payload };
    case PAYMENT_STATUS:
      return { ...state, statusResponse: action.payload };
    default:
      return state;
  }
};
