import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
// import { WhoWeAreBg} from "./../../../constants/ImageConstants";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ReviewListAction } from "../../../store/action";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {text.length > 50 && isReadMore ? text.slice(0, 50): text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore && text.length > 150 ? "... read more" : text.length > 150 && " show less"}
      </span>
    </p>
  );
};

const Reviews = (props) => {
  const reviewList = props.reviewList.reviewList.review;
  const service = props.service;
  const city_name = props.city.selectedCity;
  useEffect(() => {
    if (city_name && !reviewList) {
      const params = {
        task: "getReviewList",
        service: service,
        city_name: city_name,
        limit: 100,
      };
      props.getReviewList(params);
    }
  }, [city_name, props, service, reviewList]);

  return (
    <>
      <Helmet>
        <title>Top Reviews by VehicleCare Customers for our services</title>
        <meta name="description" content="With over 1.5 lac satisfied customers, VehicleCare receives valuable feedback on car maintenance, denting and apinning, insurance claims, and other topics." />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <section className="testimonial-page-section mt-3 pb-2">
          <div className={`auto-container  ${reviewList?.length < 1}`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title centered">
                    <h2>Testimonial</h2>
                  </div>

                  <div className="row">
                    {reviewList?.map((value, index) => (
                      <div className="testimonial-block-two col-lg-4 col-md-4 col-sm-12" key={index}>
                        <div className="inner-box">
                          <div className="quote-icon fa fa-quote-left"></div>
                          <div className="text">
                            <ReadMore>
                              {value.review}
                            </ReadMore>
                          </div>
                          <div className="rating">
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                          </div>
                          <div className="author-name">{value.review_user_name}</div>
                          <div className="author-image pb-3">
                            <img src="images/resource/author-3.png" alt="" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </section>
      </Row>

      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateobj = {};
  stateobj.reviewList = state.default.reviewListReducer;
  stateobj.city = state.default.cityListReducer;
  return stateobj;
};
const mapDispatchToProps = (dispatch) => ({
  getReviewList: (params) => ReviewListAction.getReviewList(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
