import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalStyle from "./assets/styles/GlobalStyle";
import ClientApp from "./ClientApp";
import reportWebVitals from "./reportWebVitals";
import InternetConnection from "./components/InternetConnection";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.debug = () => {};
}
ReactDOM.render(
  <>
    <GlobalStyle />
    <ClientApp />
    <InternetConnection />
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
