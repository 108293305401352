import { PROPOSAL_ID, CLEAR_DATA } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    proposal_id: null,
  },
  action = {}
) => {
  switch (action.type) {
    case PROPOSAL_ID:
      return { ...state, proposal_id: action.payload };
    case CLEAR_DATA:
      return { ...state, proposal_id: null };
    default:
      return state;
  }
};
