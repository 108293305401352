import { HELP_FORM_LIST } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    helpFormList: null,
  },
  action = {}
) => {
  switch (action.type) {
    case HELP_FORM_LIST:
      return { ...state, helpFormList: action.payload };
    default:
      return state;
  }
};
