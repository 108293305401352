import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { InsuranceBanner } from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";

const SegmentsBannerSection = (props) => {
  const { source } = props;
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="content-boxed">
            <div className="oem_banner">
              <div className="row clearfix">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 text-left">
                  <h1>Vehiclecare For OEM</h1>
                  {/* <h1 className="title">Vehiclecare For OEM</div> */}
                  <div className="text">
                    As a new electric vehicle original equipment manufacturer
                    <b>
                      <font color="#ed1c24" size="3">
                        (EV OEM),{" "}
                      </font>
                    </b>
                    it is essential to have a seamless service infrastructure in place to ensure that customers have an exceptional experience throughout their vehicle's lifespan. Our digital platform
                    offers a turnkey solution that allows OEMs to connect all relevant stakeholders in a single environment, giving them complete control over the entire customer journey, including
                    warranties. With Vehicle Care's platform, you can streamline the after-sales process, reduce costs, and increase customer satisfaction by providing a comprehensive and integrated
                    service experience.
                  </div>
                  <div className="btns-box">
                    <Link to={`/getintouch?source=${source}`} className="theme-btn btn-style-nine px-4">
                      <span className="txt">Get in touch</span>
                    </Link>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="image">
                      <img src={InsuranceBanner} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SegmentsBannerSection;
