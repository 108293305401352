import React, { useEffect, useState } from "react";
import "./index.scss";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { UserAction } from "../../../store/action";
import { SHOW_HIDE_LOADER } from "../../../store/types";
import { emailValidation } from "../../../utils/validations";

const UserSignup = (props) => {
  const { user_profile } = props.userReducer;
  const { cityList, faqCityId } = props.cityListReducer;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState(faqCityId);
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    if (firstName && lastName && emailValidation(email) && city) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [firstName, lastName, email, city]);
  const signupSubmit = () => {
    props.updateReducer({ type: SHOW_HIDE_LOADER, payload: true });
    props.userLogin({
      task: "createUser",
      phone: user_profile.phone,
      email: email,
      first_name: firstName,
      last_name: lastName,
      city_id: city,
      app_source: "web",
    });
  };
  return (
    <>
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter Your Details For Sign Up</font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="row">
              <div className="form-group col-lg-6 col-md-6 col-sm-12">
                <span className="adon-icon">
                  <span className="fa fa-user"></span>
                </span>
                <input type="text" className="form-control" placeholder="Enter First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
              </div>

              <div className="form-group col-lg-6 col-md-6 col-sm-12">
                <span className="adon-icon">
                  <span className="fa fa-user"></span>
                </span>
                <input type="text" className="form-control" placeholder="Enter Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
              </div>

              <div className="form-group col-lg-6 col-md-6 col-sm-12">
                <span className="adon-icon">
                  <span className="fa fa-envelope-o"></span>
                </span>
                <input type="email" className="form-control" placeholder="Enter Email" value={email} onChange={(event) => setEmail(event.target.value)} />
              </div>

              <div className="form-group col-lg-6 col-md-6 col-sm-12">
                <span className="adon-icon">
                  <span className="fa fa-phone"></span>
                </span>
                <input type="text" className="form-control" value={user_profile.phone} disabled />
              </div>

              <div className="form-group col-lg-6 col-md-6 col-sm-12">
                <span className="adon-icon">
                  <span className="fa fa-angle-down"></span>
                </span>
                <select value={city} onChange={(event) => setCity(event.target.value)} className="form-control">
                  <option disabled>Select City Name</option>
                  {cityList.map((cityData) => (
                    <option value={cityData.id}>{cityData.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <button disabled={!isFilled} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4" onClick={signupSubmit}>
            <span className="txt">SignUp</span>
          </button>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userReducer: state.default.userReducer,
    cityListReducer: state.default.cityListReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  userLogin: (params) => UserAction.userLogin(params, dispatch),
  updateReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserSignup);
