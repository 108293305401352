import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import Form from "react-bootstrap/Form";
import { ZoomhostBanner, RedLogo } from "../../../constants/ImageConstants";
import { Navbar, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { SHOW_HIDE_LOADER } from "../../../store/types";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
import CustomModal from "../../../components/common/CustomModal";
import { auth, generateRecaptcha } from "../../../server/firebase";
import { OrderAction } from "../../../store/action";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ZoomHost = (props) => {
  const { customDispatch, createServiceQuote, serviceBookingData } = props;
  const { serviceBookingResponse } = serviceBookingData;
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [final, setfinal] = useState("");
  const [data, setData] = useState({
    mobile: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const handleClose = () => {
    setShow(false);
  };
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "mobile") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span className="text-danger"></span>;
    }
  };
  const GetModalContent = () => {
    return (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.userPhone} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input type="text" className="form-control" maxLength={6} placeholder="Enter OTP Code" value={optCode} onChange={(event) => setOptCode(event.target.value)} />
            </div>
          </div>
          <button onClick={onCodeSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    );
  };
  const serviceQuoteCall = () => {
    const apiParam = {
      task: "createZoomHostTracker",
      mobile: data.mobile,
      link: window.location.href,
    };
    createServiceQuote(apiParam);
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        serviceQuoteCall();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  // const sendOtp = () => {
  //   customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
  //   const mynumber = `+91${data.mobile}`;
  //   if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
  //   const verify = window.recaptchaVerifier;
  //   signInWithPhoneNumber(auth, mynumber, verify)
  //     .then((result) => {
  //       setfinal(result);
  //       customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
  //       setShow(true);
  //     })
  //     .catch((err) => {
  //       customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
  //       alert(err);
  //     });
  // };
  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      serviceQuoteCall();
    }
    setValidated(true);
  };
  useEffect(() => {
    if (serviceBookingResponse !== null) {
      window.location.href = "https://zoomcar-host.onelink.me/5xXf/98ftutyz";
    }
  }, [serviceBookingResponse, navigate, customDispatch, data]);

  return (
    <>
      <Row>
        <Col id="zoomhost-footer">
          {/* header */}
          <Col className="vc-header-profiler main-header header-style-three alternate">
            <div className="header-lower">
              <div className="auto-container clearfix" style={{ padding: "0px" }}>
                <Navbar fixed="top" bg="light" expand="lg" className="py-0">
                  <Container className="px-1">
                    <Navbar.Brand>
                      <div className="logo">
                        <img src={RedLogo} className="w-50 rounded-0 d-none d-md-block d-lg-block" alt="VehicleCare Logo" title="VehicleCare Logo" />
                        <img src={RedLogo} className="vehiclecare-logo-top rounded-0 d-block d-md-none d-lg-none " alt="VehicleCare Logo" title="VehicleCare Logo" />
                      </div>
                    </Navbar.Brand>
                  </Container>
                </Navbar>
              </div>
            </div>
          </Col>

          <div className="price-section aboutus pb-3 custom-claim-form">
            <div className="auto-container getintouch">
              <div className="row clearfix h-100">
                <div className="form-column col-lg-6 col-md-12 col-sm-12 my-auto d-none d-md-block d-lg-block">
                  <div className="inner-column">
                    <div className="sec-title">
                      <h1 className="title">Earn upto 7 lac Annually</h1>
                      <h2>Onboard Zoomcar With VehicleCare</h2>
                    </div>
                    <div className="newsletter-form-two col-md-9 col-sm-12">
                      <Form noValidate validated={validated} onSubmit={submitForm}>
                        <div className="form-group">
                          <input required type="text" maxLength={10} name="mobile" placeholder="Enter Your Mobile Number" onChange={(e) => handleChange(e)} value={data.mobile} />
                          <Form.Control.Feedback type="invalid">Please enter your Mobile Number</Form.Control.Feedback>
                          <ErrorOutput case={data.mobile} name={"mobile"} />

                          <button type="submit" className="theme-btn submit-btn" name="submit-form">
                            Download APP
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>

                <div className="blocks-column col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">
                    <div className="row clearfix">
                      <img src={ZoomhostBanner} className="w-auto img-responsive" alt="Zoomhost" />
                    </div>
                  </div>
                </div>

                <div className="form-column col-lg-6 col-md-12 col-sm-12 my-auto text-center d-block d-md-none d-lg-none">
                  <div className="inner-column">
                    <div className="sec-title">
                      <h2 className="mb-2">Onboard Zoomcar With VehicleCare</h2>
                      <h5 className="text-black">
                        <b>Earn upto 7 lac Annually</b>
                      </h5>
                    </div>
                    <div className="appointment-form login-form col-9 text-center mx-auto">
                      <Form noValidate validated={validated} onSubmit={submitForm}>
                        <div className="form-group">
                          <input required type="text" maxLength={10} name="mobile" placeholder="Enter Your Mobile Number" onChange={(e) => handleChange(e)} value={data.mobile} />
                          <Form.Control.Feedback type="invalid">Please enter your Mobile Number</Form.Control.Feedback>
                          <ErrorOutput case={data.mobile} name={"mobile"} />

                          {/* <button type="submit" className="theme-btn submit-btn" name="submit-form">
                            Download APP
                          </button> */}

                          <div className="form-group col-lg-12 col-md-12 col-sm-12 mt-3">
                            <button className="theme-btn btn-style-seven" type="submit" name="submit-form">
                              Download APP
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* footer */}
          <div className="footer-style-two pt-4">
            <div className="auto-container">
              <div className="footer-bottom">
                <div className="inner-container pt-0">
                  <div className="row clearfix">
                    <div className="column col-lg-6 col-md-12 col-sm-12">
                      <div className="copyright">Copyright &copy; {moment().format("YYYY")} VehicleCare. All rights reserved.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div id="custom-captcha-verifier"></div>
      <CustomModal show={show} modalSize={"md"} content={<GetModalContent />} isCloseButton={true} handleClose={handleClose} />
    </>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) => OrderAction.createCustomServiceBooking(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ZoomHost);
