import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { GooglePlayQR } from "../../../constants/ImageConstants";
import { isMobile, isIOS, isAndroid } from "react-device-detect";

const OurApp = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container pt-5 pb-5">
          <div className="sec-title centered pt-4">
            <div className="title">We provide trusted services for you</div>
            <h2>Download Our App</h2>
          </div>
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-3 col-sm-12 text-center mb-3">
               
              {isMobile && isIOS && (
                <div className="p-3">
                  {" "}
                  <a href="https://apps.apple.com/in/app/vehiclecare/id1634342039" rel="nofollow">
                    <img src={GooglePlayQR} alt="VehicleCare Apple APP" loading=" lazy" />
                  </a>
                </div>
              )}

              {isMobile && isAndroid && (
                <div className="p-3">
                  {" "}
                  <a href="https://play.google.com/store/apps/details?id=com.app.arvindVehicleCare" rel="nofollow">
                    <img src={GooglePlayQR} alt="VehicleCare Android APP" loading=" lazy" />
                  </a>
                </div>
              )}

              {!isMobile  && (
                <div className="p-3">
                  {" "}
                  <a href="https://play.google.com/store/apps/details?id=com.app.arvindVehicleCare" rel="nofollow">
                    <img src={GooglePlayQR} alt="VehicleCare Android APP" loading=" lazy" />
                  </a>
                </div>
              )}
              
              {/* <div className="d-md-block d-lg-block">
                <div className="">
                  {" "}
                  <a href="https://play.google.com/store/apps/details?id=com.app.arvindVehicleCare" rel="nofollow">
                    <img src={PlayStore} alt="VehicleCare Android APP" loading=" lazy" className="w-75" />
                  </a>
                </div>

                <div className="">
                  {" "}
                  <a href="https://apps.apple.com/in/app/vehiclecare/id1634342039" rel="nofollow">
                    <img src={AppStoreBlack} alt="VehicleCare Apple APP" loading=" lazy" className="w-75" />
                  </a>
                </div>
              </div> */}
              
              </div>
            </div>

            {/* <div className="row justify-content-center">
              <div className="col-lg-3 col-md-3 col-sm-12 text-center mb-3">
                <div className="p-3">
                  {" "}
                  <a href="/">
                    <img src={AppStore} alt="" />
                  </a>
                </div>
              </div>
            </div> */}

            {/* <div className="newsletter-form-two row justify-content-center">
              <div className="emailed-form col-lg-5 col-md-5 col-sm-12 text-center mb-3">
                <form method="post" action="index.html">
                  <div className="form-group">
                    <input type="tel" name="number" placeholder="Enter your number" required="" autoComplete="off" />
                    <button type="submit" className="theme-btn submit-btn d-none d-md-block d-lg-block">
                      {" "}
                      Get App link{" "}
                    </button>
                    <button type="submit" className="theme-btn submit-btn d-sm-block d-md-none d-lg-none">
                      {" "}
                      <i className="fa fa-download"></i>{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default OurApp;
