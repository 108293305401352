import React, { useState } from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import {
  Appbg,
  ServiceBg,
  Pattern1,
  Pattern2,
} from "./../../../constants/ImageConstants";
import OurApp from "./../../../components/common/ourApp";
import FaqQuery from "./../../../components/common/faqQuery";
import DigitalBenifit from "../Solutions/DigitalBenifit";
import IOurBestServices from "./IOurBestServices";
import Testimonial from "./../../../components/common/Testimonial";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import VcRepairs from "./VcRepairs";

import BlogMultiImageCarousel from "./../../../components/common/blogMultiImageCarousel";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { OrderAction } from "../../../store/action";
import Banner from "./Banner";
import LazyLoad from "react-lazy-load";
import { useParams } from "react-router-dom";
import { colors } from "@mui/material";
import TrafficChallanCars24 from "../../../components/common/TrafficChallanCars24";

const Home = (props) => {
  const { cityData } = props;

  const faqScemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: `How can i book my car service with Vehiclecare in ${cityData.selectedCity}?`,
        acceptedAnswer: {
          "@type": "Answer",
          text: `<p>Your car service is just around the corner near your location in ${cityData.selectedCity} .Book directly from our website. Want a more human experience ?call or whatsapp us on 9911020209</p>`,
        },
      },
      {
        "@type": "Question",
        name: `How many VehicleCare car service are there in ${cityData.selectedCity}?`,
        acceptedAnswer: {
          "@type": "Answer",
          text: "We have more than 50 car service centres offering a variety of services such as car wash, car repair, and other mehcanical repair at affordable price.",
        },
      },
      {
        "@type": "Question",
        name: "How much time it will take to deliver our car?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "There is time when you could not see your car for 5-6 hours. In some cases, it will be delivered instantaneously.",
        },
      },
    ],
  };

  const { selectedCity } = cityData;
  const Cityid = cityData.selectedCity.toLowerCase();
  const urlParam = useParams();
  const metaTagData = { title: "", description: "" };
  const getMetaTagData = (city) => {
    switch (Cityid) {
      case "gurgaon":
        metaTagData.title = `Can You Search The Best Car Service In Gurgaon - VehicleCare`;
        metaTagData.description = `Can You Search Car Service In Gurgaon - From Servicing, Repairs, And Accident Claims To Meticulous Wash, Denting, Painting, And More. Affordable Price In India`;

        break;
      case "mumbai":
        metaTagData.title = `Best Car Service In Mumbai, and Car Accidental Claim Repair`;
        metaTagData.description = `Discover Premium Car Service In Mumbai - From Servicing, Repairs, And Accident Claims To Meticulous Wash, Denting, Painting, And More.Affordable Price In India`;
        break;
      case "ahmedabad":
        metaTagData.title = `Book Top-Notch Car Services In Ahmedabad And Accidental Claim`;
        metaTagData.description = `Best Top-Notch Car Care Services In Ahmedabad  With VehicleCare. From Maintenance,  Enjoy Hassle-Free Service With Complimentary Pickup And Drop`;
        break;
      case "delhi":
        metaTagData.title = `Find The Best Car Service And Repair In Delhi - VehicleCare`;
        metaTagData.description = `Can You Search Car Service And Repair In Delhi - From Servicing, And Accident Claims To Meticulous Wash, Denting, Painting, And More.Affordable Price In India`;
        break;
      case "chennai":
        metaTagData.title = `Find The Trusted Car Service In Chennai With Affordable Price`;
        metaTagData.description = `Find The Trusted Car Service And Repair In Chennai - From Servicing, And Accident Claims To Meticulous Wash, Denting, Painting, And More.Affordable Price In India`;
        break;
      case "bengaluru":
        metaTagData.title = `Find The Trusted Car Service In Bengaluru With Lowest Price`;
        metaTagData.description = `Find The Best Car Service In Bengaluru - From Servicing, And Accident Claims To Meticulous Wash, Denting, Painting, And More. Lowest Price In India`;
        break;

      default:
        metaTagData.title = `Book Top-Notch Car Services In ${cityData.selectedCity} And Accidental Claim`;
        metaTagData.description = `Best Top-Notch Car Care Services In ${cityData.selectedCity} With VehicleCare. From Maintenance,  Enjoy Hassle-Free Service With Complimentary Pickup And Drop`;
        break;
    }
    return metaTagData;
  };

  if (cityData && cityData.selectedCity) getMetaTagData(cityData.selectedCity);
  return (
    <>
      <Helmet>
        <title>{metaTagData.title}</title>
        <meta name="description" content={metaTagData.description} />
        <meta
          property="og:url"
          content={`https://vehiclecare.in/${cityData.selectedCity.toLowerCase()}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />
        <meta
          property="twitter:title"
          content={`Book Top-Notch Car Services In ${cityData.selectedCity} And Accidental Claim`}
        />
        <meta
          property="twitter:description"
          content={`Best Top-Notch Car Care Services In ${cityData.selectedCity} With VehicleCare. From Maintenance,  Enjoy Hassle-Free Service With Complimentary Pickup And Drop`}
        />
        <meta
          property="twitter:url"
          content={`https://vehiclecare.in/${cityData.selectedCity.toLowerCase()}`}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta
          property="twitter:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />

        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(faqScemaData)}
        </script>
        <link
          rel="canonical"
          href={`https://vehiclecare.in/${cityData.selectedCity
            .toLowerCase()
            ?.replace(" ", "-")}`}
        />
      </Helmet>

      <Header />

      <Row>
        <section className="banner-section-two style-two">
          <div className="main-slider-carousel active">
            <div className={`slide ${!isMobile ? "mt-0 pb-0" : "pt-4"}`}>
              <Banner />
            </div>
          </div>
        </section>

        {/* About Section */}
        {/* <div className="about-section pt-0">
          <div className="auto-container">
            <IAboutUs />
          </div>
        </div> */}

        {/* Our Best Service Section */}
        <div
          className="services-section-four pb-4"
          style={{
            backgroundImage: `url(${ServiceBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        >
          <IOurBestServices city={cityData?.selectedCity} />
        </div>

        {/* <section className="about-section pt-0 pb-4">
          <img src={VC2024} alt="" />
        </section> */}

        {/* Extra Section */}
        {/*Premium Car Service */}
        <section className="about-section pt-0">
          <div className="pattern-layer-one"></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix">
                <div className="content-column col-lg-12 col-md-12 col-sm-12">
                  <div className="inner-column mt-lg-5">
                    <div className="sec-title mb-4">
                      <h2 className="text-center">
                        Why Choose <font color="#ed1c24">VehicleCare</font> for
                        Car Services in {cityData.selectedCity}?
                      </h2>
                      <div className="text text-left px-2">
                        Your Car is your asset, your prized possession you care
                        about. But do you care about your car the right way?
                        Take care of your car the right way with VehicleCare. We
                        bring you India's best Car service near you in{" "}
                        {cityData.selectedCity}, at the most affordable prices.
                      </div>
                    </div>

                    <div className="detail px-2">
                      <div className="d-flex justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-2">
                            <h4 className="text-left text-dark">
                              <b>
                                But, why should you choose VehicleCare for your
                                Car related services? Let us have a look!
                                {/* <font color="#ed1c24">{cityData.selectedCity}</font> */}
                              </b>
                            </h4>
                          </div>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                We Deliver What we promise at the most
                                Affordable price -{" "}
                              </font>
                            </b>
                            VehicleCare always keeps the quality of work that it
                            promises to deliver. We believe that you bought your
                            car after putting in a lot of thought and money, so
                            we understand the sentiments connected to your car.
                            That is why every time you visit a VehicleCare
                            workshop, we make sure you get exactly what you
                            asked for. To achieve this we employ only skilled
                            and certified professionals who are equipped to take
                            care of all car-related issues for every make and
                            model.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Only Genuine Parts -{" "}
                              </font>
                            </b>
                            We use only genuine parts for your car. All parts
                            used by us are 100% authentic parts that are
                            approved by the brand. We never compromise on
                            quality. All parts that we use in the repair of your
                            car are original equipment manufacturers (OEM) and
                            manufactured particularly for your car.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                A Wide Range of Services available at Affordable
                                Rates -{" "}
                              </font>
                            </b>
                            From routine service to damage repair, VehicleCare
                            is always prepared for every type of car-related
                            issue. Our strategic partnerships allow us to save
                            money the benefits of which we pass on to you, just
                            providing you quality service at awesome competitive
                            prices and heavy discounts once in a while.
                            VehicleCare customers always go home with a smile on
                            their faces because of our competitive and
                            affordable rates.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Easy Online Booking and Free Pick-Up and Drop -{" "}
                              </font>
                            </b>
                            Booking a Car service with VehicleCare is a piece of
                            cake. You can book an appointment in a few simple
                            steps with the VehicleCare app and website. We also
                            offer for your ease, free car pick-up and drop
                            service and we are always on time.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Pan India Presence -{" "}
                              </font>
                            </b>
                            VehicleCare has a pan-India presence, with more than
                            1000 workshops in more than 200 Indian cities. We
                            are always nearby for any of your car trouble. Our
                            VehicleCare comes with a standard 1000 km or 1-month
                            warranty guarantee that is applicable across 1000+
                            VehicleCare workshops across India.
                            {/* We provide centralised service support, so even if you live in {cityData.selectedCity} and take service in Noida, that’s all right, we got you covered! */}
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Car Brands We Serve in {cityData.selectedCity} -{" "}
                              </font>
                            </b>
                            We service all brands and models of cars from Maruti
                            Suzuki, Hyundai, Mahindra, Honda, Ford, Tata, etc.
                            We also have expertise in all car-related services
                            of high-end luxury brands like Mercedes Benz, Skoda,
                            Volkswagen, Audi, Land Rover, BMW, and Volvo.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Use of AI and ML -{" "}
                              </font>
                            </b>
                            VehicleCare takes pride in leveraging the power of
                            Artificial Intelligence and Machine Learning for the
                            delivery of its services. The use of new-age
                            technologies helps us build a robust and more
                            customer-oriented technology platform, thus
                            revolutionising the entire car repair industry.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                24X7 Customer Support -{" "}
                              </font>
                            </b>
                            We know that Car trouble never comes. We want to be
                            there, whenever you need us. That’s why we give 24x7
                            customer care assistance for all help and support.
                            You can reach us anytime via WhatsApp, e-mail, chat,
                            or phone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="about-section pt-0 pb-0">
          <TrafficChallanCars24 />
        </section> */}

        {/* Faq Section  */}
        <FaqQuery />

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name={cityData.selectedCity} />
        </div>

        {/* VcRepairs */}
        <section className="features-section pt-0">
          <VcRepairs />
        </section>

        {/* Happy Client Section */}
        <div
          className="about-section pt-5"
          style={{ background: "whitesmoke" }}
        >
          <div
            className="pattern-layer-one"
            style={{ backgroundImage: `url(${Pattern1})` }}
          ></div>
          <div
            className="pattern-layer-two"
            style={{ backgroundImage: `url(${Pattern2})` }}
          ></div>
          <DigitalBenifit />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
            paddingBottom: "110px",
          }}
        >
          <OurApp />
        </div>
      </Row>

      {isMobile && (
        <a className="fab" href="tel:9911-020-209">
          <LazyLoad>
            <img src={require("../../../assets/images/phone-call.png")} />
          </LazyLoad>
        </a>
      )}
      {/* <div className="d-lg-none d-md-none d-block mt-5">
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="challan_box_mobile">
            <div>
              <h5 className="text-white" style={{ fontWeight: "bold" }}>
                Check Traffic Challan Status & Pay Online
              </h5>
              <div>
                <li className="challan_desc_mobile">
                  ✅ Free access to your traffic eChallans.
                </li>
                <li className="challan_desc_mobile">✅ Pay effortlessly.</li>
                <li className="challan_desc_mobile">
                  ✅ No hassle of court visits.
                </li>
              </div>
            </div>
            <a
              href="https://cars24.com/traffic-challan?utm_source=VEHICLECARE&utm_medium=APP&utm_campaign=LISTING"
              className="btn check-btn inactive p-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                class="btn btn-outline-light hover-btn-mobile"
                style={{ fontWeight: "bold", marginTop: "20px" }}
              >
                Check now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="d-lg-block d-md-block d-none">
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="challan_box">
            <div>
              <h5 className="text-white" style={{ fontWeight: "bold" }}>
                Check Traffic Challan Status & Pay Online
              </h5>
              <div style={{ display: "flex", marginTop: "15px" }}>
                <li className="challan_desc">
                  ✅ Free access to your traffic eChallans.
                </li>
                <li className="challan_desc">✅ Pay effortlessly.</li>
                <li className="challan_desc">✅ No hassle of court visits.</li>
              </div>
            </div>
            <a
              href="https://cars24.com/traffic-challan?utm_source=VEHICLECARE&utm_medium=APP&utm_campaign=LISTING"
              className="btn check-btn inactive"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                class="btn btn-outline-light hover-btn"
                style={{ fontWeight: "bold" }}
              >
                Check now
              </button>
            </a>
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
