import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { CarEngine1, CarEngine2, CarEngine3, CarEngine4, CarExterior1, CarExterior2, CarExterior3, CarExterior4, CarInterior1, CarInterior2, CarInterior3, CarInterior4, CarVaccum1, CarVaccum2, CarVaccum3, CarVaccum4, CarWax2, CarWax3, CarWax4, CarWax5 } from "../../../../constants/ImageConstants";

const Gallery = (props) => {

  return (
    <>
      <Row>
        {/*  Gallery Section */}
          <div className="auto-container">
        
            <div className="sec-title centered">
              {/* <div className="title">Type of Car Wash</div> */}
              <h2>Types of Car Wash</h2>
            </div>

            <div className="mixitup-gallery col-10 mx-auto">
              <div className="filters clearfix">
                <Tabs
                  defaultActiveKey="deep-cleaning"
                  id="justify-tab-example"
                  className="mb-3 filter-tabs filter-btns text-center clearfix"
                  justify
                >

                  {/* <Tab eventKey="all" title="All" className="filter">
                    <div className="row pt-3">

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarEngine1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarExterior1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarInterior1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarWax1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarVaccum1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarExterior2} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarInterior3} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarVaccum4} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab> */}

                  <Tab eventKey="exterior" title="Exterior"  className="filter">
                    <div className="row pt-3">
                      {/* Exterior tab */}
                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarExterior1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarExterior2} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarExterior3} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarExterior4} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="interior" title="Interior">
                    <div className="row pt-3">
                      {/* interior tab */}
                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarInterior1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarInterior2} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarInterior3} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarInterior4} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="deep-cleaning" title="Deep Cleaning of the Engine">
                    <div className="row pt-3">
                      {/* deep-cleaning tab */}
                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarEngine1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarEngine2} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarEngine3} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarEngine4} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="waxing" title="Waxing">
                    <div className="row pt-3">
                      {/* waxing tab */}
                      {/* <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarWax1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div> */}

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarWax2} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarWax3} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarWax4} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarWax5} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="vacuuming" title="Car Vacuuming Cleaning">
                    <div className="row">
                      {/* vacuuming tab */}
                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarVaccum1} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarVaccum2} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarVaccum3} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="gallery-block repair col-lg-3 col-md-4 col-sm-12">
                        <div className="inner-box">
                          <div className="image">
                            <img src={CarVaccum4} className="w-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  
                </Tabs>
              </div>
            
            </div>
          </div>
        {/* <!-- End Gallery Section --> */}

        
      </Row>

    </>
  );
};

export default Gallery;
