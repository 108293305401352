/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { connect } from "react-redux";
import { VehicleAction } from "../../../store/action";
import { CreateInsuranceLeadAction, InsuranceCompanyList, UploadInsuranceMediaAction } from "../../../store/action";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../../../components/NavigationBar/Footer";
import Header from "../../../components/NavigationBar/Header";
import { SET_BRAND, SET_MODEL, SET_VARIANT } from "../../../store/types";
import { Helmet } from "react-helmet";

const ErrorOutput = (props) => {
  let name = props.name;
  let inputValue = props.case;
  if (name === "insured_name") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "insured_phone") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span>Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "insured_email") {
    if (!inputValue?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) && inputValue?.length > 0) {
      return <span>Invalid email address</span>;
    }
    return <span></span>;
  }
  if (name === "car_year") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span>Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "car_reading") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span>Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "car_registration") {
    if (!inputValue?.match(/^[A-Z0-9._%+-]+$/) && inputValue?.length > 0) {
      return <span>Invalid Registration Number</span>;
    }
    return <span></span>;
  }
  if (name === "insured_city") {
    if (!inputValue?.match(/^[a-zA-Z]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "insured_pincode") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span>Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "insurance_type") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "insurance_company") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "cause_of_damage") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "insured_type") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "car_brand") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "car_variant") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span>Letters only</span>;
    }
    return <span></span>;
  }
  return <></>;
};

const WorkshopOnboard = (props) => {
  const [validated, setValidated] = useState(false);
  let vehicleData = [];
  const { userData, vehicleData: vehicleInfo } = props;
  const { is_user_logged_in, user_profile } = userData;
  const { brand, model, variant } = vehicleInfo;
  vehicleData.car_brand = brand;
  vehicleData.car_model = model;
  vehicleData.car_variant = variant;
  let insuranceCompanyList = [];
  insuranceCompanyList = props.insuranceCompanyList;
  useEffect(() => {
    props.getInsuranceCompanies({ task: "getInsuranceCompanies" });
  }, [is_user_logged_in]);
  const [data, setData] = useState({
    claimType: "",
    insurance_company: "",
    assistance: "no",
    car_brand: `${user_profile?.email}` || "",
    car_model: "",
    car_variant: "",
    insured_name: `${user_profile?.first_name} ${user_profile?.last_name}` || "",
    insured_email: `${user_profile?.email}` || "",
    insured_phone: `${user_profile?.phone}` || "",
    car_registration: "",
    insured_type: "Insured",
    car_year: "",
    car_reading: "",
    insured_address: "",
    insured_city: props.cityList.selectedCity,
    insured_pincode: "",
    insurance_type: "zeroDept",
    cause_of_damage: "",
    policy_pdf: "",
    images: "",
    claim_date: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const handleClick = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const submitFormData = () => {
    if (
      data.claim_date.length > 0 &&
      data.insured_name.length > 0 &&
      data.insured_email.length > 0 &&
      data.insurance_company.length > 0 &&
      data.insurance_type.length > 0 &&
      data.insured_city.length > 0 &&
      data.insured_address.length > 0 &&
      data.insured_phone.length > 0 &&
      data.insured_pincode.length > 0 &&
      data.cause_of_damage.length > 0 &&
      data.car_mode.length > 0 &&
      data.car_brand.length > 0 &&
      data.car_model.length > 0 &&
      data.car_variant.length > 0 &&
      data.car_reading.length > 0 &&
      data.car_registration.length > 0 &&
      data.car_year.length > 0 &&
      isNaN(data.insured_name) &&
      isNaN(data.insured_email) &&
      isNaN(data.insured_city) &&
      isNaN(data.insured_address) &&
      isNaN(data.insured_type) &&
      isNaN(data.insurance_company) &&
      isNaN(data.insurance_type) &&
      isNaN(data.car_mode) &&
      isNaN(data.car_year) &&
      isNaN(data.car_brand) &&
      isNaN(data.car_model) &&
      isNaN(data.car_variant) &&
      isNaN(data.car_registration)
    ) {
      data.claim_date = startDate;
      props.getInsuranceLead({
        task: "createInsuranceLead",
        insured_name: data.insured_name,
        insured_email: data.insured_email,
        insured_phone: data.insured_phone,
        car_brand: data.car_brand,
        car_mode: data.car_mode,
        car_year: data.car_year,
        car_variant: data.car_variant,
        car_reading: data.car_reading,
        car_registration: data.car_registration,
        claim_date: data.claim_date,
        insured_address: data.insured_address,
        insured_city: data.insured_city,
        insured_pincode: data.insured_pincode,
        insurance_type: data.insurance_type,
        insurance_company: data.insurance_company,
        cause_of_damage: data.cause_of_damage,
        insured_type: data.insured_type,
      });
      return true;
    } else {
      return false;
    }
  };

  const [selectedImageFile, setSelectedImageFile] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const changeImageHandler = (event) => {
    setSelectedImageFile(event.target.files[0]);
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmission = () => {
    const formData = new FormData();

    formData.append("pdf", selectedFile);
    props.sendInsuranceMedia({
      task: "uploadInsuranceMedia",
      proposal_id: props.proposalID?.proposal_id,
      type: "document",
      ...formData,
    });
  };
  const handleImageSubmission = () => {
    const formData1 = new FormData();
    formData1.append("Image", selectedImageFile);
    props.sendInsuranceMedia({
      task: "uploadInsuranceMedia",
      proposal_id: props.proposalID?.proposal_id,
      type: "image",
      formData: formData1,
    });
  };
  useEffect(() => {
    if (props.proposalID?.proposal_id) {
      handleSubmission();
      handleImageSubmission();
    }
  }, [props.proposalID?.proposal_id]);

  const submitForm = () => {
    console.log(data);
    submitFormData();
  };
  const [variantList, setVariant] = useState(null);
  const getModelList = (val) => {
    const selectedBrandName = val;
    const newBrandList = vehicleInfo.brandList.filter((elemn) => elemn.name === selectedBrandName);
    props.updateVehicle({ type: SET_BRAND, payload: newBrandList[0] });
    if (newBrandList[0]?.id?.length > 0) {
      const selectedBrandId = newBrandList[0]?.id;
      const params = { task: "getCardModels", brand_id: selectedBrandId };
      props.getModelNames(params);
    }
  };
  const getVariantList = (val) => {
    const modelName = val;
    const newModelList = vehicleInfo.modelList.filter((elemn) => elemn.model_name === modelName);
    const newObject = {};
    newObject.id = newModelList?.[0].model_id;
    newObject.name = newModelList?.[0].model_name;
    newObject.icon = newModelList?.[0].icon;
    newObject.segment_type = newModelList?.[0].segment_type;
    newObject.variants = newModelList?.[0].variants;
    const updatedModelList = newObject;
    props.updateVehicle({ type: SET_MODEL, payload: updatedModelList });

    setVariant(newModelList?.[0].variants);
  };
  const setSelectedVariant = (val) => {
    const selectedVariantName = val;
    const newVariantList = variantList.filter((elemn) => elemn.variant === selectedVariantName);
    props.updateVehicle({ type: SET_VARIANT, payload: newVariantList[0] });
  };

  const [field, setField] = useState([]);
  return (
    <>
      <Helmet>
        <title>Car Service Simplified - VehicleCare</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />
      </Helmet>
      
      <Header isStatic={true} />

      <Row className="price-section aboutus mt-5">
        <Col className="">
          <div className="auto-container">
            <div className="sec-title centered">
              <div className="title">Workshop Details</div>
              <h2>Information</h2>
            </div>

            <div className="row clearfix">
              <div className="appointment-form login-form">
                <div className="right-sidebar col-lg-12 col-md-12 col-sm-12">
                  <div className="lower-section">
                    <div className="appointment-form login-form">
                      <Form noValidate validated={validated} onSubmit={submitForm}>
                        <div className="row clearfix">
                          <div className="row clearfix pb-5">
                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Workshop Name</Form.Label>
                              <input name="workshopName" type="text" placeholder="Enter Workshop Name" required="" />
                              <small className="form-text text-muted">Example: KM moters</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Owner Name</Form.Label>
                              <input name="ownerName" type="text" placeholder="Enter Owner Name" required="" />
                              <small className="form-text text-muted">Example: Yash</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>phone Number</Form.Label>
                              <input
                                name="phoneNumber"
                                type="text"
                                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode ==0) || (event.charCode ==46)"
                                maxlength="10"
                                placeholder="Enter phone Number"
                                required=""
                              />
                              <small className="form-text text-muted">Example: 9876543210</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Workshop manager Name</Form.Label>
                              <input name="workshopManagerName" type="text" placeholder="Enter manager Name" required="" />
                              <small className="form-text text-muted">Example: Raj</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Manager contact no.</Form.Label>
                              <input
                                name="workshopManagerNumber"
                                type="text"
                                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode ==0) || (event.charCode ==46)"
                                maxlength="10"
                                placeholder="Enter Manager contact no"
                                required=""
                              />
                              <small className="form-text text-muted">Example: 9876543210</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>GST Number</Form.Label>
                              <input name="gstNumber" type="text" placeholder="Enter Gst" required="" />
                              <small className="form-text text-muted">Example: Gurgaon</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>PAN Number:</Form.Label>
                              <input type="text" name="pan" placeholder="Enter PAN" required="" />
                              <small className="form-text text-muted">Example: PUBG2020T</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Upload Document:</Form.Label>
                              <input type="file" name="uploadDoc" placeholder="Insurance (Policy)" onChange={changeHandler} accept="pdf/*" />
                              <ErrorOutput case={data.uploadDoc} name={"uploadDoc"} />
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Attached Document</Form.Label>
                              <input type="file" name="attachedDocument" onChange={changeImageHandler} placeholder="Attached Documents" />
                              <ErrorOutput case={data.attachedDocument} name={"attachedDocument"} />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>City</Form.Label>
                              <input
                                type="text"
                                list="citylist"
                                value={data.insured_city}
                                placeholder="Select City"
                                aria-describedby="inputGroupPrepend"
                                name="insured_city"
                                onChange={(e) => handleClick(e)}
                              />
                              <datalist id="citylist">
                                {props?.cityList?.cityList.map((city) => {
                                  return (
                                    <option value={city.name} key={city.id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                              </datalist>
                              <Form.Control.Feedback type="invalid">Please select city</Form.Control.Feedback>
                              <ErrorOutput case={data.insured_city} name={"insured_city"} />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Pincode</Form.Label>
                              <input type="text" name="insured_pincode" aria-describedby="inputGroupPrepend" required="" onChange={(e) => handleClick(e)} />
                              <Form.Control.Feedback type="invalid">Please enter pincode</Form.Control.Feedback>
                              <ErrorOutput case={data.insured_pincode} name={"insured_pincode"} />
                              {/* <ErrorOutput case={data.insured_pincode} name={"insured_pincode"} /> */}
                            </div>

                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                              <Form.Label>Workshop Address</Form.Label>
                              <input name="workshopAddress" type="text" placeholder="Enter Address" required="" />
                              <small className="form-text text-muted">Example: sector 16</small>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Insurance Company</Form.Label>
                              <input
                                type="text"
                                list="insuranceCompanyList"
                                placeholder="Select Insurance Company"
                                aria-describedby="inputGroupPrepend"
                                name="insurance_company"
                                onChange={(e) => handleClick(e)}
                              />
                              <datalist id="insuranceCompanyList">
                                {insuranceCompanyList?.map((company) => {
                                  return (
                                    <option value={company.name} key={company.id}>
                                      {company.name}
                                    </option>
                                  );
                                })}
                              </datalist>
                              <Form.Control.Feedback type="invalid">Please select claim type</Form.Control.Feedback>
                              <ErrorOutput case={data.company} name={"company"} />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Ready to Work </Form.Label>
                              {/* <select data-actions-box="true" 
                                  data-live-search="true" name="workType" required="">
                                  <option value="b2b">B2B</option>
                                  <option value="b2c">B2C</option>
                                  <option value="insurance">Insurance</option>
                              </select> */}

                              <Form.Control as="select" multiple value={field} onChange={(e) => setField([].slice.call(e.target.selectedOptions).map((item) => item.value))}>
                                <option value="b2b">B2B</option>
                                <option value="b2c">B2C</option>
                                <option value="insurance">Insurance</option>
                              </Form.Control>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Working Hours</Form.Label>
                              <select name="workHours" required="">
                                <option value="" selected disabled>
                                  Select Type
                                </option>
                                <option value="9-6">9am to 6pm</option>
                                <option value="10-7">10am to 7pm</option>
                                <option value="other">other</option>
                              </select>
                              <small className="form-text text-muted">Example: 9am to 6pm</small>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Week off </Form.Label>

                              <select name="weekOff" required="">
                                <option value="" selected disabled>
                                  Select Weekoff
                                </option>
                                <option value="Sunday">Sunday</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                              </select>
                              <small className="form-text text-muted">Example: Sunday</small>
                            </div>
                          </div>

                          <div className="row clearfix border-top py-5">
                            <div className="col-12 mb-5 sec-title">
                              <h2> Banking Details of workshop</h2>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Bank Name.</Form.Label>
                              <input name="bankName" type="text" placeholder="Enter BANK NAME" required="" />
                              <small className="form-text text-muted">Example: SBI</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Bank Account No.</Form.Label>
                              <input name="bankAccountNumber" type="text" placeholder="Enter Bank Account No" required="" />
                              <small className="form-text text-muted">Example: ****</small>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>IFSC Code</Form.Label>
                              <input name="ifscCode" type="text" placeholder="Enter IFSC Code" required="" />
                              <small className="form-text text-muted">Example: 120SSB</small>
                            </div>
                          </div>

                          <div className="row clearfix border-top py-5">
                            <div className="col-12 mb-5 sec-title">
                              <h2> Tools and equipments details of workshop</h2>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>No. of Lift</Form.Label>
                              <input name="numberOfLift" type="number" min="1" placeholder="Enter No. of Lift" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Paint Booth</Form.Label>
                              <select name="paintBooth" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Dent Puller</Form.Label>
                              <select name="dentPuller" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Gas welding</Form.Label>
                              <select name="gasWelding" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Mig welding</Form.Label>
                              <select name="migWelding" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Tig welding</Form.Label>
                              <select name="tigWelding" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Bench</Form.Label>
                              <select name="bench" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Gas Cutter</Form.Label>
                              <select name="gasCutter" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Vaccum Cleaner</Form.Label>
                              <select name="vaccumCleaner" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Scanner</Form.Label>
                              <select name="scanner" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Compressor</Form.Label>
                              <select name="compressor" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Paint Machine Company</Form.Label>
                              <input name="paintMachineCompany" type="text" placeholder="Enter Machine Company" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>IR Lamp</Form.Label>
                              <select name="irLamp" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Car O Liner</Form.Label>
                              <select name="carOLiner" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Alignment & Balancing Machine</Form.Label>
                              <select name="alignmentBalancing" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>

                          <div className="row clearfix border-top py-5">
                            <div className="col-12 mb-5 sec-title">
                              <h2> Facilities</h2>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>No. of Mechnical Bay</Form.Label>
                              <input name="numberOfMechnicalBay" type="number" min="1" placeholder="Enter No. of Mechnical Bay" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>No. of Denting Bay</Form.Label>
                              <input name="numberOfDentingBay" type="number" min="1" placeholder="Enter No. of Denting Bay" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>No. of Painting Bay</Form.Label>
                              <input name="numberOfPaintingBay" type="number" min="1" placeholder="Enter No. of Painting Bay" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Area in Sqft.</Form.Label>
                              <input name="areaInSqft" type="number" placeholder="Enter Area in Sqft." required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>No. of Vehicles Parking</Form.Label>
                              <input name="numberOfVehiclesParking" type="number" min="1" placeholder="Enter No. of Vehicles Parking" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>No. of Washing Bay</Form.Label>
                              <input name="numberOfWashingBay" type="number" min="1" placeholder="Enter No. of Washing Bay" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <Form.Label>Avg. No. of Car Handling Every Month</Form.Label>
                              <input name="averageCarHandling" type="number" placeholder="Enter No. of Car Handling Every Month" required="" />
                            </div>
                          </div>

                          <div className="row clearfix border-top py-5">
                            <div className="col-12 mb-5 sec-title">
                              <h2>Infra</h2>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Store Room</Form.Label>
                              <select name="storeRoom" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Engine Room</Form.Label>
                              <select name="engineRoom" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Custumer Lounge Room</Form.Label>
                              <select name="custumerLoungeRoom" required="">
                                <option disabled selected value="">
                                  Choose
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>

                          <div className="row clearfix border-top py-5">
                            <div className="col-12 mb-5 sec-title">
                              <h2>Manpower</h2>
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <label>No.of Mechanics</label>
                              <input name="numberOfMachanics" type="number" min="1" placeholder="Enter No.of Mechanics" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <label>No.of Electricians</label>
                              <input name="numberOfElectricians" type="number" min="1" placeholder="Enter No.of Electricians" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <label>No.of Denter</label>
                              <input name="numberOfDenter" type="number" min="1" placeholder="Enter No.of Denter" required="" />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-12">
                              <label>No.of Painter</label>
                              <input name="numberOfPainter" type="number" min="1" placeholder="Enter No.of Painter" required="" />
                            </div>
                          </div>

                          <div className="row clearfix border-top py-5">
                            <div className="col-12 mb-5 sec-title">
                              <h2>Contact Info</h2>
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>Current Location Link</Form.Label>
                              <input name="currentLocation" type="text" placeholder="Enter Current Location" required="" />
                            </div>

                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                              <Form.Label>WhatsApp Link</Form.Label>
                              <input name="whatsAppLink" maxlength="10" type="text" placeholder="Enter WhatsApp Number" required="" />
                            </div>
                          </div>

                          <div className="form-group col-lg-12 col-md-12 col-sm-12">
                            <button className="theme-btn btn-style-seven" type="button" name="submit-form">
                              Submit Now
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.insuranceCompanyList = state.default.insuranceCompanyReducer.insuranceCompanyList;
  stateObj.proposalID = state.default.createInsuranceLeadReducer.proposal_id;
  stateObj.file_url_array = state.default.uploadInsuranceMediaReducer;
  stateObj.userData = state.default.userReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  getInsuranceCompanies: (params) => InsuranceCompanyList.getInsuranceCompanies(params, dispatch),
  getInsuranceLead: (params) => CreateInsuranceLeadAction.getInsuranceLead(params, dispatch),
  sendInsuranceMedia: (params) => UploadInsuranceMediaAction.sendInsuranceMedia(params, dispatch),
  getModelNames: (params) => VehicleAction.getModelNames(params, dispatch),
  updateVehicle: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkshopOnboard);
