import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Schedule } from "../../../../constants/ImageConstants";

const PersonalizeYourExperience = () => {
  return (
    
    <Row>
      
      <div className="auto-container">
        <Row className="justify-content-md-center">
          <Col xs={12} lg={11}>
            <Row className="align-items-center">
              
              <Col xs={12} md={7} lg={7}>
                <div className="inner-column">
                  <div className="sec-title">
                    <h2 className="fs-1">Personalize Your Experience</h2>
                  </div>
                  <div className="text">
                    <p className="mb-1">VehicleCare offers the best-class car services at the most affordable prices. Get car services that exactly fit your needs.</p>

                    <p className="mb-1">Only some cars need the same, and we understand that. Our customized solutions let you choose the services that you want. </p>

                    <p className="mb-1">We offer you the flexibility of choice in all kinds of services be it routine maintenance, dent paint, engine tune-up, electrical repairs, or any other car-related services. You get what you want.</p>

                    {/* <p className="mb-1">Our certified technicians suggest the best service and deliver only quality work. Vehiclecare promises you quality, affordability, and trust.</p> */}

                  </div>
                </div>
              </Col>

              <Col xs={12} md={5} lg={5}>
                <Row>
                  <Col className="text-center">
                    <img src={ Schedule } alt="Handshake" className="w-100" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

    </Row>
  );
};

export default PersonalizeYourExperience;
