import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { ProcessOfClaimFile } from "./../../../../constants/ImageConstants";

const VcClaimProcess = () => {
  return (
    <Row>
      <Col>

        <div className="auto-container">
          <div className="">
            <div className="row clearfix">
              <div className="sec-title text-center">
                {/* <div className="title">Digitizing the end-to-end Claim Process </div> */}
                <h2>How to File for Claim on VehicleCare</h2>
              </div>

              <div className="pt-4">
                <div className="row clearfix">
                  <div className="d-flex justify-content-center col inner-box">
                    <div className="col-10">
                      <img src={ ProcessOfClaimFile } alt="Track And Manage Claim Process" />
                    </div>
                  </div>
                  
                </div>
              </div>

            </div>
          </div>
        </div>

      </Col>
    </Row>
  );
};

export default VcClaimProcess;
