import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNavigateUrl } from "../../../../utils/customHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  banner1,
  banner2,
  banner3,
  banner4,
  Successful,
  TotalEnergiesVehicleCare,
  ZoomCar,
} from "../../../../constants/ImageConstants";
import VehicleSelector from "../../../../components/common/vehicleSelector";
import {
  DentingPaintOffer,
  CarWashOffer,
  MechanicalOffer,
  CarServiceOffer,
} from "../../../../constants/ImageConstants";
import { auth, generateRecaptcha } from "../../../../server/firebase";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
import {
  CREATE_WARRANTY,
  MEMBER_ENROLL,
  SHOW_HIDE_LOADER,
} from "../../../../store/types";
import CustomModal from "../../../../components/common/CustomModal";
import { OrderAction, PaymentAction } from "../../../../store/action";
import { initializePayment } from "../../../../utils/paymentHelper";
import moment from "moment";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { isMobile } from "react-device-detect";
import { createWarrantyData } from "../../../../store/action/OrderAction";
import axios from "axios";
import ENV from "../../../../server/environment";
import { da } from "date-fns/locale";

const Banner = (props) => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [optVerified, setOptVerified] = useState(false);
  const initialPrice = 500;
  const [price, setPrice] = useState(initialPrice);
  const [final, setfinal] = useState("");
  const {
    vehicleData,
    partner,
    customDispatch,
    createMemberEnrollment,
    enrollSuccessData,
    paymentSessionData,
    createPaymentSession,
  } = props;
  const { brand, model, variant } = vehicleData;
  const navigate = useNavigate();
  const paymentReturnUrl = "enrollPaymentresponse";
  const [data, setData] = useState({
    userPhone: "",
    carReg: "",
    userName: "",
    selectYear: "1",
    year: "",
    userEmail: "",
    package: "standard",
  });
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "userPhone") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Invalid phone number</span>;
      }
      return <span></span>;
    }
    if (name === "carReg") {
      if (!inputValue?.match(/^[a-z0-9A-Z]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Invalid</span>;
      }
      return <span></span>;
    }
    if (name === "userName") {
      if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span></span>;
    }
    if (name === "userVehicle") {
      if (
        data.userPhone &&
        data.carReg &&
        data.userName &&
        vehicleData?.variant === null
      ) {
        return <span className="text-danger">Select Your Vehicle</span>;
      }
      return <span></span>;
    }

    return <></>;
  };
  const GetPartnerImage = () => {
    let imageUrl = "";
    if (partner === "VC ZOOM") {
      imageUrl = ZoomCar;
    } else {
      imageUrl = TotalEnergiesVehicleCare;
    }
    return (
      <img
        src={imageUrl}
        className="w-25"
        alt=""
        style={{ position: "absolute", top: "-1em", right: "0" }}
      />
    );
  };
  const handleClose = () => {
    if (optVerified) {
      navigate(getNavigateUrl("/"));
    }
    setShow(false);
  };
  const handleClick = (e) => {
    if (e.target.name === "userPhone" && e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      enrollFormSubmit();
    }
    setValidated(true);
  };
  const enrollFormSubmit = () => {
    const apiParam = {
      task: "createMemberEnrollment",
      brand_id: brand.id,
      model_id: model.id,
      variant_id: variant.id.toString(),
      name: data.userName,
      email: data.userEmail,
      mobile: data.userPhone,
      car_reg: data.carReg,
      payment_status: "pending",
    };
    createMemberEnrollment(apiParam);
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        enrollFormSubmit();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  // eslint-disable-next-line no-unused-vars
  const sendOtp = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${data.userPhone}`;
    if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        setShow(true);
      })
      .catch((err) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(err);
      });
  };
  const GetModalContent = () => {
    return true;
    return !optVerified ? (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.userPhone} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                maxLength={6}
                placeholder="Enter OTP Code"
                value={optCode}
                onChange={(event) => setOptCode(event.target.value)}
              />
            </div>
          </div>
          <button
            onClick={onCodeSubmit}
            className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4"
          >
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    ) : (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="col-4 text-center">
              <img src={Successful} alt="" className="w-100" />
            </div>

            <div className="col-8 text-left my-auto">
              <h4>
                <b>
                  <font color="black">Congratulations!!</font>
                </b>
              </h4>
              <h5 style={{ color: "#40c057" }}>
                <b>Your Enquiry is successfully Registered.</b>
              </h5>
              <h6>Our Executive will contact you soon.</h6>
              <button
                type="button"
                className="theme-btn btn-style-nine my-3 mx-5"
                onClick={() => navigate(getNavigateUrl("/"))}
              >
                <span className="txt">Home</span>
              </button>
            </div>
          </div>
        </div>
      </Row>
    );
  };
  useEffect(() => {
    if (paymentSessionData.sessionResponse !== null) {
      initializePayment(
        paymentSessionData.sessionResponse.data.payment_session_id,
        paymentReturnUrl
      );
    }
    if (enrollSuccessData.memberEnrollData !== null) {
      setShow(true);
      setOptVerified(true);
      customDispatch({ type: MEMBER_ENROLL, payload: null });

      axios
        .post(ENV.WAVE_URL, {
          brand_id: brand.id,
          model_id: model.id,
          variant_id: variant.id.toString(),
          name: data.userName,
          email: data.userEmail,
          mobile: data.userPhone,
          car_reg: data.carReg,
          payment_status: "pending",
          order_id: `VC-Enroll-${moment().format("DD-MM-YY-HH-mm-ss")}-${
            enrollSuccessData.memberEnrollData.order_id
          }`,
          order_amount: price,
          customer_id: `${enrollSuccessData.memberEnrollData.order_id}`,
          type: "warranty"
        })
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
      const params = {
        task: "getpaymentGatewayOrderDetails",
        order_id: `VC-Enroll-${moment().format("DD-MM-YY-HH-mm-ss")}-${
          enrollSuccessData.memberEnrollData.order_id
        }`,
        order_amount: price,
        customer_id: `${enrollSuccessData.memberEnrollData.order_id}`,
        customer_phone: data.userPhone,
      };
      createPaymentSession(params);
    }
  }, [
    enrollSuccessData,
    paymentSessionData,
    navigate,
    customDispatch,
    createPaymentSession,
    data,
  ]);

  const update = (e) => {
    if (e.target.name === "carNumber" && e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (data.selectYear == "1" && data.package == "standard")
      setPrice(initialPrice);
    if (data.selectYear == "2" && data.package == "standard")
      setPrice(initialPrice * data.selectYear);
    if (data.selectYear == "1" && data.package == "comprehensive")
      setPrice(initialPrice * 2 * data.selectYear);
    if (data.selectYear == "2" && data.package == "comprehensive")
      setPrice(initialPrice * 2 * data.selectYear);
  }, [data.package, data.selectYear]);

  return (
    <>
      <Row>
        <Col>
          <div className="auto-container">
            <div className="content-boxed">
              <div className="">
              <div className="row clearfix">

              <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-md-none" id="homeBannerSplide">
                <div className="inner-column">
                  <div className="image">
                    <Splide  options={ {
                          type         : 'loop',
                          gap          : '1rem',
                          autoplay     : true,
                          pauseOnHover : false,
                          resetProgress: false,
                          arrows      : false,
                          pagination  : false,
                        } }
                      hasTrack={ true }>
                      <SplideSlide>
                        <img src={banner1} alt="save upto 50% off with extended warranty plan"  />
                      </SplideSlide>
                      <SplideSlide>
                        <img src={banner2} alt="extended warranty" />
                      </SplideSlide>
                    </Splide>
                  </div>
                </div>
                </div>

                <div
                  id="landingForm"
                  className="content-column appointment-form banner-form col-lg-5 col-md-12 col-sm-12 text-left px-3 d-none d-md-block"
                >
                  <Form noValidate validated={validated} onSubmit={submitForm} className="px-3">
                    <div className="mb-3">
                      <h1 className="fs-2 text-capitalize d-inline">
                      Extend or purchase your <b><font color="#ed1c24">Car Warranty</font></b>
                      </h1>
                    </div>

                    <div className="row">
                      <div className="form-group mt-2 col-lg-6 col-md-6 col-sm-12 pe-0">
                        <input
                          type="text"
                          name="userName"
                          className="form-control border-0 phoneNo"
                          aria-describedby="inputGroupPrepend"
                          required
                          value={data.userName}
                          placeholder="Enter Name"
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your name
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.userName} name={"userName"} />
                      </div>
                      <div className="form-group mt-2 col-lg-6 col-md-6 col-sm-12 pe-0">
                        <input
                          type="text"
                          name="userEmail"
                          className="form-control border-0 phoneNo"
                          aria-describedby="inputGroupPrepend"
                          placeholder="Enter Email"
                          value={data.userEmail}
                          onChange={(e) => handleClick(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mt-2 col-lg-6 col-md-6 col-sm-12 ps-4">
                        <VehicleSelector navigate={true} />
                        <ErrorOutput
                          case={data.userVehicle}
                          name={"userVehicle"}
                        />
                      </div>

                      <div className="mt-2 form-group col-lg-6 col-md-6 col-sm-12 pe-0">
                        <input
                          type="text"
                          name="year"
                          className="form-control border-0 phoneNo"
                          aria-describedby="inputGroupPrepend"
                          required
                          placeholder="Enter Year"
                          value={data.year}
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter year of registration
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.year} name={"year"} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group mt-0 col-lg-6 col-md-6 col-sm-12 pe-0">
                        <input
                          type="text"
                          maxLength={10}
                          minLength={10}
                          name="userPhone"
                          value={data.userPhone}
                          aria-describedby="inputGroupPrepend"
                          className="form-control border-0 phoneNo"
                          placeholder="Enter Mobile"
                          required
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your phone number
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.userPhone} name={"userPhone"} />
                      </div>
                      <div className="mt-0 form-group col-lg-6 col-md-6 col-sm-12 pe-0">
                        <input
                          type="text"
                          name="carReg"
                          className="form-control border-0 phoneNo"
                          aria-describedby="inputGroupPrepend"
                          required
                          placeholder="Enter Car Registration"
                          value={data.carReg}
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter car registration
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.carReg} name={"carReg"} />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 px-3">
                        <Form.Label>Select Duration</Form.Label>
                        <div className="radio-box">
                          <input
                            type="radio"
                            name="selectYear"
                            value="1"
                            checked={data.selectYear === "1"}
                            onChange={update}
                            id="cod"
                          />
                          <Form.Label className="bg-transparent" for="cod">
                            {" "}
                            1 year
                          </Form.Label>
                        </div>
                        <br />
                        <div className="radio-box bg-transparent">
                          <input
                            type="radio"
                            name="selectYear"
                            value="2"
                            checked={data.selectYear === "2"}
                            onChange={update}
                            id="online"
                          />
                          <Form.Label className="bg-transparent" for="online">
                            {" "}
                            2 years
                          </Form.Label>
                        </div>
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 px-3">
                        <Form.Label>Select Package</Form.Label>
                        <div className="radio-box">
                          <input
                            type="radio"
                            name="package"
                            value="standard"
                            checked={data.package === "standard"}
                            onChange={update}
                            id="standard"
                          />
                          <Form.Label className="bg-transparent" for="standard">
                            {" "}
                            Standard
                          </Form.Label>
                        </div>
                        <br />
                        <div className="radio-box bg-transparent">
                          <input
                            type="radio"
                            name="package"
                            value="comprehensive"
                            checked={data.package === "comprehensive"}
                            onChange={update}
                            id="comprehensive"
                          />
                          <Form.Label
                            className="bg-transparent"
                            for="comprehensive"
                          >
                            {" "}
                            Comprehensive
                          </Form.Label>
                        </div>
                      </div>
                      <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                        <button
                          className="theme-btn btn-style-nine getQuote"
                          href=""
                          type="submit"
                        >
                          <span className="txt">Pay ₹{price}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>

                <div
                  id="landingForm"
                  className="content-column appointment-form banner-form col-lg-5 col-md-12 col-sm-12 text-left d-lg-none d-md-none mt-4"
                >
                  <Form noValidate validated={validated} onSubmit={submitForm} className="px-3">
                    <div className="mb-3">
                      <h1 className="fs-2 text-capitalize d-inline">
                      Extend or purchase your <b><font color="#ed1c24">Car Warranty</font></b>
                      </h1>
                    </div>

                    <div className="row">
                      <div className="form-group mt-2 col-lg-6 col-md-6 col-sm-12 px-0 border">
                        <input
                          type="text"
                          name="userName"
                          className="px-0 border-0"
                          aria-describedby="inputGroupPrepend"
                          required
                          value={data.userName}
                          placeholder="   Enter Name"
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your name
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.userName} name={"userName"} />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 border">
                        <input
                          type="text"
                          name="userEmail"
                          className="px-0 border-0"
                          aria-describedby="inputGroupPrepend"
                          placeholder="Enter Email"
                          value={data.userEmail}
                          onChange={(e) => handleClick(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-6 col-md-6 col-sm-12"
                        style={{ position: "relative", right: "8px" }}
                      >
                        <VehicleSelector navigate={true} />
                        <ErrorOutput
                          case={data.userVehicle}
                          name={"userVehicle"}
                        />
                      </div>

                      <div className=" form-group col-lg-6 col-md-6 col-sm-12 border">
                        <input
                          type="text"
                          name="year"
                          className="px-0 border-0"
                          aria-describedby="inputGroupPrepend"
                          required
                          placeholder="Enter Year"
                          value={data.year}
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter year of registration
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.year} name={"year"} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group mt-2 col-lg-6 col-md-6 col-sm-12 px-0 border">
                        <input
                          type="text"
                          name="userPhone"
                          value={data.userPhone}
                          aria-describedby="inputGroupPrepend"
                          className="px-0 border-0"
                          placeholder="   Enter Mobile"
                          required
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your phone number
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.userPhone} name={"userPhone"} />
                      </div>
                      <div className="mt-2 form-group col-lg-6 col-md-6 col-sm-12 border">
                        <input
                          type="text"
                          name="carReg"
                          className="custom-select-box border-0 px-0"
                          aria-describedby="inputGroupPrepend"
                          required
                          placeholder="Enter Car Registration"
                          value={data.carReg}
                          onChange={(e) => handleClick(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter car registration
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.carReg} name={"carReg"} />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>Select Duration</Form.Label>
                        <div className="radio-box">
                          <input
                            type="radio"
                            name="selectYear"
                            value="1"
                            checked={data.selectYear === "1"}
                            onChange={update}
                            id="cod"
                          />
                          <Form.Label className="bg-transparent" for="cod">
                            {" "}
                            1 year
                          </Form.Label>
                        </div>
                        <div className="radio-box bg-transparent">
                          <input
                            type="radio"
                            name="selectYear"
                            value="2"
                            checked={data.selectYear === "2"}
                            onChange={update}
                            id="online"
                          />
                          <Form.Label className="bg-transparent" for="online">
                            {" "}
                            2 years
                          </Form.Label>
                        </div>
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>Select Package</Form.Label>
                        <div className="radio-box">
                          <input
                            type="radio"
                            name="package"
                            value="standard"
                            checked={data.package === "standard"}
                            onChange={update}
                            id="standard"
                          />
                          <Form.Label className="bg-transparent" for="standard">
                            {" "}
                            Standard
                          </Form.Label>
                        </div>
                        
                        <div className="radio-box bg-transparent">
                          <input
                            type="radio"
                            name="package"
                            value="comprehensive"
                            checked={data.package === "comprehensive"}
                            onChange={update}
                            id="comprehensive"
                          />
                          <Form.Label
                            className="bg-transparent"
                            for="comprehensive"
                          >
                            {" "}
                            Comprehensive
                          </Form.Label>
                        </div>
                      </div>
                      <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                        <button
                          className="theme-btn btn-style-nine getQuote"
                          href=""
                        >
                          <span className="txt">Pay ₹{price}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
                <div
                  className="col-lg-7 mt-2 col-md-12 col-sm-12 d-none d-md-block"
                >
                  <Splide
                    options={{
                      type : 'loop',
                      gap          : '1rem',
                      autoplay     : true,
                      pauseOnHover : false,
                      resetProgress: false,
                      arrows      : false,
                      pagination  : false,
                    }}
                    hasTrack={true}
                  >
                    <SplideSlide>
                      <div
                      >
                        <img
                          src={banner1}
                          alt="save upto 50% off with extended warranty plan"
                        />
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                      >
                        <img
                          src={banner2}
                          alt="extended warranty"
                        />
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
            </div>
           </div> 
          </div> 
        </Col>
      </Row>
      <div id="custom-captcha-verifier"></div>
      <CustomModal
        show={show}
        modalSize={optVerified ? "lg" : "md"}
        content={<GetModalContent />}
        isCloseButton={true}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.paymentSessionData = state.default.paymentReducer;
  stateObj.enrollSuccessData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createMemberEnrollment: (params) =>
    OrderAction.createMemberEnrollment(params, dispatch),
  createPaymentSession: (params) =>
    PaymentAction.createPaymentSession(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
