import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { WhyUs } from "../../../constants/ImageConstants";

const WhyChooseUs = () => {
  return (
    <Row>
    <>
      
      <div className="auto-container">
        <div className="row clearfix">
          
          <div className="title-column col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column">
              
              <div className="sec-title">
                <div className="title">Look At Our Quality Features</div>
                <h2>Why Should <br/> You Choose Us?</h2>
              </div>
              <div className="text">
                <p>At VehicleCare, we prioritise our customers' satisfaction by providing affordable, premium-quality car care services. Our team of highly skilled engineers is dedicated to building long-lasting relationships with our customers by prioritising their security and convenience. We believe in delivering prompt and efficient services and value the trust of our customers above everything else.</p>
                
              </div>
            </div>
          </div>
          
          <div className="blocks-column col-lg-7 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="row clearfix">
                <img src={ WhyUs } alt="" />
              </div>
            </div>
          </div>
          
        </div>
      </div>

    </>
    </Row>
  );
};

export default WhyChooseUs;
