import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import {
  Faqbg,
  Pattern1,
  Pattern2,
  Appbg
} from "./../../../constants/ImageConstants";
import Testimonial from "./../../../components/common/Testimonial";

import VcClaimProcess from "./VcClaimProcess";
import InsuranceBannerSection from "./InsuranceBannerSection";
import OurApp from "../../../components/common/ourApp";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import TrustedCompany from "./trustedCompany";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { OrderAction } from "../../../store/action";

import InsuranceFaq from "./InsuranceFaq";
import Dcx from "./Dcx";
import HowVcHelp from "./HowVcHelp";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const Insurance = (props) => {
  const { cityData } = props;
  
  return (
    <>

      <Helmet>
        <title>Find The Best Car Accidental Insurance Claim Services</title>
        <meta
          name="description" content="Can You Search for Book cashless Car insurance Claim service with a hassle-free process and towing service from vehicleCare"
          data-react-helmet="true" />

        <meta property="og:title" content="Find The Best Car Accidental Insurance Claim Services"/>
        <meta property="og:description" content="Can You Search for Book cashless Car insurance Claim service with a hassle-free process and towing service from vehicleCare "/>
        <meta property="og:url" content={`https://vehiclecare.in/car-insurance-claim`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="VehicleCare"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
        <meta property="twitter:title" content="Find The Best Car Accidental Insurance Claim Services"/>
        <meta property="twitter:description" content="Can You Search for Book cashless Car insurance Claim service with a hassle-free process and towing service from vehicleCare "/>
        <meta property="twitter:url" content={`https://vehiclecare.in/car-insurance-claim`}/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:site" content="@vehiclecare1"/>
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
     
        <link rel="canonical" href={`https://vehiclecare.in/car-insurance-claim`} />

      </Helmet>

      <Header isStatic={true} />
      
      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="slide">
              <InsuranceBannerSection />
            </div>
          </section>

          {/* How VehicleCare can help */}
          <section className="about-section pb-5" style={{background: "rgb(251, 251, 251)"}}>
            <HowVcHelp />
          </section>

          {/* How to file for claim on VehicleCare */}
          <section className="about-section pt-5 pb-5">
            <VcClaimProcess />
          </section>

          {/* Trusted Company & Documents Required: */}
          <section className="about-section pt-0">
            <div
              className="pattern-layer-one"
              style={{ backgroundImage: `url(${Pattern1})` }}
            ></div>
            <div
              className="pattern-layer-two"
              style={{ backgroundImage: `url(${Pattern2})` }}
            ></div>
            
            <TrustedCompany />
          </section>

          {/*brand Section*/}
          {/* <section className="sponsors-section style-three">
              <div className="container-fluid text-center" id="splideCarousel">
                <div className="carousel-outer d-none d-md-block">
                  <Splide
                    options={ {
                      type   : 'loop',
                      drag   : 'free',
                      focus  : 'center',
                      interval: 2000,
                      speed: 200,
                      autoplay: "play",
                      pagination : false,
                      perPage: 6,
                      autoScroll: {
                        speed: 1,
                      },
                    } }
                  >
                  
                    <SplideSlide><img src={Insurance2} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance1} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance8} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance3} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance4} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance5} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance6} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance7} alt="" /></SplideSlide>
                  
                    
                  </Splide>
                </div>

                <div className="carousel-outer d-md-none">
                  <Splide
                    options={ {
                      type   : 'loop',
                      drag   : 'free',
                      focus  : 'center',
                      interval: 2000,
                      speed: 200,
                      autoplay: "play",
                      pagination : false,
                      perPage: 3,
                      autoScroll: {
                        speed: 1,
                      },
                    } }
                  >
                  
                    <SplideSlide><img src={Insurance2} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance1} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance8} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance3} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance4} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance5} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance6} alt="" /></SplideSlide>
                    <SplideSlide><img src={Insurance7} alt="" /></SplideSlide>
                  
                  </Splide>
                </div>
              </div>
          </section> */}
          {/* End brand Section */}

        
          <section className="about-section" style={{background: "rgb(251, 251, 251)"}}>
            <Dcx />
          </section>

          <div
            className="acordian-section pb-4"
            style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}
          >
            <InsuranceFaq />
          </div>

          {/* Testimonial Section  */}
          <div className="d-none d-md-block">
            <Testimonial city_name={cityData.selectedCity} />
          </div>

          {/* Our App */}
          <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
          </div>

        </Col>
      </Row>
      <Footer />
    </>
  );
};


const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) => OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Insurance);

