import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { OrderAction, PaymentAction } from "../../../store/action";
import { Row } from "react-bootstrap";
import ServicePaymentFail from "./servicePaymentFail";
import ServicePaymentSuccess from "./servicePaymentSuccess";

const PaymentSuccess = (props) => {
  const { getPaymentStatus, paymentData, updateServiceBooking, serviceBookingResponse } = props;
  const { statusResponse } = paymentData;
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get("myorder");
  const GetPaymentStatusView = () => {
    return statusResponse.data.status === "PAID" ? <ServicePaymentSuccess /> : <ServicePaymentFail />;
  };
  useEffect(() => {
    if (statusResponse) return;
    const params = {
      task: "getpaymentGatewayOrderStatus",
      order_id,
    };
    getPaymentStatus(params);
  }, [order_id, getPaymentStatus, statusResponse]);
  useEffect(() => {
    const leadId = order_id.split("-");
    if (serviceBookingResponse.serviceBookingResponse === null && statusResponse?.data.status === "PAID") {
      const updateParams = {
        task: "updateServiceBooking",
        user_id: statusResponse.data.customer.customer_id,
        id: leadId[leadId.length - 1],
        payment_mode: "online",
      };
      updateServiceBooking(updateParams);
    }
  }, [serviceBookingResponse, statusResponse, updateServiceBooking, order_id]);
  return statusResponse ? (
    <GetPaymentStatusView />
  ) : (
    <Row>
      <section className="error-section">
        <div className="auto-container">
          <div className="content">
            <h2>Checking Payment Status</h2>
            <div className="text">Thank you for your support</div>
          </div>
        </div>
      </section>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.paymentData = state.default.paymentReducer;
  stateObj.serviceBookingResponse = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  updateServiceBooking: (params) => OrderAction.updateServiceBooking(params, dispatch),
  getPaymentStatus: (params) => PaymentAction.getPaymentStatus(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
