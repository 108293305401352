import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "./index.scss";
import { CallBackImg } from "../../../constants/ImageConstants";

const CallBackPopup = (props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <Row>
      <Col className="auto-container">
        <Modal show={show} onHide={handleClose} centered size="lg">
          {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div className="row px-3">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="sec-title mb-3">
                  <h2 className="font-weight-bold">
                    <font color="#ed1c24">Get a Call Back </font>
                    {/* <font size="2"> Our respected representative will connect you asap </font> */}
                  </h2>
                  <p> Our respected representative will connect you asap</p>
                </div>

                <div className="styled-form login-form">
                  <div className="form-group">
                    <input type="text" className="form-control" name="name" placeholder="Name" required />
                  </div>

                  <div className="form-group">
                    <input type="text" className="form-control" maxLength={10} name="phone_no" placeholder="Mobile Number" aria-describedby="inputGroupPrepend" required />
                  </div>

                  <div className="form-group">
                    <input type="text" className="form-control" name="intrested_in" placeholder="Intrested In" required />
                  </div>
                </div>

                <button className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
                  <span className="txt">Submit</span>
                </button>
                
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 d-none d-md-block">
                <img src={ CallBackImg } alt="get a call back" />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Col>
    </Row>
  );
};
export default CallBackPopup;