/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { PointInspection, CheckMark } from "../../../constants/ImageConstants";

const PointInspection15 = () => {
  return (
    <Row>
      <div className="row clearfix pointInspection">
        <div className="content-column col-lg-12 col-md-12 col-sm-12">
          <div className="inner-column">
            <h2>+ 15-Point Inspection</h2>
            <div className="text">Car Wash Oil Change includes a complimentary 15 Point Inspection.</div>
            <div className="row clearfix">
              <div className="column col-lg-2 col-md-3 col-sm-12">
                <ul className="list-style-two">
                  <li>
                    <img src={CheckMark} className="checkMark" /> Change Oil
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Change Oil Filter
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Lubricate Chassis
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check Air Filter
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check Breathers
                  </li>
                </ul>
              </div>

              <div className="column col-lg-3 col-md-3 col-sm-12">
                <ul className="list-style-two">
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check PCV Valve
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check Wiper Blades
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check &amp; Fill Coolant
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check Inflation of Tires
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check &amp; Fill Battery
                  </li>
                </ul>
              </div>

              <div className="column col-lg-3 col-md-3 col-sm-12">
                <ul className="list-style-two">
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check Brake Fluid Level
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check &amp; Fill Steering Fluid
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check &amp; Fill Windshield Fluid
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check &amp; Fill Transmission Fluid
                  </li>
                  <li>
                    <img src={CheckMark} className="checkMark" /> Check &amp; Fill Differential Fluid
                  </li>
                </ul>
              </div>

              <div className="image-column col-lg-4 col-md-3 col-sm-12">
                <div className="inner-column">
                  <div className="image wow fadeInRight animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                    <img src={PointInspection} alt="" style={{ width: "100%" }} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default PointInspection15;
