import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaqListAction } from "../../../store/action";
import FaqList from "./FaqList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {Faqbg } from "../../../constants/ImageConstants";

import "./index.scss";
import { useEffect } from "react";

const FaqQuery = (props) => {
  const faqCityID = parseInt(props.city.faqCityId);
  const faqList = props.faqList.faqList.faq;
  useEffect(() => {
    if (!faqList?.length || (faqList && parseInt(faqList?.[0].city_id) !== parseInt(faqCityID))) {
      const params = { task: "getFaqList", city_id: faqCityID };
     props.getFaqList(params);
    }
  }, [faqCityID]);
  const [toggle, setToggle] = useState(0);
  let handleToggle = (id) => {
    if (toggle === id) {
      setToggle(null);
      return false;
    }
    setToggle(id);
  };
  return (
    <Row>
    <>
      <div className={`acordian-section  ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
        <Col  className={`container`}>
          <div className="row clearfix">
            <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Contact Support</div> */}
                  <h2>Frequently Asked Questions</h2>
                </div>

                <ul className="accordion-box">

                  <FaqList faqList={faqList} handleToggle={handleToggle} toggle={toggle} />

                  <div className="btns-box pull-right mt-3"><a className="theme-btn btn-style-nine" href="/faq"><span className="txt">Read More</span></a></div>

                </ul>
              </div>
            </div>

            <div className="title-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <div className="title">Contact Support</div>
                  <h2>Have Any Questions?</h2>
                </div>
                <div className="inquiry-box">
                  <div className="box-inner">
                    <div className="upper-box">
                      <div className="icon flaticon-telephone"></div>
                      <div className="inquiry-title">For Any Inquiries Call Now!</div>
                      <a href="tel:9911-020-209" className="phone">
                        9911-020-209
                      </a>
                    </div>
                    <div className="lower-box">
                      <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                        support@vehiclecare.in
                      </a>
                    </div>
                  </div>
                  <Link to={"/getintouch"} className="get-tech">
                    Get You Tech Now!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const stateobj = {};
  stateobj.faqList = state.default.faqListReducer;
  stateobj.city = state.default.cityListReducer;
  return stateobj;
};
const mapDispatchToProps = (dispatch) => ({
  getFaqList: (params) => FaqListAction.getFaqList(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(FaqQuery);
