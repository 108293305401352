import React from "react";
import "./index.scss";
// import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Tawkto = () => {
 // const tawkMessengerRef = useRef();
  // console.log('tawkMessengerRef',tawkMessengerRef);
//   const handleHide = () => {
//     tawkMessengerRef.current.hideWidget();
// };
  return (
    
    <>
     
      <TawkMessengerReact
    widgetId="default" propertyId="5a8beff3d7591465c707d370"  
    />

    </>
  );
};

export default Tawkto;

