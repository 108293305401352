const emailValidation = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
  return !(!email || regex.test(email) === false);
};
const checkValidMobile = (num) => {
  let expr = /^[6-9]{1}[0-9]{9}$/;
  if (num !== "" && expr.test(num)) return true;
  return false;
};

export { emailValidation, checkValidMobile };
