import React from "react";
import { Row, Col} from "react-bootstrap";
import "./index.scss";
import { BoostCustumer, ReduceCycleTime, ExperienceFull, LowerRepairCost} from "../../../../constants/ImageConstants";
import { Link } from "react-router-dom";

const OurServices = () => {
  return (
    <Row>
      <Col>

        <div className="auto-container">

          <div className="sec-title centered">
            <div className="title">Benefits of digital repair management</div>
            <h2>We provide expert Car service </h2>
          </div>

          <div className="row clearfix">
            
            <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <Link to="/makeclaim"><img src={BoostCustumer} alt="" /></Link>
                </div>
                <div className="lower-content">
                  <h5><Link to="/makeclaim"> Boost Custumer Satisfaction </Link></h5>
                  <div className="text">Customers are the most important part of your business. We try to offer good services to our customer so that they don’t get upset.</div>
                </div>
              </div>
            </div>

            <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <Link to="/makeclaim"><img src={ReduceCycleTime} alt="" /></Link>
                </div>
                <div className="lower-content">
                  <h5><Link to="/makeclaim"> Reduce Cycle Time</Link></h5>
                  <div className="text">Smooth workflows and minimize manual activities. At less time we deliver best service to you.</div>
                </div>
              </div>
            </div>

            <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <Link to="/makeclaim"><img src={LowerRepairCost} alt="" /></Link>
                </div>
                <div className="lower-content">
                  <h5><Link to="/makeclaim"> Lower Repair Cost </Link></h5>
                  <div className="text">We always try to provide low cost service and repair to our cutomers.</div>
                </div>
              </div>
            </div>

            <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <Link to="/makeclaim"><img src={ExperienceFull} alt="" /></Link>
                </div>
                <div className="lower-content">
                  <h5><Link to="/makeclaim"> Experience Full Control </Link></h5>
                  <div className="text">Our most popular service is our Virtual Receptionist. We know that sometimes it’s difficult to get.</div>
                </div>
              </div>
            </div>
            
          </div>

        </div>
      
      </Col>
    </Row>
  );
};

export default OurServices;
