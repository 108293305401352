import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { Helmet } from "react-helmet";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Car Service Simplified - VehicleCare</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />
      </Helmet>
    
      <Header isStatic={true} />

      <Row>
        {/* Error Section */}
        <section className="error-section pb-5">
          <div className="auto-container">
            <div className="content pt-5 pb-4">
              <h1>404</h1>
              <h2>Oops! That page can’t be found</h2>
              <div className="text">Sorry, but the page you are looking for does not existing</div>
              <a href="/" className="theme-btn btn-style-two">
                <span className="txt">Go to Home Page</span>
              </a>
            </div>
          </div>
        </section>

        {/* Sponsors section */}
      </Row>

      <Footer />
    </>
  );
};

export default NotFound;
