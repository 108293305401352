import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { BannerSolution } from "../../../../constants/ImageConstants";

const BannerSection = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="content-boxed">
            <div className="insurance_banner">
              <div className="row clearfix h-100">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 text-left my-auto">
                  <div className="row h-100">
                    <div className="col-4 my-auto">
                      <h2 className="hash">
                        <i className="fa fa-hashtag fa-5x" aria-hidden="true"></i>
                      </h2>
                    </div>
                    <div className="col-8">
                      <h1> Next Gen Repair Journey</h1>
                    </div>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                  <div className="inner-column">
                    <div className="image">
                      <img src={BannerSolution} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BannerSection;
