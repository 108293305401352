import React, { useEffect, useState } from "react";
import { Row, Form } from "react-bootstrap";
import "./index.scss";
import { AccountDeletionBanner } from "./../../../constants/ImageConstants";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { SERVICE_QUOTE, SHOW_HIDE_LOADER, Sign_Out } from "../../../store/types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { OrderAction, UserAction } from "../../../store/action";
import { getNavigateUrl } from "../../../utils/customHelper";

const AccountDeletion = (props) => {
  const { customDispatch, userData, userDelete } = props;
  const { is_user_logged_in, user_profile } = userData;
  const { phone } = user_profile;
  const [data, setData] = useState({
    userPhone: "",
  });
  console.log("userData", userData);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "userPhone") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span></span>;
    }

    return <></>;
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!is_user_logged_in) {
      alert("You are not logged in. Login first to delete account.");
      document.getElementsByClassName("userprofile_icon")[0].click();
    }
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (phone !== data.userPhone) {
        alert("Check your mobile number again.");
        return false;
      }
      const params = {
        task: "userDeleteRequest",
      };
      userDelete(params);
      navigate(getNavigateUrl("/"));
    }
    setValidated(true);
  };

  return (
    <>
      <Helmet>
        <title>User Account Deletion</title>
        <meta name="description" content="Contact us to get more details about our products and offerings." />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <section className="banner-section-three">
          <div className="main-slider-carousel">
            <div className={`slide ${!isMobile ? "mt-0 pb-5" : "pt-4"}`}>
              <div className="auto-container">
                <div className="content-boxed">
                  <div className="row clearfix h-100">
                    <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-md-none">
                      <div className="inner-column">
                        <div className="image">
                          <img src={AccountDeletionBanner} className="img-responsive" alt="Car" />
                        </div>
                      </div>
                    </div>

                    <div id="landingForm" className="content-column appointment-form banner-form col-lg-5 col-md-12 col-sm-12 text-left px-4 my-auto">
                      <Form noValidate validated={validated} onSubmit={submitForm}>
                        <h1 className="fs-2 px-4 text-capitalize d-none d-md-block mt-0 mb-4">Request to Delete Account</h1>
                        <h1 className="fs-2 text-capitalize d-md-none mb-3 px-0">Request to Delete Account</h1>

                        <div className="d-none d-md-block px-4">
                          <div className="row">
                            <div className={`form-group mt-3 px-2 col-sm-12 col-lg-10 col-md-10`}>
                              <input
                                type="text"
                                name="userPhone"
                                maxLength={10}
                                pattern="[6-9]{1}[0-9]{9}"
                                value={data.userPhone}
                                aria-describedby="inputGroupPrepend"
                                className="form-control border-0 phoneNo"
                                placeholder="Confirm Your Mobile Number"
                                onChange={(e) => handleClick(e)}
                                required
                              />
                              <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                              <ErrorOutput case={data.userPhone} name={"userPhone"} />
                            </div>

                            <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                              <button className="theme-btn btn-style-nine getQuote" type="submit" name="submit-form">
                                <span className="txt">Delete Account</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-md-none">
                          <div className="row clearfix px-2">
                            <div className={`form-group col-lg-12 col-md-12 col-sm-12 border col-lg-12 col-md-12`}>
                              <input
                                type="text"
                                name="userPhone"
                                maxLength={10}
                                pattern="[6-9]{1}[0-9]{9}"
                                value={data.userPhone}
                                aria-describedby="inputGroupPrepend"
                                className="custom-select-box border-0 px-0"
                                placeholder="Confirm Your Mobile Number"
                                onChange={(e) => handleClick(e)}
                                required
                              />
                              <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                              <ErrorOutput case={data.userPhone} name={"userPhone"} />
                            </div>

                            <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                              <button className="theme-btn btn-style-nine getQuote" type="submit" name="submit-form">
                                <span className="txt">Delete Account</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                      {/* <p className="text text-success">
                        Your request for account deletion has been Submitted.
                        <br /> We will update you in next 7 days.
                      </p> */}
                    </div>

                    <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                      <div className="inner-column">
                        <div className="image">
                          <img src={AccountDeletionBanner} className="img-responsive" alt="Car" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-section pt-0">
          <div className="pattern-layer-one"></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix">
                <div className="content-column col-lg-12 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="detail px-2">
                      <div className="d-flex justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-2">
                            <h4 className="text-left text-dark">
                              <b>User Account Deletion :</b>
                            </h4>
                          </div>

                          <p className="mb-3">
                            At VehicleCare, we believe in empowering our users with control over their data. If you wish to remove your account from our platform, we respect your decision and have streamlined the process to ensure the complete deletion of your information.

                          </p>

                          <div className="sec-title mb-2">
                            <h4 className="text-left text-dark">
                              <b>Our Commitment to Your Privacy</b>
                            </h4>
                          </div>

                          <p className="mb-1">
                            We want to assure you that your privacy matters to us. VehicleCare does not engage in selling user data to third parties. Moreover, when you opt to delete your account, we ensure the permanent eradication of all your personal information and associated data from our systems.

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Row>

      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.userData = state.default.userReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  userDelete: (params) => UserAction.userDelete(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountDeletion);
