import React from "react";
import { Row, Col} from "react-bootstrap";
import "./index.scss";
import { Link } from "react-router-dom";

const ServiceDetailTab = () => {
  return (
    <Row>
      <Col>

				
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
              <div className="service-detail">
                <div className="inner-box">
                  <h2>EXPERT TIRE REPAIR</h2>
                  <h1 className="title">Firestone Has Been The Nation’s Leading Tire Provider For More Then a Century.</h1>
                  <div className="image">
                    <img src="images/resource/service-22.jpg" alt="" />
                  </div>
                  <h3>For Tire Repair That Last Firestone’e on a Roll</h3>
                  <p>It's a tough road out there, and your tires bear the brunt of potholes, broken glass, nails, screws and anything else that can puncture a tire. Firestone Complete Auto Care's flat tire repair services are the best in the business. We've been repairing tires since 1900, and we take pride in repairing America's tires. When tire damage strikes, come to Firestone Complete Auto Care – the tire repair shop you can trust.</p>
                  <h4>The Firestone Triple Promise</h4>
                  <p>Firestone Complete Auto Care prides itself on promise that your vehicle will be fixed right – the first time.</p>
                  <h4>Got Questions About Flat Tire Repair? We have Answers</h4>
                  <p>When you have a question about car maintenance or repair (including tire repair), Completely Firestone has the answers. We're your go-to resource to keep your car running newer, stronger and longer.</p>
                  <div className="bold-title">Flat Tire Repeir, Tire Patches and More.</div>
                  <p>If you suspect something is wrong with your vehicle tires, don't hesitate to come into a Firestone Complete Auto Care. If your Tire Pressure Monitoring System (TPMS) light is on, or you've hit something on the road, your tire is looking flat, or maybe something just doesn't feel quite right, bring it in and one of expert, ASE-certified technicians will take a look and tell you if you need any tire repair services. If you keep riding on a damaged tire, it can lead to additional (and more costly) rim damage and repairs, so it's best to have your tires examined at the first sign of trouble. </p>
                  <p>The solution might be as simple as a patch and tire plug if the tire damage is addressed early on. In other simple cases, a leaking tire valve may need to be replaced with a new one, or the TPMS sensor needs to be reset. Come on in to Firestone Complete Auto Care, we will take care of you and your vehicle tire repairs.</p>
                  <div className="appointment-box">
                    <div className="appointment-text">*All services and prices vary by location and vehicle condition. Quote provided prior to services. Call for more details or to schedule an appointment.</div>
                    <Link to="/getintouch" className="theme-btn btn-style-two"><span className="txt">Schedule Appointment</span></Link>
                  </div>
                </div>
              </div>
            </div>

      </Col>
    </Row>
  );
};

export default ServiceDetailTab;
