import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Fleet } from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";

const SegmentsBannerSection = (props) => {
  const { source } = props;
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="content-boxed">
            <div className="fleet_banner">
              <div className="row clearfix">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 text-left">
                  <h1>Vehiclecare For Fleet</h1>
                  {/* <h1 className="title">Vehiclecare For Fleet</div> */}
                  <div className="text">
                    We offer a range of services to help manage your fleet. Our qualified mechanics provide regular maintenance check-ups and emergency repairs. Cost-effective solutions help you save
                    money on repairs and maintenance. Convenient online booking makes scheduling service appointments easy.
                  </div>
                  <div className="text">
                    Our services are customized to meet the unique needs of your fleet. We use the latest tools and equipment and genuine spare parts with an assured warranty.
                  </div>
                  <div className="btns-box">
                    <Link to={`/getintouch?source=${source}`} className="theme-btn btn-style-nine px-4 me-3">
                      <span className="txt">Get in touch</span>
                    </Link>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="image">
                      <img src="images/insurance/banner.png" alt="" />
                      <img src={Fleet} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SegmentsBannerSection;
