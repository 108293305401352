import React from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";

import ListGroup from 'react-bootstrap/ListGroup';

const CarAcRepairPriceList = (props) => {

  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
  };
  
  return (
    <>
      <Row>
      
        {/* price list */}
        
        <div className="auto-container">
          <Row className="justify-content-center">
            <Col className="col-11 mb-md-4 mb-sm-0">
              <div className="sec-title centered">
                <h2><font color="#ed1c24">Car AC Service</font> Price List in India {getYear()} </h2>
              </div>
              <div className="row">
                <Col>
                  <ListGroup >
                    
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start bg-light"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Car Service List</div>
                      </div>
                      <span>
                        Price Starts From (₹)
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">1. Regular Car AC service</div>
                      </div>
                      <span>
                        Rs. 1999
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">2. Ac Filter</div>
                      </div>
                      <span>
                        Rs. 399
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">3. Blower Motor Cleaning</div>
                      </div>
                      <span>
                        Rs. 299
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">4. Car Ac Inspection</div>
                      </div>
                      <span>
                        Rs. 499
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">5. Car Ac Gas Top Up</div>
                      </div>
                      <span>
                        Rs. 799
                      </span>
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                
              </div>
            </Col>
          </Row>
        </div>
        
      </Row>

    </>
  );
};


export default CarAcRepairPriceList;
