import { preLoginApiRequest } from "../../server";
import { HELP_FORM_LIST } from "../types";

// eslint-disable-next-line import/prefer-default-export
const getHelpForm = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({type: HELP_FORM_LIST, payload: responseData});
  });
};
export { getHelpForm };
