import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { Link } from "react-router-dom";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 100) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

const BenifitDigitalRepairManagement = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="sec-title centered">
            {/* <h2 className="title">Solutions & Services</div> */}
            <h2 className="text-capitalize">
              Benefit from digital repair <br /> management{" "}
            </h2>
          </div>

          <div className="d-flex justify-content-center mb-5 col">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row clearfix">
                <div className="feature-block-two col-lg-6 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="content">
                      <div className="icon-box">
                        <span className="number">01</span>
                        <div className="icon flaticon-maintenance"></div>
                      </div>
                      <h5>
                        <Link to={"/getintouch"}>Enhance driver satisfaction</Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          Our digital repair management solution offers a hassle-free repair journey that is customized to meet the demands of today's digitally savvy customers. By providing an
                          intuitive and user-friendly web app, drivers can quickly report damages, schedule appointments that work with their busy schedules, and provide feedback after repairs are
                          completed. This results in increased driver satisfaction, which can help improve customer loyalty and retention.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="feature-block-two col-lg-6 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="content">
                      <div className="icon-box">
                        <span className="number">02</span>
                        <div className="icon flaticon-speed"></div>
                      </div>
                      <h5>
                        <Link to={"/getintouch"}>Reduce cycle times</Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          Our digital repair management system streamlines workflows and minimizes manual activities, reducing the time it takes to complete repairs. With our advanced platform, we can
                          match each damage with the right repair shop, ensuring quick and efficient repairs. This not only benefits the customer, who gets their vehicle back sooner, but also helps
                          fleet managers keep their vehicles on the road, reducing downtime and increasing productivity.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="feature-block-two col-lg-6 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="content">
                      <div className="icon-box">
                        <span className="number">03</span>
                        <div className="icon flaticon-car-insurance"></div>
                      </div>
                      <h5>
                        <Link to={"/getintouch"}>Lower repair costs</Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          By optimizing repair allocation in a truly independent and dynamic network, we can offer competitive repair costs without compromising quality. Our digital marketplace
                          connects partner-specific requirements with repairers' expertise, ensuring that every repair job is matched with the right repair shop at the right price. This saves our
                          customers money and helps them stay within their repair budgets.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="feature-block-two col-lg-6 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="content">
                      <div className="icon-box">
                        <span className="number">04</span>
                        <div className="icon flaticon-speed-1"></div>
                      </div>
                      <h5>
                        <Link to={"/getintouch"}>Experience full control</Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          Our digital repair management solution gives our customers full control over the repair process. Through our cohesive dashboard, fleet managers can track every repair job
                          from start to finish, gaining unique real-time data insights at every step of the way. This allows them to make informed decisions and take proactive steps to improve their
                          fleet's overall performance.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BenifitDigitalRepairManagement;
