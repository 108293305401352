import React, { useEffect, useState } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { ServicePackagesListAction } from "../../../../store/action";
import { CATEOGARY_ID } from "../../../../store/types";
import { useSearchParams } from "react-router-dom";

const ServiceCateogaries = (props) => {
  const [searchParams] = useSearchParams();
  const [updatedCat, setUpdatedCat] = useState(false);
  const serviceName = searchParams.get("service");
  const serviceCateogaries = props.serviceList;
  const cgID = props.cateogary;
  const loadTab = (data) => {
    props.updateService({ type: CATEOGARY_ID, payload: data.replace(/ /g, "-").toLowerCase() });
  };
  useEffect(() => {
    if (!updatedCat && serviceName && serviceName !== cgID) {
      props.updateService({ type: CATEOGARY_ID, payload: serviceName.replace(/ /g, "-").toLowerCase() });
      setUpdatedCat(true);
    }
  }, [serviceName, props, updatedCat, setUpdatedCat, cgID]);
  return (
    <div className="column col-lg-12 col-md-12 col-sm-12 prod-tabs my-4">
      <div className="nav-items nav-pills tab-btns" role="tablist" aria-multiselectable="true">
        <div className="form-group col-lg-3 col-md-6 col-sm-12">
          <select name="workHours" onChange={(event) => loadTab(event.target.value)} className="form-control" required="">
            {serviceCateogaries?.map((serviceCateogary) => {
              const activeClass = serviceCateogary.name.toLowerCase() === cgID.replace(/-/g, " ").toLowerCase() ? true : false;
              return (
                <option key={serviceCateogary.id} selected={activeClass} value={serviceCateogary.name}>
                  {serviceCateogary.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state.default.serviceListReducer;
};
const mapDispatchToProps = (dispatch) => ({
  getServiceData: (params) => ServicePackagesListAction.getServiceData(params, dispatch),
  updateService: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceCateogaries);
