import React from "react";
import { NoConnection, AjaxLoader } from "../../constants/ImageConstants";
import { Modal } from 'react-bootstrap';
import IconImage from '../common/IconImage/IconImage';

class InternetConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowICPopupA: false,      
        }
    }
  
    componentDidMount() {
      const imageList = [NoConnection, AjaxLoader];
      imageList.forEach((image) => {
        // eslint-disable-next-line no-undef
        new Image().src = image;
      });
      this.checkConnection();
    }
  
    handleInternetChange = (event) => {
      event.preventDefault();
      const { navigator } = window;
      const { onLine } = navigator;
      this.setState({
        isShowICPopupA: !onLine,
      });
    }
  
  
    /**
     * This function checks the Internet connection of the application
     *
     * @memberof InternetConnection
     */
    checkConnection = () => {
      const { addEventListener } = window;
      addEventListener('online', event => this.handleInternetChange(event));
      addEventListener('offline', event => this.handleInternetChange(event));
    }
  
    render() {
      const { isShowICPopupA } = this.state;
      return (
        <Modal
          show={isShowICPopupA}
          onHide={() => {}}
          dialogClassName="noInternetConnection"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <IconImage
              image={NoConnection}
              className="noConnectionImg"
              borderRadius="0"
              width="65px"
              height="auto"
            />
            <br />
            <b>No Internet Connection!</b>
            <IconImage
              image={AjaxLoader}
              borderRadius="0"
              width="128px"
              height="15px"
            />
            <br />
            <small>Reconnecting...</small>
          </Modal.Body>
        </Modal>
      );
    }
  }
  export default InternetConnection;