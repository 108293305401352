import { blogRequest } from "../../server";
import { BLOG_LIST } from "../types";

// eslint-disable-next-line import/prefer-default-export
const getBlogList = (dispatch) => {
  blogRequest((responseData) => {
    dispatch({type: BLOG_LIST, payload: responseData});
  });
};
export { getBlogList };
