import React , { useState } from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 ,Faqbg} from "./../../../constants/ImageConstants";

import OurApp from "./../../../components/common/ourApp";

import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import Testimonial from "../../../components/common/Testimonial";
import BlogMultiImageCarousel from "./../../../components/common/blogMultiImageCarousel";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import CarMechanicPriceList from "../CarMechanicNearMe/CarMechanicPriceList";

const CarRepairNearMe = (props) => {
  const selectedCityName = props.cityListReducer.selectedCity;
  const faqList =[
    {
        id:1,
        title:"What all do you check during inspection of our car?",
        summary:"We first start with a document check and test drive and then use the latest tools to assess the exterior, engine, tire, transmission, electrical, and interior. We also inspect the suspension, steering mechanism, brakes, and other accessories of your car during the test drive."   
    },
    {
        id:2,
        title:"Will you charge for our car inspection?",
        summary:"Yes, the charges for our car inspection is Rs 399." 
       
    },
    {
        id:3,
        title:"Do VehicleCare provide home inspection for car?",
        summary:"We provide Home inspection for you your car, you just have to call or WhtsApp us and our mechanic will reach you within 15 minutes." 
       
    },
    {
        id:4,
        title:"How long will it take to repair my car?",
        summary:"There is time when you could not see your car for 5-6 hours. But in some cases the repair could take anywhere from 5-6 hours to a week or more." 
       
    },
    {
      id:5,
      title:"Can i book my appointment thorugh mobile APP ?",
      summary:"Yes you can book your appointment through Mobile App. You can also book via email." 
     
  },
  {
    id:6,
    title:"How many mechanic are there in vehiclecare ?",
    summary:"There are plenty of Mechanics in VehicleCare and our expert mechanics have more than ten years of experience." 
   
},
{
  id:7,
  title:"What all kind of services do VehicleCare provide?",
  summary:"There's a wide range of car services which are available.We offer Car service, Wheel Care, Car AC Repair, Custom Repair and much more." 
 
},
{
  id:7,
  title:"what sort of spare parts and engine oils are used in the servicing?",
  summary:"We use only genuine spares sourced from OEM/OES across all our workshops. Further, all products used in car services are authentic and top-rated." 
 
}
];

const [toggle, setToggle] = useState(0);
let handleToggle = (id) => {
  if (toggle === id) {
    setToggle(null);
    return false;
  }
  setToggle(id);
};
const active = { backgroundColor: "black", color: "white" };
  const inactive = {};

  return (
    <>
      <Helmet>
        <title>Car Repair Near Me VehicleCare Provides Affordable Price</title>
        <meta data-react-helmet="true" name="description" content="Your search for a car repair near me ends here. With VehicleCare's network of expert mechanics, finding an nearby car repair is an easy search away " />
      
        <meta property="og:title" content="Car Repair Near Me VehicleCare Provides Affordable Price"/>
        <meta property="og:description" content="Your search for a car repair near me ends here. With VehicleCare's network of expert mechanics, finding an nearby car repair is an easy search away "/>
        <meta property="og:url" content={`https://vehiclecare.in/car-mechanic-near-me`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="VehicleCare"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
        <meta property="twitter:title" content="Car Repair Near Me VehicleCare Provides Affordable Price"/>
        <meta property="twitter:description" content="Your search for a car repair near me ends here. With VehicleCare's network of expert mechanics, finding an nearby car repair is an easy search away "/>
        <meta property="twitter:url" content={`https://vehiclecare.in/car-mechanic-near-me`}/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:site" content="@vehiclecare1"/>
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                  car wash, 
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="services-section-four pt-0">
          <ServiceSteps />
        </div>

        {/* price list */}
        <div className="blog-section pt-0">
          <CarMechanicPriceList />
        </div>

        <div className="price-section " style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        <div className="services-section-four">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Why Choose VehicleCare </h2>
            </div>
          </div>
          <WhyChooseVc />
        </div>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix d-flex justify-content-center px-2">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">
                    <div className="sec-title mb-4">
                      <h2 className="text-center">Looking For Nearby <font color="#ed1c24">Car Repair Services?</font></h2>
                      <div className="text text-left mb-0">
                      Welcome to VehicleCare, your go-to online platform for hassle-free and reliable multi-brand car repair and services.Tired of searching endlessly for "car repair shops near me" or "auto shop near me"? Look no further. With VehicleCare, professional car maintenance is just a click away, right at your doorstep at the most reasonable price. </div>

                      <div className="text text-left mt-2 mb-0">No matter if you have a SUV or a Sedan or anything else, we cater to your need for “best car repair services near me”. Dont just search for an “car repair shop near me”, but book a car repair service with VehicleCare and handover your car with us and sit back and relax.</div>
                      
                    </div>

                    <div className="detail">
                      <div className="justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          
                          <div className="sec-title mb-4 mt-4">
                            <h2 className="text-left fs-4 text-capitalize">Why opt for VehicleCare for your Car Repair Services?</h2>
                          </div>
                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                1. Trustworthy and reliable:
                              </font>
                            </b>
                            <span>
                              {" "}
                               VehicleCare is your most trusted and reliable 
                               <a href="https://vehiclecare.in/car-service-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24"> car servicing </font></a>
                                centre because we understand the worth of your car and that's why we employ the best certified technicians with multi-brand expertise to handle your car. Our skilled professionals guarantee precision and reliability in every repair. 
                            </span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                2. Skilled technicians with advanced tools for repair and diagnosis:
                              </font>
                            </b>
                            <span> At every VehicleCare workshop, we only have technicians who meet the industry standards and they conduct the diagnosis with advacnced equipment in order to conduct the vehicle examination accurately before every service. The integration of state-of-the-art tools enhances our diagnostic capabilities, enabling us to provide comprehensive and reliable insights into your vehicle's health.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                3. We deliver what we promise and on-time:
                              </font>
                            </b>
                            <span>At VehicleCare, we believe in giving our customers exactly what we promised. Whether its routine car repair, car wash, wheel alignment, car break related issue, car AC repair or any car related services, we being a multi-brand car repair and service provider, always prioritize the satisfaction of our customer and deliver what we promise. We value your time and that's why we make sure to deliver you your car on time.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                4. Use of Genuine parts:
                              </font>
                            </b>
                            <span> VehicleCare upholds the integrity and performance of your vehicle by exclusively using genuine parts for all car repair and services. We understand the critical role quality components play in ensuring longevity and optimal functionality. By utilizing authentic spare parts, we guarantee that your car receives the exact components it was designed for, preserving its performance on the road.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                5. 24x7 Customer Support:
                              </font>
                            </b>
                            <span> VehicleCare takes pride in offering 24x7 customer support to its customers, ensuring assistance is just a call or message away anytime.Our dedicated support team is committed to addressing your queries and offering guidance around the clock.<br/> Whether you have questions about services, need help with booking, or require assistance with an ongoing repair, our 24x7 customer support is there to provide prompt and reliable assistance. With VehicleCare customer support, experience peace of mind always.</span>
                          </p>

                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-4 mt-4">
                            <h2 className="text-left fs-4 text-capitalize">Booking a car repair service with VehicleCare is simple and easy. Just follow these 5 simple steps.</h2>
                          </div>

                          <p><font color="#ed1c24" size="4"><b>1.</b> </font> Visit the website 
                          <b><a href="vehiclecare.in" target="_blank"><font color="#ed1c24" size="3"> www.vehiclecare.in </font> </a>
                          </b>
                          
                          or download VehicleCare app from playstore or app store.</p> 

                          <p><font color="#ed1c24" size="4"><b>2.</b> </font> Fill in the basic details like make and model of your car and the kind of service you want.</p>

                          <p><font color="#ed1c24" size="4"><b>3.</b> </font> Request the time and place for the pick and drop service.</p>

                          <p><font color="#ed1c24" size="4"><b>4.</b> </font> VehicleCare expert technicians will pick up the car from your location at the scheduled time and location. </p>

                          <p><font color="#ed1c24" size="4"><b>5.</b> </font> Sit back and relax and let us do the rest for you.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section  */}
        <div className={`acordian-section  ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
          <Col  className={`auto-container`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    {/* <div className="title">Contact Support</div> */}
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <ul className="accordion-box">
                    {faqList?.map((value, index) => {
                      return (
                        <div className="accordion block mb-1" id="acc_faq" key={index}>
                          <div
                            className="acc-btn"
                            onClick={() => handleToggle(index)}
                            style={index === toggle ? active : inactive}
                          >
                            <h2>{value.title}{" "}</h2>
                            <div className="icon-outer">
                              <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
                            </div>{" "}
                          </div>
                          {index === toggle ? (
                            <div className="content px-2 py-0">
                              <div className="accordian-text"> {value.summary} </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="title-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Contact Support</div>
                    <h2>Have Any Questions?</h2>
                  </div>
                  <div className="inquiry-box">
                    <div className="box-inner">
                      <div className="upper-box">
                        <div className="icon flaticon-telephone"></div>
                        <div className="inquiry-title">For Any Inquiries Call Now!</div>
                        <a href="tel:9911-020-209" className="phone">
                          9911-020-209
                        </a>
                      </div>
                      <div className="lower-box">
                        <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                    <Link to={"/getintouch"} className="get-tech">
                      Get You Tech Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* FAQ Section  */}


        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
        <Testimonial city_name="Gurgaon" />
        </div>

        {/* Blog Section */}
        <div className="blog-section pb-0">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>



      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(CarRepairNearMe);
