import { FILE_URL_ARRAY, CLEAR_FILE_URL_ARRAY, IMAGE_URL_ARRAY } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    file_url_array: [],
    image_url_array: [],
  },
  action = {}
) => {
  switch (action.type) {
    case FILE_URL_ARRAY:
      return { ...state, file_url_array: action.payload };
    case IMAGE_URL_ARRAY:
      return { ...state, image_url_array: action.payload };
    case CLEAR_FILE_URL_ARRAY:
      return { ...state, file_url_array: [], image_url_array: [] };
    default:
      return state;
  }
};
