import React from "react";
import { Col, Row } from "react-bootstrap";
import './index.scss';
import {
	Solution1,
	Solution2
  } from "../../../../constants/ImageConstants";

const CustomisablePlatform = () => {
    return (
        <Row>
            <Col>
			<div className="auto-container CustomisablePlatform">
				<div className="upper-section">
					<div className="sec-title centered">
						{/* <h1 className="title">Look At Our Quality Features</div> */}
						<h2>Customisable platform to fit your <br/> repair management needs</h2>
						{/* <div className="text">Offer an effortless repair journey, streamline workflows, and benefit from intelligent repair allocation</div> */}
					</div>
				
					<div className="row clearfix mb-4">

						<div className="image-column col-lg-4 col-md-12 col-sm-12">
							<div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
								<div className="image">
									<img src={Solution1} alt="" className="w-100" />
								</div>  
							</div>
						</div>
						
						<div className="content-column col-lg-8 col-md-12 col-sm-12 my-auto border">
							<div className="inner-column px-2">
								
								<div className="sec-title">
									<h2><font color="#ed1c24" >Experience an end-to-end digital repair journey</font></h2>
									{/* <h6 className="mb-3 mt-2 text-dark"><b> Offer a frictionless repair experience </b></h6> */}
								</div>

								<div className="text"><b>Vehiclecare</b> welcomes you to our websites for car repairs in India. The vehicleCare nexus develop on innovation and try to bring quality, reliability, and transparency.welcomes you to our websites for car repairs in India. The vehicleCare nexus develop on innovation and try to bring quality, reliability, and transparency. Our business reach across India with workshops, Manufacturers, Suppliers, Corporates, Insurers, Consumers in PAN India. We are a top provider of car repair services in India and maintain our status as the top option for all your auto repair and servicing requirements. We offer a wide range of customized hydraulic integrated manifolds, hydraulic cartridge valves, and valves for hydraulic braking systems. </div>

							</div>
						</div>
						
					</div>

					{/* second */}
					<div className="row clearfix mb-4">
						
						<div className="image-column col-lg-4 col-md-12 col-sm-12">
							<div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
								<div className="image">
									<img src={Solution2} alt="" className="w-100" />
								</div>  
							</div>
						</div>
						<div className="content-column col-lg-8 col-md-12 col-sm-12 my-auto border">
							<div className="inner-column px-2">
								
								<div className="sec-title">
									<h2><font color="#ed1c24" >Digital driver journey</font></h2>
									{/* <h6 className="mb-3 mt-2 text-dark"><b> Offer a frictionless repair experience </b></h6> */}
								</div>
								
								<div className="text"><b>Vehiclecare</b> welcomes you to our websites for car repairs in India. The vehicleCare nexus develop on innovation and try to bring quality, reliability, and transparency. welcomes you to our websites for car repairs in India. The vehicleCare nexus develop on innovation and try to bring quality, reliability, and transparency.Our business reach across India with workshops, Manufacturers, Suppliers, Corporates, Insurers, Consumers in PAN India. We are a top provider of car repair services in India and maintain our status as the top option for all your auto repair and servicing requirements. We offer a wide range of customized hydraulic integrated manifolds, hydraulic cartridge valves, and valves for hydraulic braking systems. </div>

							</div>
						</div>
						
					</div>

					{/* third */}
					{/* <div className="row clearfix mb-4">
						<div className="image-column col-lg-4 col-md-12 col-sm-12">
							<div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
								<div className="image">
									<img src={Solution3} alt="" className="w-100" />
								</div>  
							</div>
						</div>

						<div className="content-column col-lg-8 col-md-12 col-sm-12 my-auto border">
							<div className="inner-column px-2">
								
								<div className="sec-title">
									<h2><font color="#ed1c24" >Digital driver journey</font></h2>
									
								</div>
								
								<div className="text"><b>Vehiclecare</b> welcomes you to our websites for car repairs in India. The vehicleCare nexus develop on innovation and try to bring quality, reliability, and transparency.welcomes you to our websites for car repairs in India. The vehicleCare nexus develop on innovation and try to bring quality, reliability, and transparency. Our business reach across India with workshops, Manufacturers, Suppliers, Corporates, Insurers, Consumers in PAN India. We are a top provider of car repair services in India and maintain our status as the top option for all your auto repair and servicing requirements. We offer a wide range of customized hydraulic integrated manifolds, hydraulic cartridge valves, and valves for hydraulic braking systems. </div>

							</div>
						</div>
					</div> */}
				</div>
			</div>

			
            </Col>
        </Row>
  );
};





export default CustomisablePlatform;
