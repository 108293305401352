import { BRAND_LIST, MODEL_LIST, SET_BRAND, SET_MODEL, SET_YEAR, SET_VARIANT, REMOVE_VEHICLE } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    brandList: [],
    modelList: [],
    brand: null,
    model: null,
    variant: null,
    year: null,
  },
  action = {}
) => {
  let vehicleDetails = window.sessionStorage.getItem("vehicleDetails");
  if (state.variant === null && vehicleDetails) {
    vehicleDetails = JSON.parse(vehicleDetails);
    state.brand = vehicleDetails.brand;
    state.model = vehicleDetails.model;
    state.variant = vehicleDetails.variant;
  }
  switch (action.type) {
    case BRAND_LIST:
      return { ...state, brandList: action.payload, modelList: [] };
    case MODEL_LIST:
      return { ...state, modelList: action.payload };
    case SET_BRAND:
      return { ...state, brand: action.payload };
    case SET_MODEL:
      return { ...state, model: action.payload };
    case SET_YEAR:
      return { ...state, year: action.payload };
    case SET_VARIANT:
      return { ...state, variant: action.payload };
    case REMOVE_VEHICLE:
      window.sessionStorage.removeItem("vehicleDetails");
      return { ...state, brand: null, model: null, variant: null, modelList: [] };
    default:
      return state;
  }
};
