import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { Failed } from "./../../../constants/ImageConstants";
import { Helmet } from "react-helmet";

const ServicePaymentFail = () => {
  return (
    <>
      <Helmet>
        <title>Car Service Simplified - VehicleCare</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />
      </Helmet>

      <Row>
        {/* Error Section */}
        <section className="error-section h-100">
          <div className="auto-container">
            <div className="content my-auto">
              <img src={Failed} className="img-fluid mb-4 w-25" alt="Payment Failed" />
              <h2>Payment Failed</h2>
              <div className="text">Unfortunately payment is failed.</div>
              <a href="/" className="theme-btn btn-style-two">
                <span className="txt">Back</span>
              </a>
            </div>
          </div>
        </section>

        {/* Sponsors section */}
      </Row>
    </>
  );
};

export default ServicePaymentFail;
