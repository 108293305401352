import React from "react";
import { Row } from "react-bootstrap";
import { Successfull } from "./../../../constants/ImageConstants";
import { Link } from "react-router-dom";

const ServicePaymentSuccess = (props) => {
  return (
    <>
      <Row>
        <section className="error-section">
          <div className="auto-container">
            <div className="content">
              <img src={Successfull} alt="Payment Successfull" className="mb-4 w-25" />
              <h2>Payment Successfull</h2>
              <div className="text">Thank you for your payment</div>
              <Link to={"/"} className="theme-btn btn-style-nine">
                <span className="txt">Go to Home Page</span>
              </Link>
            </div>
          </div>
        </section>
      </Row>
    </>
  );
};

export default ServicePaymentSuccess;
