import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { DentingPaintOffer, CarWashOffer, MechanicalOffer, Appbg, CarServiceOffer } from "../../../constants/ImageConstants";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";
import OurApp from "../../../components/common/ourApp";
import Testimonial from "../../../components/common/Testimonial";

import { connect } from "react-redux";
import { OrderAction } from "../../../store/action";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


const Offers = (props) => {
  const { cityData } = props;
  return (
    <>
      <Helmet>
        <title>Exclusive VehicleCare Offers - Grab Deals & Coupons For Car Services</title>
        <meta name="description" content="Explore Unbeatable Deals, Discounts, And Coupons for A Range Of Car Services Including Periodic Maintenance, AC Servicing, Wheel Care, And More At VehicleCare." />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <section className="banner-section-three" id="offer">
          <div className="main-slider-carousel active">
            <div className={`slide ${!isMobile ? "mt-5" : "pt-4"}`}>
              <div className="auto-container">
                <div className="content-boxed">
                  <div className="">

                    <div className="row clearfix">
                      <div className="image-column col-lg-12 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                        <div className="inner-column">
                          <div className="image">

                            <Splide  options={ {
                              type   : 'loop',
                              padding: '10rem',
                              rewind : true, 
                            } }
                              hasTrack={ true }>
                              <SplideSlide>
                                <a href="https://vehiclecare.in/gurgaon/services/car-cleaning/hyundai-i10-cng" target="_blank"><img src={CarWashOffer} alt="Car wash"/></a>
                              </SplideSlide>
                              <SplideSlide>
                                <a href="https://vehiclecare.in/gurgaon/services/mechanical-repairs/hyundai-i10-cng" target="_blank"><img src={MechanicalOffer} alt="Mechanical repair"/></a>
                              </SplideSlide>
                              <SplideSlide>
                                <a href="https://vehiclecare.in/gurgaon/services/denting-painting/hyundai-i10-cng" target="_blank"><img src={DentingPaintOffer} alt="Denting and painting"/></a>
                              </SplideSlide>
                              <SplideSlide>
                                <a href="https://vehiclecare.in/gurgaon/services/periodic-car-services/hyundai-i10-cng" target="_blank"><img src={CarServiceOffer} alt="Car service"/></a>
                              </SplideSlide>
                            </Splide>

                          </div>
                        </div>
                      </div>

                      <div className="image-column col-lg-12 col-md-12 col-sm-12 pt-5 d-md-none">
                        <div className="inner-column">
                          <div className="image">

                          <Splide  options={ {
                              
                              rewind : true, 
                            } }
                            hasTrack={ true }>
                            <SplideSlide>
                              <a href="https://vehiclecare.in/gurgaon/services/car-cleaning/hyundai-i10-cng"><img src={CarWashOffer} alt="Car wash"/></a>
                            </SplideSlide>
                            <SplideSlide>
                              <a href="https://vehiclecare.in/gurgaon/services/mechanical-repairs/hyundai-i10-cng"><img src={MechanicalOffer} alt="Mechanical repair"/></a>
                            </SplideSlide>
                            <SplideSlide>
                              <a href="https://vehiclecare.in/gurgaon/services/denting-painting/hyundai-i10-cng"><img src={DentingPaintOffer} alt="Denting and painting"/></a>
                            </SplideSlide>
                            <SplideSlide>
                              <a href="https://vehiclecare.in/gurgaon/services/periodic-car-services/hyundai-i10-cng"><img src={CarServiceOffer} alt="Car service"/></a>
                            </SplideSlide>
                          </Splide>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonial Section  */}
        <div className="testimonial-section-four d-none d-md-block">
          <Testimonial city_name={cityData.selectedCity} />
        </div>

        {/* Our App */}
        <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
        </div>

      </Row>


      {isMobile && (
        <a className="fab" href="tel:9911-020-209">
          <img src={require("../../../assets/images/phone-call.png")} />
        </a>
      )}
      <Footer />
    </>
  );
};


const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) => OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Offers);

