import { combineReducers } from "redux";
import { DESTROY_SESSION } from "../types";

import LoaderReducer from "./LoaderReducer";
import VehicleReducer from "./VehicleReducer";
import CityListReducer from "./CityListReducer";
import DocumentTypeReducer from "./DocumentTypeReducer";
import FaqListReducer from "./FaqListReducer";
import ReviewListReducer from "./ReviewListReducer";
import HelpFormListReducer from "./HelpFormListReducer";
import HomePageBannerListReducer from "./HomePageBannerListReducer";
import ServiceListReducer from "./ServiceListReducer";
import ServicePackagesListReducer from "./ServicePackagesListReducer";
import UserReducer from "./UserReducer";
import CartListReducer from "./CartListReducer";
import RecentBlogReducer from "./RecentBlogReducer";
import AppointmentDataReducer from "./AppointmentDataReducer";
import InsuranceCompanyReducer from "./InsuranceCompanyReducer";
import CreateInsuranceLeadReducer from "./CreateInsuranceLeadReducer";
import UploadInsuranceMediaReducer from "./UploadInsuranceMediaReducer";
import OrderReducer from "./OrderReducer";
import PaymentReducer from "./PaymentReducer";

const appReducer = combineReducers({
  loaderReducer: LoaderReducer,
  vehicleReducer: VehicleReducer,
  cityListReducer: CityListReducer,
  documentTypeReducer: DocumentTypeReducer,
  faqListReducer: FaqListReducer,
  reviewListReducer: ReviewListReducer,
  helpFormListReducer: HelpFormListReducer,
  homePageBannerListReducer: HomePageBannerListReducer,
  serviceListReducer: ServiceListReducer,
  userReducer: UserReducer,
  servicePackagesListReducer: ServicePackagesListReducer,
  cartListReducer: CartListReducer,
  recentBlogReducer: RecentBlogReducer,
  appointmentDataReducer: AppointmentDataReducer,
  insuranceCompanyReducer: InsuranceCompanyReducer,
  createInsuranceLeadReducer: CreateInsuranceLeadReducer,
  uploadInsuranceMediaReducer: UploadInsuranceMediaReducer,
  orderReducer: OrderReducer,
  paymentReducer: PaymentReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
