import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2, Advance, CarXray, Engine } from "../../../constants/ImageConstants";
import BannerSection from "./BannerSection";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import OurApp from "../../../components/common/ourApp";

const TotalEnergies = () => {
  return (
    <>
      <Helmet>
        <title>VehicleCare - Total Energies - Exclusive Lubricants Partner</title>
        <meta name="description" content="VehicleCare partnered with TotalEnergies to provide top-quality lubricants at an exclusive price to our customers." />
        <meta property="og:title" content="VehicleCare - Total Energies - Exclusive Lubricants Partner" />
        <meta property="og:description" content="VehicleCare partnered with TotalEnergies to provide top-quality lubricants at an exclusive price to our customers." />
        <meta property="og:url" content={`https://vehiclecare.in/lubricants`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
        <meta property="twitter:title" content="VehicleCare - Total Energies - Exclusive Lubricants Partner" />
        <meta property="twitter:description" content="VehicleCare partnered with TotalEnergies to provide top-quality lubricants at an exclusive price to our customers." />
        <meta property="twitter:url" content={`https://vehiclecare.in/lubricants`} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="main-slider-carousel">
              <div className="slide">
                <BannerSection />
              </div>
            </div>
          </section>

          {/* Efficient After-Sales Infrastructure */}
          <section className="about-section pt-0">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <div className="auto-container">
              <div className="upper-section">
                <div className="row clearfix">
                  <div className="content-column col-lg-12 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="sec-title">
                        <h2>Quartz: Driving lubricants innovation for all types of engines</h2>
                      </div>

                      <div className="text">
                        <b>
                          <font color="#ed1c24" size="4">
                            Quartz deals with the demands of a diversified customer base every day. From motorsports teams and top racers to real-life experts, such as manufacturers and mechanics, our
                            oil is tailored to meet all customer needs.{" "}
                          </font>
                        </b>
                      </div>

                      <p>
                        Quartz engineers have capitalized on 70 years of experience to design formulas with unprecedented molecular interactions. Each product contains specific hyperactive molecules
                        that create the desired effect: Quartz series are empowered with the newest technologies.
                      </p>

                      <p>
                        Thanks to a long history of bold R&D, our Quartz engine oils exceed even the most demanding requirements of car manufacturers. Quartz products are tested and approved by Major
                        Automobile Manufacturers (Original Equipment Manufacturers – OEMs).
                      </p>

                      <p>
                        These product approvals are the official guarantee proving that Quartz products are 100% compatible with the equipment and technology of these related manufacturers. Quartz
                        engine oils not only enhance engine cleanliness, boost engine protection but also improve fuel efficiency
                      </p>
                    </div>
                  </div>

                  <div className="image-column col-lg-12 col-md-12 col-sm-12">
                    <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                      <div className="image">
                        <iframe
                          id="lpq5-video1"
                          className="player"
                          frameborder="0"
                          scrolling="no"
                          allowfullscreen="true"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          src="https://vehiclecare.s3.us-east-2.amazonaws.com/vc_assets/TE_Powai_Quartz.mp4"
                          width="512"
                          height="288"
                          title="Vehicle Care - Car Service Simplified! | Best Multi-Brand Car Workshop Network In India"
                          allowFullScreen
                          autoplay
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* The Most Effective Engine Cleaning Technology */}
          <section className="services-section-four" style={{ backgroundColor: "#f4f8f9" }}>
            <div className="auto-container">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <div className="row clearfix">
                    <div className="row clearfix h-100 mb-5">
                      <div className="image-column col-lg-4 col-md-4 col-sm-12">
                        <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                          <div className="image text-center">
                            <img className="w-100 about-img" src={Advance} alt="Clean-Shield" />
                          </div>
                        </div>
                      </div>

                      <div className="content-column col-lg-8 col-md-8 col-sm-12 my-auto">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Advanced Clean-Shield Technology for Extended Engine Life
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            Our Clean-Shield technology is designed to keep your engine clean from within. Using advanced detergent molecules, it forms a protective barrier against oxidized oil
                            compounds that can lead to sludge formation. This helps fight one of the major causes of engine breakdown and ensures that your engine remains clean and well-maintained.
                            Quartz with Clean-Shield technology is a top choice for drivers worldwide, as it guarantees extended engine life and a perfectly clean engine after each oil change.
                          </div>
                          <div className="text">
                            <b>
                              <font color="black" size="4">
                                Up to 64% cleaner compared to industry limits*
                              </font>
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix h-100 mb-5">
                      <div className="content-column col-lg-8 col-md-8 col-sm-12 my-auto">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Revolutionary Age-Resistance Technology for Superior Engine Protection
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            Our age-resistance technology offers unbeatable engine protection for your vehicle. Using a unique combination of hyperactive molecules, it creates a strong, thick oil film
                            that coats all relevant engine parts, ensuring their smooth performance. With Age-Resistance technology, your engine is boosted and free to run at full capacity throughout
                            its lifespan while effectively fighting all sorts of challenges such as oil oxidation, extreme temperatures, and dirt. This advanced oil technology provides outstanding
                            protection and peace of mind for drivers, no matter what conditions they face on the road.
                          </div>
                          <div className="text">
                            <b>
                              <font color="black" size="4">
                                Up to 74% less wear compared to industry limits*
                              </font>
                            </b>
                          </div>
                        </div>
                      </div>

                      <div className="image-column col-lg-4 col-md-4 col-sm-12 text-center my-auto">
                        <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                          <div className="image text-center">
                            <img className="w-100 about-img" src={CarXray} alt="Transparent Car" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix h-100">
                      <div className="image-column col-lg-4 col-md-4 col-sm-12 my-auto">
                        <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                          <div className="image text-center">
                            <img className="w-100 about-img" src={Engine} alt="Engine Performance" />
                          </div>
                        </div>
                      </div>

                      <div className="content-column col-lg-8 col-md-8 col-sm-12 my-auto">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Rev up your engine's performance with Eco-Science Technology
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            Our Eco-Science technology is the latest breakthrough from TotalEnergies' laboratories, designed to unleash the power of all engines. Using cutting-edge friction modifiers
                            reduces friction between engine parts, allowing engines to reach their full potential. With long-lasting cleanliness and protection against aging, this technology offers
                            outstanding oil stability and significant fuel economy. Reducing carbon and particle emissions helps drivers play their part in protecting the environment. Quartz with
                            Eco-Science technology is the first choice for drivers looking to maximize their engine performance and achieve their sustainability goals.
                          </div>
                          <div className="text">
                            <b>
                              <font color="black" size="4">
                                Up to 4% Fuel Economy meaning Up to 33% improved Fuel Economy compared to industry limits.*
                                <br />
                                Up to 15X stronger oil stability compared to industry limits**
                              </font>
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* TotalEnergies: The Future of Energy */}
          {/* <section className="services-section-four" style={{ backgroundColor: "" }}>
            <div className="auto-container">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <div className="row clearfix">
                    <div className="row clearfix h-100 mb-3">
                      <div className="content-column col-lg-12 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                TotalEnergies: The Future of Energy
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            Energy is reinventing itself, and Total is leading the way with our transformation into TotalEnergies. As a global energy company, we produce and market a wide range of
                            energy sources, including oil, biofuels, natural gas, green gases, renewables, and electricity. With over 100,000 committed employees, we strive to make energy ever more
                            affordable, clean, reliable, and accessible to as many people as possible. Our presence in over 130 countries allows us to put sustainable development at the heart of our
                            projects and operations, contributing to the well-being of people worldwide. TotalEnergies is at the forefront of the energy industry, shaping the future of energy for
                            generations to come.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix h-100 mb-3">
                      <div className="content-column col-lg-12 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                TotalEnergies Lubrifiants: Leading the Way in Lubricant Innovation
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            TotalEnergies Lubrifiants is a global leader in the manufacturing and distribution of lubricants. With 42 production plants across the world, over 5,800 employees, and
                            products sold in 160 countries, TotalEnergies Lubrifiants is a trusted name in the industry. Our Lubricants division offers innovative, efficient, and environmentally
                            responsible products and services, developed by over 130 researchers in our state-of-the-art R&D centers. As a partner of choice for the automotive, industrial, and marine
                            markets, TotalEnergies Lubrifiants is committed to providing the highest quality lubricants to meet the evolving needs of our customers.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix h-100">
                      <div className="content-column col-lg-12 col-md-12 col-sm-12">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                TotalEnergies Marketing & Services: Your Partner for Sustainable Energy Solutions
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            TotalEnergies Marketing & Services is dedicated to providing our professional and private customers with a wide range of energy products and services that are both reliable
                            and sustainable. We offer petroleum products, biofuels, electric vehicle charging, and related services, and gas for road and maritime transportation, all designed to
                            support our customers' mobility while reducing their carbon footprint. With over 16,000 service stations in 107 countries, we serve over 8 million customers every day. As a
                            leading global lubricant manufacturer, we also provide high-performance products for the automotive, industrial, and maritime sectors. Our sales forces, logistics network,
                            and diverse offerings ensure that we can provide the best solutions for our B2B customers. With a team of 31,000 employees, we are committed to supporting our customers and
                            contributing to sustainable development.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* The Most Effective Engine Cleaning Technology */}
          {/* <section
            className="services-section-four"
            style={{
              backgroundImage: "linear-gradient(to bottom right, #f4f8f9,white, #fff8d5)",
            }}
          >
            <div className="auto-container">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <div className="row clearfix">
                    <div className="row clearfix h-100 mb-5">
                      <div className="content-column col-lg-6 col-md-6 col-sm-12 my-auto">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Revolutionizing Lubricant Packaging with Sustainability in Mind
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            In 2020, TotalEnergies Lubrifiants ranges have been entirely revamped to offer a high level of efficiency and innovation (new bottles, new labels, new ranges). Since 2021,
                            TotalEnergies Lubrifiants is thriving in a changing world and is introducing a breakthrough packaging innovation1 : a new bottle made with 50% recycled plastic and 100%
                            recyclable. With TotalEnergies Lubrifiants, a new vision of performance for your everyday life is ahead
                          </div>

                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Revamped Label Design for Enhanced Product Information and Education
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            Introduction of a new label design inspired by car dashboards, with panels that display product marketing and technical information (educational pictograms, product
                            features, clear viscosity grade, technical profile indication)
                          </div>

                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Product Authentication and Information Retrieval
                              </font>
                            </b>
                          </h5>
                          <div className="text">Need to authenticate and get more information about one of our products? Just scan the QR code to be redirected to our website.</div>
                        </div>
                      </div>

                      <div className="image-column col-lg-6 col-md-6 col-sm-12 text-center">
                        <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                          <div className="image text-center">
                            <img className="w-100 about-img" src={About2} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix h-100">
                      <div className="image-column col-lg-6 col-md-6 col-sm-12">
                        <div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                          <div className="image text-center">
                            <img className="w-100 about-img" src={About3} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="content-column col-lg-6 col-md-6 col-sm-12 my-auto">
                        <div className="inner-column">
                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Redesigned Caps for Hassle-free Lubricant Pouring
                              </font>
                            </b>
                          </h5>
                          <div className="text">No more splashes or leaks when pouring your lubricant. The caps have been redesigned for a simple and efficient use.</div>

                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Improved Design for Easy Handling and Pouring
                              </font>
                            </b>
                          </h5>
                          <div className="text">The new big side handle has been rethought to facilitate the carrying and pouring of the product</div>

                          <h5>
                            <b>
                              <font color="#ed1c24" size="5">
                                Quartz: Highlighting High-Quality Lubricants
                              </font>
                            </b>
                          </h5>
                          <div className="text">
                            Dedicated starburst to highlight product benefits have been designed to translate the high quality of our Quartz ranges. Choose yours according to the needs of your
                            vehicle.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* Our App */}
          <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
          </div>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default TotalEnergies;
