import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { InsuranceBanner } from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";

const SegmentsBannerSection = (props) => {
  const { source } = props;
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="content-boxed">
            <div className="lease_banner">
              <div className="row clearfix">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 text-left">
                  <h1>Vehiclecare For Lease</h1>
                  {/* <h1 className="title">Vehiclecare For Lease</div> */}
                  <div className="text">
                    Leasing a car is a great way to get a new vehicle without committing to a long-term loan. We offer services to help maintain your leased car and avoid costly penalties. Our
                    experienced mechanics keep your car in good condition throughout the lease term. Convenient online booking makes scheduling service appointments easy.
                  </div>
                  <div className="text">
                    Our services include regular maintenance check-ups and emergency repairs. We use the latest tools and equipment and genuine spare parts with an assured warranty.
                  </div>

                  <div className="btns-box">
                    <Link to={`/getintouch?source=${source}`} className="theme-btn btn-style-nine px-4 me-3">
                      <span className="txt">Get in touch</span>
                    </Link>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="image">
                      <img src="images/insurance/banner.png" alt="" />
                      <img src={InsuranceBanner} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SegmentsBannerSection;
