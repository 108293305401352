import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useNavigate,Link } from "react-router-dom";
import { ServicePackagesListAction } from "../../../../store/action";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CATEOGARY_ID } from "../../../../store/types";
import { useParams } from "react-router-dom";
import { getNavigateUrl } from "../../../../utils/customHelper";

const ServiceCateogaries = (props) => {
  const city_name = props.city_name;
  const data = props.vehicleData;
  const urlParam = useParams();
  const cgID = urlParam.id;
  const serviceCateogaries = props.serviceList.serviceList;
  const [startPosition, setStartPosition] = useState(0);
  const prevCountRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    if (prevCountRef && prevCountRef.current !== cgID) {
      props.updateService({ type: CATEOGARY_ID, payload: cgID });
      prevCountRef.current = cgID;
    }
  }, [cgID, props]);

  // const loadTab = (data) => {
  //   navigate(getNavigateUrl(`/services/${data.replace(/ /g, "-").toLowerCase()}`));
  // };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  const updateCarouselPosition = (object) => {
    if (object.property.name === "position" && object.property.value !== startPosition) {
      setStartPosition(object.property.value);
    }
  };
  return (
    <div className="column col-lg-12 col-md-12 col-sm-12 prod-tabs">
      <div className="nav-items nav-pills tab-btns" role="tablist" aria-multiselectable="true">
        {serviceCateogaries.length ? (
          <OwlCarousel
            responsiveclassName={true}
            responsive={responsive}
            className="owl-theme"
            onChange={(object) => updateCarouselPosition(object)}
            startPosition={startPosition}
            dots={false}
            nav={true}
            margin={5}
            stagePadding={10}
            autoWidth={true}
          >
            {serviceCateogaries?.map((serviceCateogary) => {
              return (
                <>
                 <Link  
                 to={`/${city_name.toLowerCase()}/services/${serviceCateogary.name.replace(/ /g, "-").toLowerCase()}/${data.brand?.name}-${data.model?.name}-${data.variant?.variant}`.replace(/ /g, "-").toLowerCase()}
                    key={serviceCateogary.id}
                    className={`nav-link tab-btn ${serviceCateogary.name.toLowerCase() === cgID.replace(/-/g, " ").toLowerCase() ? " active-btn" : ""}`}
                    role="tab"
                    id={serviceCateogary.id}
                    value={serviceCateogary.id}
                    //onClick={() => loadTab(serviceCateogary.name)}
                    aria-selected="true"
                  >
                    {serviceCateogary.name}
                    </Link>
                </>
              );
            })}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.serviceList = state.default.serviceListReducer;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.city = state.default.cityListReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  getServiceData: (params) => ServicePackagesListAction.getServiceData(params, dispatch),
  updateService: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceCateogaries);
