import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Row } from "react-bootstrap";
import "./index.scss";
import Cart from "./Cart";
import ServicePlan from "./ServicePlan";
import ServiceCateogaries from "./ServiceCateogaries";
import PointInspection15 from "./../../../components/common/PointInspection15";
import { connect } from "react-redux";
import { ServiceListAction, VehicleAction } from "../../../store/action";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CustomModal from "../../../components/common/CustomModal";
import { SET_BRAND, SET_MODEL, SET_VARIANT } from "../../../store/types";
import WhyChoose from "./WhyChoose";
import ServiceFaq from "./ServiceFaq";
import Testimonial from "../../../components/common/Testimonial";
import OilProduct from "../Lubricants/OilProduct";

const Services = (props) => {
  const urlParam = useParams();
  const navigate = useNavigate();
  const cgID = urlParam.id;
  const [cartShow, setCartShow] = useState(false);
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);
  const { vehicleData, cityData, cartList, CateogaryData, customDispatch } = props;
  const { selectedCity } = cityData;
  const { brand, model, variant, modelList } = vehicleData;

  const getCustomHelmetDetails = () => {
    const returnVal = { title: "", description: "" };
    if (variant === null || variant === undefined) return returnVal;
    switch (cgID) {
      case "periodic-car-services":
        returnVal.title = `Book the best periodic car maintenance service and mechanical repair for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book hassle-free periodic car service and an accidental claim for your ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
      case "denting-painting":
        returnVal.title = `Book the best quality denting and painting service for the  with VehicleCare ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book a quality car denting and painting service for your ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
      case "car-ac-services":
        returnVal.title = `Book the coolest AC repair and maintenance service for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book a quality car AC repair and maintenance service for your ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
      case "mechanical-repairs":
        returnVal.title = `Book the best mechanical repair and maintenance service for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book a custom mechanical repair and maintenance service for your ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
      case "wheel-care":
        returnVal.title = `Book the quality wheel repair and maintenance service for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book wheel care, wheel alignment, and balancing services for your  ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
      case "car-cleaning":
        returnVal.title = `Book the quality car wash and interior dry cleaning service for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book car wash and car interior dry cleaning services for your  ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
      case "car-detailing-&-spa":
        returnVal.title = `Book the quality car detailing and spa service for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book quality car detailing,car wrapping,car nano coating, teflon coating,and paint protection services for your ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
      case "custom-repairs":
        returnVal.title = `Book the custom repair service for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book a detailed car scanning and diagnosis service for your ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;

      default:
        returnVal.title = `Book the best periodic car maintenance service and mechanical repair for the ${brand.name} ${model.name} ${variant.variant} in ${selectedCity} with VehicleCare`;
        returnVal.description = `Book hassle-free periodic car service and an accidental claim for your ${brand.name} ${model.name} ${variant.variant} from VehicleCare in ${selectedCity}. We provide free pickup and drop-off along with dedicated support.`;
        break;
    }
    return returnVal;
  };
  const handleCartClose = () => setCartShow(false);
  const CartModalContent = () => {
    return (
      <Row>
        <div className="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12">
          <Cart passingData={passingData} />
        </div>
      </Row>
    );
  };
  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(elementRef.current.clientHeight);
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []); //empty dependency array so it only runs once at render

  useEffect(() => {
    const getsetVehicleInfo = () => {
      let vehicleArr = urlParam.vehicle.split("-");
      let brandName = vehicleArr[0].trim();
      if (vehicleArr.length > 3) {
        brandName = `${vehicleArr[0].trim()}-${vehicleArr[1].trim()}`;
      }
      const variantName = vehicleArr[vehicleArr.length - 1].trim();
      const modelName = urlParam.vehicle.replace(brandName, "").replace(variantName, "").replace(/-/g, " ").trim();
      if (!modelList.length) {
        const params = { task: "getCardModels", search_text: modelName };
        props.getModelNames(params);
      } else {
        const brandData = { id: modelList[0].brand_id, name: modelList[0].brand_name, icon: "" };
        const modelData = {
          id: modelList[0].model_id,
          name: modelList[0].model_name,
          icon: modelList[0].icon,
          segment_type: modelList[0].segment_type,
          variants: modelList[0].variants,
        };
        props.customDispatch({ type: SET_BRAND, payload: brandData });
        props.customDispatch({
          type: SET_MODEL,
          payload: modelData,
        });
        const newVariantList = modelList[0].variants.filter((elemn) => elemn.variant.toLowerCase() === variantName.toLowerCase());
        props.customDispatch({ type: SET_VARIANT, payload: newVariantList[0] });
        const vehicleDetails = { brand: brandData, model: modelData, variant: newVariantList[0] };
        window.sessionStorage.setItem("vehicleDetails", JSON.stringify(vehicleDetails));
      }
    };
    if (!CateogaryData.serviceList.length) {
      props.getServiceCateogaries({ task: "getServiceCateogaries" });
    }
    if (variant !== null) {
      const vehiclePath = `${brand.name}-${model.name}-${variant.variant}`.replace(/ /g, "-").toLowerCase();
      if (!urlParam.vehicle) {
        const path = `${window.location.pathname}/${vehiclePath}`.replace(/([^:]\/)\/+/g, "$1");
        navigate(path);
      }
    } else {
      if (urlParam.vehicle && variant === null) {
        getsetVehicleInfo();
      } else {
        if (!document.getElementById("vehicleSelectorModal")?.parentElement.classList.contains("show")) {
          document.getElementById("custom-vehicle-selector-brand").click();
        }
      }
    }
  }, [brand, model, variant, props, CateogaryData, vehicleData, modelList, navigate, urlParam]);
  let passingData = {};
  if (variant === null) {
    passingData = {
      brand: {
        icon: "https://vehiclecare.in/images/carBrand/Hyundai.png",
        id: "16",
        name: "Hyundai",
      },
      model: {
        icon: "https://vehiclecare.in/images/car_models/hyundai-grandi10-petrol-tilted.jpg",
        id: "148",
        name: "GRAND i 10",
        segment_type: "HB",
        variants: [
          { id: 251, variant: "Diesel" },
          { id: 250, variant: "Petrol" },
          { id: 249, variant: "Cng" },
        ],
      },
      variant: { id: 250, variant: "Petrol" },
    };
  } else {
    passingData = {
      brand: brand,
      model: model,
      variant: variant,
    };
  }
  const { title, description } = getCustomHelmetDetails();
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://vehiclecare.in${window.location.pathname}`} />
      </Helmet>
      <Header />

      <Row>
        {/* Banner Section */}
        {/* <section className="banner-section-three style-two">
          <div className="slide" style={{ backgroundColor: "#F5F1EE" }}>
            <ServiceBanner />
          </div>
        </section> */}

        {/* Price Section  */}
        <div className="sidebar-page-container shop-single-section pb-5" style={{ backgroundColor: "#F5F1EE" }}>
          <div className="auto-container">
            <div className="row clearfix">
              <div className="shop-page">
                <ServiceCateogaries passingData={passingData} city_name={cityData.selectedCity} />
              </div>

              {/* <div className="right-sidebar col-lg-8 col-md-12 col-sm-12"> */}
              <div className="right-sidebar col-lg-8 col-md-12 col-sm-12 mt-2" style={{ height: !isMobile && `${height + 120}px`, overflow: "scroll" }}>
                <ServicePlan passingData={passingData.variant} />
              </div>

              <div className="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12 mt-5" style={{ backgroundColor: "white" }}>
                <aside className={`sidebar sticky-top ${isMobile && "isHidden"}`} ref={elementRef}>
                  <Cart passingData={passingData} />

                  {/* <div className="sidebar-widget popular-posts">
                    <div className="widget-content">
                      <article className="">
                        <figure className="">
                          <img src={ServiceCard} alt="" />
                        </figure>
                      </article>
                    </div>
                  </div> */}
                </aside>
              </div>
            </div>
          </div>
        </div>

        {/* whychoose us */}
        <div className="faq-page-section acordian-section pb-4 pt-5 bg-white">
          <WhyChoose passingData={passingData} />
        </div>

        <div className="about-section pb-0">
          <OilProduct />
        </div>

        {/* faq service acc. */}
        <div className="acordian-section pb-4 pt-5">
          <ServiceFaq passingData={passingData} />
        </div>

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name={cityData.selectedCity} />
        </div>

        {/* 15 point inspection */}
        <div className="service-points-section pt-5 pb-5">
          <div className="auto-container">
            <PointInspection15 />
          </div>
        </div>
      </Row>

      <Footer />
      {isMobile && (
        <div className={`footer-fix my-2`}>
          <div className="no-gutters">
            <button className="col-auto mx-auto bg-danger py-2 text-center text-white rounded btn btn-danger w-100" onClick={() => setCartShow(true)}>
              <h6>
                <i className="fa fa-shopping-cart custom-mobile-cart mx-1" aria-hidden="true"></i>
                View Cart ( {cartList.length} )
              </h6>
            </button>
          </div>
        </div>
      )}
      <CustomModal show={cartShow} title="Cart" content={<CartModalContent />} isCloseButton={true} handleClose={handleCartClose} />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer.cartList;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.CateogaryData = state.default.serviceListReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  getModelNames: (params) => VehicleAction.getModelNames(params, dispatch),
  getServiceCateogaries: (params) => ServiceListAction.getServiceCateogaries(params, dispatch),
  customDispatch: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(Services);
