import React from "react";
import { Row, Col } from "react-bootstrap";
import { CareerImg, Appbg } from "../../../constants/ImageConstants";
import OurApp from "../../../components/common/ourApp";
import "./index.scss";
import Footer from "../../../components/NavigationBar/Footer";
import Header from "../../../components/NavigationBar/Header";

import { Helmet } from "react-helmet";

const Career = (props) => {
  return (
    <>
      <Helmet>
        <title>Career - VehicleCare</title>
        <meta name="description" content="Let's build a autoTech solution for our clients , Join our team of talented individual " />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col className="price-section aboutus mt-5 col pb-0">
          <section className="contact-form-section">
            <div className="auto-container getintouch">
              <div className="row clearfix">
                <div className="form-column col-lg-5 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="sec-title">
                      <div className="title">Career</div>
                      <h2>Get in touch</h2>
                    </div>
                    <div className="banner-section-three" style={{ zIndex: 0 }}>
                      <div className="content-boxed">
                        <h2>
                          Open <br />
                          <font color="#ed1c24">Recruitment...</font>
                        </h2>

                        <button
                          className="theme-btn btn-style-nine getQuote"
                          onClick={(e) => {
                            window.location.href = "mailto:hr@vehiclecare.in?subject=Job Application";
                          }}
                        >
                          <span className="txt">Join Us</span>
                        </button>
                      </div>
                    </div>
                    {/* <div className="contact-form">
                      <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                          <Form.Group className="column col-md-6 form-group">
                            <Form.Label className="d-none d-md-block d-lg-block">Name</Form.Label>
                            <Form.Control required value={data.userName} name="userName" type="text" placeholder="Enter Your name" onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your Name</Form.Control.Feedback>
                            <ErrorOutput case={data.userName} name={"userName"} />
                          </Form.Group>
                          <Form.Group className="column col-md-6 form-group" controlId="validationCustom02">
                            <Form.Label className="d-none d-md-block d-lg-block">Email</Form.Label>
                            <Form.Control required value={data.userEmail} type="email" name="userEmail" placeholder="Enter Your email" onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your Email</Form.Control.Feedback>
                            <ErrorOutput case={data.userEmail} name={"userEmail"} />
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group className="column col-md-6 form-group" controlId="validationCustom03">
                            <Form.Label className="d-none d-md-block d-lg-block">Phone</Form.Label>
                            <Form.Control required value={data.userNumber} type="text" name="userNumber" placeholder="Enter Your Number" onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your Number</Form.Control.Feedback>
                            <ErrorOutput case={data.userNumber} name={"userNumber"} />
                          </Form.Group>
                          <Form.Group className="column col-md-6 form-group" controlId="validationCustom04">
                            <Form.Label className="d-none d-md-block d-lg-block">
                              Upload CV <small className="text-danger">(.pdf only)</small>
                            </Form.Label>
                            <Form.Control required type="file" name="uploadcv" accept="application/pdf" placeholder="Upload CV" onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">Please upload your CV</Form.Control.Feedback>
                            <ErrorOutput case={data.uploadcv} name={"uploadcv"} />
                          </Form.Group>
                        </Row>

                        <Form.Group>
                          <Form.Label className="d-none d-md-block d-lg-block">Message For Us</Form.Label>
                          <Form.Control value={data.userMsg} as="textarea" name="userMsg" placeholder="Type your message here..." onChange={(e) => handleChange(e)} />
                        </Form.Group>
                        <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                          <button className="theme-btn btn-style-nine getQuote" type="submit" name="submit-form">
                            <span className="txt">Submit</span>
                          </button>
                        </div>
                      </Form>
                    </div> */}
                  </div>
                </div>

                <div className="blocks-column col-lg-7 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="row clearfix">
                      <img src={CareerImg} alt="Recruitment" className="w-100" />
                    </div>
                    {/* <div className="info-block col-lg-10 col-md-10 col-sm-10 mx-auto">
                      <div className="inner-box py-0 bg-transparent">
                        <h3>Make A Call</h3>
                        <a href="tel:+844-1800-33-999" className="phone">
                          {" "}
                          +91 9911-0202-09
                        </a>
                        <br />
                        <a href="mailto:support@vehiclecare.in" className="email">
                          {" "}
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Our App */}
          <div className="testimonial-section-two bg-size" style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}>
            <OurApp />
          </div>

        </Col>
      </Row>

      <Footer />
    </>
  );
};
export default Career;
