import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import "./index.scss";
import { Link } from "react-router-dom";

const CityList = (props) => {
  return (
    <Row>
      <div className="auto-container clearfix">
        <div className="sec-title centered">
          <h2>Cities we serve</h2>
        </div>
        <div className="row">
          
          <div className="d-none d-md-block">
            <div className="col-lg-12 col-md-12 col-sm-12 px-0">
              <div className="row">
                {props.cityList.map((city) => {
                    return (
                      <div className="col-lg-1 col-md-3 col-4" key={city.id}>
                        <ul className="service-list-two mb-3">
                          <li>
                          <Link to={`/${city.name.toLowerCase()}`}>
                            <span className="text-dark">{city.name}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

            
          <div className="px-2 d-md-none">
            <div className="col-lg-12 col-md-12 col-sm-12 px-3">
              <div className="row">
                {props.cityList.map((city) => {
                  return (
                    <div className="col-4" key={city.id}>
                      <ul className="service-list-two mb-3">
                        <li>
                        <Link to={`/${city.name.toLowerCase()}`}>
                          <span className="text-dark">{city.name}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>


          
        </div>
      </div>
    </Row>
  );
};
const mapStateToProps = (state) => {
  return state.default.cityListReducer;
};
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CityList);
