import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { DentingPaintOffer, CarWashOffer, MechanicalOffer, Appbg, CarServiceOffer } from "../../../constants/ImageConstants";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";
import OurApp from "../../../components/common/ourApp";
import Testimonial from "../../../components/common/Testimonial";

import { connect } from "react-redux";
import { OrderAction } from "../../../store/action";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Banner from "./Banner";


const AMC = (props) => {
  const { cityData } = props;
  return (
    <>
      <Helmet>
        <title>Search Annual Maintenance Contract (AMC) For Our Car</title>
        <meta name="description" content="Saving Up To 50% With VehicleCare AMC Plan, Provides The Offers 2 A/c services, 2 car health checkups, 2 car wash and 24x7 roadside assistance." />

        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <Header isStatic={true} />

      <Row>

        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>
                     
        <section className="about-section pt-5 pb-5">
          <div className="auto-container CustomisablePlatform">
            <div className="upper-section">
              <div className="sec-title centered">
                <h1 className="title">AMC</h1> 
                <h2 className="text-left"> Annual Maintenance Contract </h2>
              </div>

              <div className="row clearfix mb-4">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 my-auto">
                  <div className="inner-column px-2">
                    <div className="text">
                      VehicleCare Annual Maintenance Contracts are carefully designed to cater to the unique needs of every driver. Our AMC plans offer tailored and budget-friendly options to keep your vehicle in optimal condition throughout the year. Whether you are a low-mileage commuter, a high-mileage traveller or a used car owner, we offer you the flexibility to choose the services as per your special needs. VehicleCare AMC ensures comprehensive and convenient maintenance, helping you manage your vehicle repair and maintenance costs and avoid unexpected expenses. VehicleCare believes in empowering you, so you drive confidently, knowing that your vehicle’s care is in expert hands.
                    </div>

                    <hr />

                    <div className="text">
                      <h4 className="fw-bold text-dark">
                        <font >Savings up to 50% with <font color="#ed1c24">Vehiclecare AMC</font> plan</font>
                      </h4>
                    </div>

                    <div className="text">
                      Make the smart decision take advantage of our Annual Maintenance Contract(AMC), and enjoy savings of up to a whopping 50%. Our plans are available at most used car dealers and online, promising unparalleled peace of mind. All services are performed either at your doorstep at a time that suits you best. Invest in VehicleCare AMC Plan and unlock significant savings to ensure expert care for your vehicle and experience convenience and peace of mind throughout the year.
                    </div>

                    <hr />

                    <div className="text">
                      <h4 className="fw-bold text-dark">
                        <font color="#ed1c24">Gold Plan :</font>
                      </h4>
                    </div>

                    <div className="text">
                      Year-on-year vehicle maintenance can be a costly and time-consuming affair. Due to our busy schedule, we often don't give the priority and care that our vehicles deserve. VehcilCare offers an effortless and cost-effective solution to this problem through our Annual Maintenance Gold Plan.
                    </div>
                  
                    <div className="text">
                    VehicleCare Annual Maintenance Contract Gold Plan is the ultimate formula for comprehensive car care at unbeatable savings. This premium plan includes all the essential services such as engine oil change, air and oil filter replacement, coolant top-ups, wiper fluid refills, tyre-pressure check, brake oil servicing and thorough brake maintenance. The plan also includes the added <font color="#ed1c24"> benefits of 2 A/c services, 2 car health checkups, 2 car wash and 24x7 roadside assistance</font>. As a premium Gold plan member, you also get an additional 50% discount on all mechanical labour costs.

                    <br/>

                    Our Gold Plan ensures that your car remains in its peak condition and saves you money in the long run. Invest in VehicleCare AMC Gold Plan for worry-free driving and exceptional value throughout the year. 
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* Testimonial Section  */}
        <div className="testimonial-section-four d-none d-md-block">
          <Testimonial city_name={cityData.selectedCity} />
        </div>

        {/* Our App */}
        <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
        </div>

      </Row>


      {isMobile && (
        <a className="fab" href="tel:9911-020-209">
          <img src={require("../../../assets/images/phone-call.png")} />
        </a>
      )}
      <Footer />
    </>
  );
};


const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) => OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AMC);

