import React from "react";
import { Row, Col  } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 } from "./../../../constants/ImageConstants";

import OurApp from "./../../../components/common/ourApp";

import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import Testimonial from "../../../components/common/Testimonial";
import BlogMultiImageCarousel from "./../../../components/common/blogMultiImageCarousel";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { useParams } from "react-router-dom";
import { toTitleCase } from "../../../utils/customHelper";
import { Helmet } from "react-helmet";
import DentPaintPriceList from "./DentPaintPriceList";
import BestPrice from "../CarService/BestPrice";

const DentPaint = () => {
  const urlParam = useParams();
  const selectedCityName = toTitleCase(urlParam.city.replace(/-/g, " "));
  return (
    <>
      <Helmet>
        <title>
          {" "}
          Best Car Dent Paint Services in {selectedCityName} - Fix Car Dent And Scratch
        </title>
        <meta
          name="description"
          content={`Book Your Car Dent Paint Service In ${selectedCityName} With a Free Pickup And Drop Facility. Get Rid Of The Scratches And Dents In Your Car.`}
          data-react-helmet="true"
        />

        <meta property="og:title" content={`Best Car Dent Paint Services in ${selectedCityName} - Fix Car Dent And Scratch`} /> 
        <meta
          property="og:description"
          content={`Book Your Car Dent Paint Service In ${selectedCityName} With a Free Pickup And Drop Facility. Get Rid Of The Scratches And Dents In Your Car.`}
        />
        <meta property="og:url" content={`https://vehiclecare.in/${urlParam.city.replace(/-/g, " ")}/dent-paint`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
        <meta property="twitter:title" content={`Best Car Dent Paint Services in ${selectedCityName} - Fix Car Dent and Scratches`} />
        <meta
          property="twitter:description"
          content={`Book your car dent paint service online with a Free Pickup and Drop facility in ${selectedCityName}. Get rid of the scratches and dents from your car.`}
        />
        <meta property="twitter:url" content={`https://vehiclecare.in/${urlParam.city.replace(/-/g, " ")}/dent-paint`} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />

        <link rel="canonical" href={`https://vehiclecare.in/${urlParam.city.toLowerCase()?.replace(" ", "-")}/dent-paint`} />
        
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                  car wash, 
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="services-section-four pt-0">
          <ServiceSteps />
        </div>

        <div className="blog-section pt-0">
          <DentPaintPriceList />
        </div>

        <div className="price-section" style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        <hr />

        {/*Best Prices - No Hidden Costs*/}
        <section className="about-section pt-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <BestPrice />
        </section>

        {/* <WhyChooseVc /> */}
        <div className="services-section-four pb-5">
          <div className="auto-container">
            <Row className="justify-content-md-center">
              <Col xs={11} lg={11}>
                <div className="sec-title">
                  <h2 className="text-left">Why Choose VehicleCare for Your Car's <font color="#ed1c24">Dent & Paint</font>  Needs?  </h2>
                  
                  <div className="row">
                    <Col lg={6} sm={12}>
                      <div class="card mb-2">
                        <div class="card-body">
                        <p className="mb-0"><b><font color="#ed1c24">1.</font></b>
                          Your car is your baby and we understand your feelings. That is why, we handle your car with extreme care and attention. Whether you own a sedan, an SUV, a luxury car, or any other vehicle, we have extensive experience working with various car makes and models, ensuring a perfect match for your vehicle's specific requirements. VehicleCare, promises to take care of your car at every step of its service. VehicleCare is equipped to handle all types of dent and paint needs of all categories of cars. We have extensive experience working with various car makes and models, ensuring a perfect match for your vehicle's specific requirements.</p>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <p className="mb-0"><b><font color="#ed1c24">2.</font></b>
                          VehicleCare offers booking at your fingertips. Book your appointment online through our website or VehicleCare app or call us for a stress-free experience. We also offer door pick-up and drop-off services.</p>
                        </div>
                      </div>

                    </Col>

                    <Col lg={6} sm={12}>
                      <div class="card mb-2">
                        <div class="card-body">
                          <p className="mb-0"><b><font color="#ed1c24">3.</font></b>
                          We invest in cutting-edge tools and advanced technology to ensure efficient and high-quality service. VehicleCare believes in transparent and quick communication which is why we keep you informed throughout the repair process, addressing any queries or concerns you may have. </p>
                        </div>
                      </div>

                      <div class="card">
                        <div class="card-body">
                          <p className="mb-0"><b><font color="#ed1c24">4.</font></b> We provide a free one-year warranty on all our services including dent & paint services on all our VehicleCare workshops. On every dent and paint service you avail from us, we employ a dedicated service manager to keep you updated on your service status. We keep you updated throughout your service journey.</p>
                        </div>
                      </div>
                    </Col>
                  </div>

                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/*What Is Included In Car Service In {selectedCityName}*/}
        <section className="about-section pt-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <Row className="justify-content-md-center">
              <Col xs={12} md={12}>
                <div className="sec-title centered">
                  <h2 className="title">Here are all the checkpoints for auto repair & service in {selectedCityName}</h2>
                  <h2 className="text-center">What Is Included In Car Dent Paint Service In {selectedCityName}</h2>

                  <div className="text px-5 d-none d-md-block">Dents and scratches can significantly impact your car's aesthetics and decrease its resale value. With VehicleCare, you get nothing but the best dent and paint service. We revive your car's appearance and boost its resale value. Choose VehicleCare for affordable dent pain services. 
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-md-center" lg={12}>
              <Col xs={12} md={7}>
                <div className="text fs-5 text-dark mb-3"><b>VehicleCare offers full body paint and selective body part paint and dents for your car. We offer a range of dent and paint services for all parts.</b>
                </div>

                <div className="px-3">
                  <p className="mb-1">1. Bumpers</p>
                  <p className="mb-1">2. Bonnets</p>
                  <p className="mb-1">3. Side doors & Rear doors</p>
                  <p className="mb-1">4. Right fender & Left fender</p>
                  <p className="mb-1">5. Boot lid</p>
                  <p className="mb-1">6. Whole body paint (including door jambs & under-hood)</p>
                </div>
              </Col>

              <Col xs={12} md={4}>
                <div className="text fs-5 text-dark mb-3"><b>Common Causes of Car Dents:</b>
                </div>

                <div className="px-3">
                  <p className="mb-1">1. Minor accidents and Collisions</p>
                  <p className="mb-1">2. Parking mishaps</p>
                  <p className="mb-1">3. Hail damage</p>
                  <p className="mb-1">4. Vandalism</p>
                  <p className="mb-1">5. Stray objects hitting the car</p>
                  <p className="mb-1">6. Sudden Weather Events</p>
                  <p className="mb-1">7. Road bumps</p>
                  <p className="mb-1">8. Dent by tress branches and debris </p>
                </div>
              </Col>
            </Row> 
          </div>
        </section>

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial service={"Car Service"} city_name={selectedCityName} />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>

        
      </Row>
      <Footer />
    </>
  );
};

export default DentPaint;
