import { customEncrypt } from "../../utils/authHelper";
import Cookies from "js-cookie";
import { User_Login, New_User_Login, Sign_Out, User_SignUp, USER_PROFILE, UPDATE_VEHILCE } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    is_user_logged_in: false,
    user_profile: {},
    full_profile: null,
    is_new_user: false,
  },
  action = {}
) => {
  let fullProfile = window.localStorage.getItem("fullProfile");
  let userProfile = window.localStorage.getItem("userProfile");
  let user_token = window.localStorage.getItem("user_token");
  if (userProfile && user_token) {
    userProfile = JSON.parse(userProfile);
    state.user_profile = userProfile;
    state.is_user_logged_in = true;
    state.is_new_user = false;
    if (fullProfile) state.full_profile = JSON.parse(fullProfile);
  }
  const makeLocalprofile = (data) => {
    data = data.user;
    const profData = {
      id: data.user_id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
    };
    window.localStorage.setItem("userProfile", JSON.stringify(profData));
    window.localStorage.setItem("user_token", customEncrypt(data.token));
    Cookies.set("user_id", data.user_id);
    return profData;
  };
  switch (action.type) {
    case User_Login:
      const loginData = makeLocalprofile(action.payload);
      return {
        ...state,
        user_profile: loginData,
        is_user_logged_in: true,
        is_new_user: false,
      };
    case New_User_Login:
      return {
        ...state,
        user_profile: { phone: action.payload },
        is_new_user: true,
        is_user_logged_in: true,
      };
    case UPDATE_VEHILCE:
      // state.full_profile.user.vehicles = action.payload;
      // window.localStorage.setItem("fullProfile", JSON.stringify(state.full_profile));
      return { ...state, full_profile: { ...state.full_profile.user, vehicles: action.payload } };
    case USER_PROFILE:
      window.localStorage.setItem("fullProfile", JSON.stringify(action.payload));
      return {
        ...state,
        full_profile: action.payload,
      };
    case Sign_Out:
      window.localStorage.removeItem("userProfile");
      window.localStorage.removeItem("fullProfile");
      window.localStorage.removeItem("user_token");
      Cookies.remove("user_id");
      return {
        ...state,
        user_profile: {},
        full_profile: null,
        is_new_user: false,
        is_user_logged_in: false,
      };
    case User_SignUp:
      const signupData = makeLocalprofile(action.payload);
      return {
        ...state,
        user_profile: signupData,
        is_user_logged_in: true,
        is_new_user: false,
      };
    default:
      return state;
  }
};
