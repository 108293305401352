import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
// import {  Link } from "react-router-dom";
import { GearIcon, PhoneIcon, MailIcon } from "./../../../../constants/ImageConstants";

const CompanyInfo = () => {
  return (
    <Row>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="info-block col-lg-6 col-md-6 col-sm-12">
            <div className="inner-box">
              <div className="gear-icon">
                <img src={GearIcon} alt="" />
              </div>
              <div className="side-icon">
                <img src={PhoneIcon} alt="" />
              </div>
              <div className="icon fa fa-phone"></div>
              <h3>Make A Call</h3>
              <div className="text">Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</div>
              <a href="tel:+91-991-102-0209" className="phone">
                +91-991-102-0209
              </a>
            </div>
          </div>

          <div className="info-block col-lg-6 col-md-6 col-sm-12">
            <div className="inner-box">
              <div className="gear-icon">
                <img src={GearIcon} alt="" />
              </div>
              <div className="side-icon">
                <img src={MailIcon} alt="" />
              </div>
              <div className="icon fa fa-envelope"></div>
              <h3>Email</h3>
              <div className="text">Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</div>
              <a href="mailto:support@vehiclecare.in?subject=Need Help" className="email">
                {" "}
                support@vehiclecare.in
              </a>
              <a href="mailto:vehiclecarecustomer@gmail.com?subject=Need Help" className="email">
                {" "}
                vehiclecarecustomer@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default CompanyInfo;
