import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { ACRepair, Battery, CarWash, CustumRepair, DentingPainting, InsuranceClaimLanding, PeriodicService, WheelCare } from "../../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import { CityListAction, VehicleAction } from "../../../../store/action";
import { connect } from "react-redux";


const EvServices = (props) => {

  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="sec-title centered">
            <h2>Services We Offer</h2>
            {/* <div className="text col-8 mx-auto d-none d-md-block">
            Find the best service packages for your car. Select from a wide range of car services, from general service to
            <a href="https://vehiclecare.in/car-wash-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24">  car wash, </font></a>
             accidental repair to custom repair, cashless insurance claims, and much more.
            </div> */}
          </div>

          <div className="d-flex align-items-center justify-content-center">
            <div className="col-lg-10 col-md-11 col-sm-12">
              <div className="row">
                <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                  <div className="inner-box text-center border-0">
                    <div className="">
                    <Link className="text-dark" to={"/"}>
                        <img className="w-35 mx-auto pt-4" src={CarWash} alt="Car Service" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={"/"}>
                          {" "}
                          Service{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                  <div className="inner-box text-center border-0">
                    <div className="">
                    <Link className="text-dark" to={"/"}>
                        <img className="w-35 mx-auto pt-4" src={WheelCare} alt="Wheel Care" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={"/"}>
                          {" "}
                          Wheel Care
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                  <div className="inner-box text-center border-0">
                    <div className="">
                    <Link className="text-dark" to={"/"}>
                        <img className="w-35 mx-auto pt-4" src={ACRepair} alt="Motor" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={"/"}>
                          {" "}
                          Motor{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6">
                  <div className="inner-box text-center border-0">
                    <div className="">
                    <Link className="text-dark" to={"/"}>
                        <img className="w-35 mx-auto pt-4" src={Battery} alt="Battery" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={"/"}>
                          {" "}
                          Battery{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    vehicleReducer: state.default.vehicleReducer,
    cityListReducer: state.default.cityListReducer,
    userReducer: state.default.userReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getBrandNames: (params) => VehicleAction.getBrandNames(params, dispatch),
  getCityList: (params) => CityListAction.getCityList(params, dispatch),
  updateReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(EvServices);