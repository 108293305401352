import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { Helmet } from "react-helmet";

const RecentNews = () => {
  return (
    <>
      <Helmet>
        <title>VehicleCare Media Coverage</title>
        <meta name="description" content="VehicleCare is a market leader in autotech industry , with more than 1000 workshop all over india." />
      </Helmet>

      <Header isStatic={true} />

      <Row>
      <section className="service-page-section mt-3" id="recentNews">
        <div className="auto-container">

          <div className="sec-title centered"><div className="title">VehicleCare In The Press</div><h2>Recent News</h2></div>

          <div className="row clearfix">
            
            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://www.financialexpress.com/business/express-mobility-vehiclecare-gets-undisclosed-pre-series-a-funding-led-by-jito-angel-networknbsp-3165329/" target="_blank" rel="nofollow noreferrer"><img src="https://www.financialexpress.com/wp-content/uploads/2023/07/Vehiclecare.jpg?w=1024" alt="VehicleCare gets undisclosed Pre-Series A funding led by JITO Angel Network" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>VehicleCare gets undisclosed Pre-Series A funding led by JITO Angel Network </h6>
                  
                  <a href="https://www.financialexpress.com/business/express-mobility-vehiclecare-gets-undisclosed-pre-series-a-funding-led-by-jito-angel-networknbsp-3165329/" target="_blank" rel="nofollow noreferrer"><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://www.vccircle.com/earlystage-startups-okulo-aerospace-vehiclecare-raise-funding" target="_blank" rel="nofollow noreferrer"><img src="https://images.hindustantimes.com/auto/img/2023/05/05/600x338/Screenshot_2023-05-05_181155_1683290567246_1683290577980.png" alt="Early-Stage Startups Okulo Aerospace, Vehiclecare Raise Funding" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>Early-Stage Startups Okulo Aerospace, Vehiclecare Raise Funding </h6>
                  
                  <a href="https://www.vccircle.com/earlystage-startups-okulo-aerospace-vehiclecare-raise-funding" target="_blank" rel="nofollow noreferrer"><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://auto.economictimes.indiatimes.com/news/industry/totalenergies-enters-online-to-offline-segment-with-vehiclecare-automovill/99063727" target="_blank" rel="nofollow noreferrer"><img src="https://etimg.etb2bimg.com/photo/99063764.cms" alt="TotalEnergies enters Online-to-Offline segment with VehicleCare, Automovill " /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>TotalEnergies enters Online-to-Offline segment with VehicleCare, Automovill </h6>
                  
                  <a href="https://auto.economictimes.indiatimes.com/news/industry/totalenergies-enters-online-to-offline-segment-with-vehiclecare-automovill/99063727" target="_blank" rel="nofollow noreferrer"><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://www.entrepreneur.com/en-in/news-and-trends/vehiclecare-secures-undisclosed-investment-in-pre-series-a/455617" target="_blank" rel="noreferrer"><img src="https://assets.entrepreneur.com/content/3x2/2000/1689075868-Untitleddesign-2023-07-11T171217174.jpg?format=pjeg&auto=webp&crop=16:9&width=675&height=380" alt="VehicleCare Secures Undisclosed Investment In Pre-Series A Funding" rel="nofollow" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>VehicleCare Secures Undisclosed Investment In Pre-Series A Funding</h6>
                  
                  <a href="https://www.entrepreneur.com/en-in/news-and-trends/vehiclecare-secures-undisclosed-investment-in-pre-series-a/455617" target="_blank" rel="nofollow noreferrer" ><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>


            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://auto.hindustantimes.com/brand-stories/vehiclecare-pioneering-the-future-of-ev-maintenance-and-repair-in-india-41683290379468.html" target="_blank" rel="nofollow noreferrer"><img src="https://images.hindustantimes.com/auto/img/2023/05/05/600x338/Screenshot_2023-05-05_181155_1683290567246_1683290577980.png" alt="VehicleCare: Pioneering the Future of EV Maintenance and Repair in India" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>VehicleCare: Pioneering the Future of EV Maintenance and Repair in India</h6>
                  
                  <a href="https://auto.hindustantimes.com/brand-stories/vehiclecare-pioneering-the-future-of-ev-maintenance-and-repair-in-india-41683290379468.html" target="_blank" rel="nofollow noreferrer"><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://www.financialexpress.com/auto/industry/vehicle-care-to-open-500-workshops-this-year-future-plans-road-to-recovery-more-explained/2318980/" target="_blank" rel="nofollow noreferrer"><img src="https://www.financialexpress.com/wp-content/uploads/2021/08/Mr.-Arvind-Kumar-Founder-Vehicle-Care.jpg-2.jpg" alt="Vehicle Care to open 500+ workshops this year: Future plans, road to recovery & more explained" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>Vehicle Care to open 500+ workshops this year: Future plans, road to recovery & more explained</h6>
                  
                  <a href="https://www.financialexpress.com/auto/industry/vehicle-care-to-open-500-workshops-this-year-future-plans-road-to-recovery-more-explained/2318980/" target="_blank" rel="nofollow noreferrer" ><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://navbharattimes.indiatimes.com/auto/car-bikes/conversation-with-vehiclecare-cofounder-arvind-verma/articleshow/87187726.cms" target="_blank" rel="nofollow noreferrer"><img src="https://navbharattimes.indiatimes.com/thumb/87188401/conversation-with-vehiclecare-cofounder-arvind-verma-87188401.jpg?imgsize=40260&width=700&height=525&resizemode=75" alt="'ऑफ्टर कार सर्विस' बाजार में क्या है VehicleCare की दावेदारी? अरविंद वर्मा से खास..." /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>'ऑफ्टर कार सर्विस' बाजार में क्या है VehicleCare की दावेदारी? अरविंद वर्मा से खास...</h6>
                  
                  <a href="https://navbharattimes.indiatimes.com/auto/car-bikes/conversation-with-vehiclecare-cofounder-arvind-verma/articleshow/87187726.cms" target="_blank" rel="nofollow noreferrer" ><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://www.bhaskarhindi.com/business/vehiclecare-will-take-full-care-of-your-car-now-get-your-vehicle-serviced-at-home-936462" target="_blank" rel="nofollow noreferrer"><img src="https://www.bhaskarhindi.com/h-upload/2023/05/30/773182-screenshot35.webp" alt="VehicleCare रखेगा आपकी कार का पूरा ध्यान, अब घर बैठे-बैठे कराए अपनी गाड़ी की सर्विसिंग" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>VehicleCare रखेगा आपकी कार का पूरा ध्यान, अब घर बैठे-बैठे कराए अपनी गाड़ी की सर्विसिंग</h6>
                  
                  <a href="https://www.bhaskarhindi.com/business/vehiclecare-will-take-full-care-of-your-car-now-get-your-vehicle-serviced-at-home-936462" target="_blank" rel="nofollow noreferrer" ><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://www.mid-day.com/brand-media/article/vehiclecare-and-mtc-group-pioneer-national-movement-for-responsible-vehicle-23293526" target="_blank" rel="nofollow noreferrer"><img src="https://images.mid-day.com/images/images/2023/jun/MTC-Group-2106_d.jpg" alt="VehicleCare & MTC Group Pioneer National Movement for Responsible Vehicle Disposal" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>VehicleCare & MTC Group Pioneer National Movement for Responsible Vehicle Disposal</h6>
                  
                  <a href="https://www.mid-day.com/brand-media/article/vehiclecare-and-mtc-group-pioneer-national-movement-for-responsible-vehicle-23293526" target="_blank" rel="nofollow noreferrer" ><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>

            <div className="service-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image">
                  <a href="https://www.mid-day.com/brand-media/article/vehiclecareinyour-monsoon-season-companion-for-optimal-vehicle-maintenance-23301014" target="_blank" rel="nofollow noreferrer"><img src="https://images.mid-day.com/images/images/2023/jul/Total-Enginers3107_d.jpg" alt="VehicleCare.in: Your Monsoon Season Companion for Optimal Vehicle Maintenance" /></a> 
                </div>

                <div className="lower-content">
                  <h6><span>\<i>\</i></span>VehicleCare.in: Your Monsoon Season Companion for Optimal Vehicle Maintenance</h6>
                  
                  <a href="https://www.mid-day.com/brand-media/article/vehiclecareinyour-monsoon-season-companion-for-optimal-vehicle-maintenance-23301014" target="_blank" rel="nofollow noreferrer" ><div className="quote-btn"><span className="arrow fa fa-angle-double-right"></span>Read More</div></a>
                </div>
              
              </div>
            </div>
            
          </div>
        </div>
      </section>
      </Row>

      <Footer />
    </>
  );
};

export default RecentNews;
