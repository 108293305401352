import React, { useState } from 'react';
import { Row } from "react-bootstrap";
import "./index.scss";
import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import { Helmet } from "react-helmet";
// import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {
  const [activeId, setActiveId] = useState('0');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <>
      <Helmet>
        <title>Booking A Service Through An App Or Mobile - Responsive Website</title>
        <meta name="description" content="Download The VehicleCare App From The Play Store, Log in With Your Registered Mobile Number, And Navigate To The Order Section And Check FAQ" />
      </Helmet>

      <Header isStatic={true} />

      <Row>
      {/* <section classNameName="faq-page-section acordian-section mt-3 pb-0" id="faq-page">
        <div classNameName="auto-container">

          <div classNameName="row clearfix">
            <div classNameName="accordian-column col-lg-12 col-md-12 col-sm-12">
              <div classNameName="inner-column">
                <div classNameName="sec-title centered"><div classNameName="title">Frequently Asked Questions</div><h2>Just Find Your Answers Below</h2></div>

                <ul classNameName="accordion-box">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is VehicleCare?</Accordion.Header>
                      <Accordion.Body>
                        VehicleCare offers high-quality luxury car services at an affordable price, restoring your trust in car maintenance through fair pricing and transparent processes. Experience live updates throughout the service period.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Why choose VehicleCare?</Accordion.Header>
                      <Accordion.Body>
                        As a market leader in car servicing, repairs, and insurance claims, VehicleCare stands as your comprehensive solution. With a trusted brand reputation, we've served over 1.5 lakh customers across India through our 1000+ workshops. Expect 100% support and real-time updates via our App, website, and hotline.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>How can I book a car service with VehicleCare?</Accordion.Header>
                      <Accordion.Body>
                        Booking a service is effortless through our modern App or mobile-responsive website. Alternatively, call us at 9911-020-209. Our services are available 24/7 in all our operational cities.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What services are available at VehicleCare Workshops?</Accordion.Header>
                      <Accordion.Body>
                        At VehicleCare, find all-inclusive car services, repairs, and insurance claim assistance. Our expert mechanics and state-of-the-art workshops ensure a smooth return to the road for your vehicle. 
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>In how many cities is VehicleCare available?</Accordion.Header>
                      <Accordion.Body>
                        VehicleCare operates in over 200 cities across India, boasting more than 1000 workshops dedicated to car servicing, repairs, and insurance claims.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Does VehicleCare provide car pickup, drop, and doorstep service?</Accordion.Header>
                      <Accordion.Body>
                        Absolutely. Enjoy free pickup and drop services at your doorstep. Additionally, we offer doorstep car inspection and basic periodic servicing for your convenience.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>How can I track the status of my car service?</Accordion.Header>
                      <Accordion.Body>
                        Download the VehicleCare App from the Play Store, log in with your registered mobile number, and navigate to the order section. Real-time updates related to your service will be readily available.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Do VehicleCare workshops support online payments? </Accordion.Header>
                      <Accordion.Body>
                        Yes, our workshops facilitate online payments. Choose from wallet or card payment options for added convenience.
                      </Accordion.Body>
                    </Accordion.Item>
                    
                  </Accordion>
                
                </ul>
                
              </div>
            </div>
          </div>

        </div>
      </section> */}


        <section className="faq-page-section mt-4 pb-4" id="faq-page">
          <div className="auto-container">
            <div className="row clearfix">
              
              <div className="accordian-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
        
                  <div className="sec-title">
                    <div className="title">Frequently Asked Questions</div>
                    <h2>Just Find Your Answers Below</h2>
                  </div>
          
                  <ul className="accordion-box" defaultActiveKey={activeId}>
                    
                    <li className={activeId === '0' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '0' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('0')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>What is VehicleCare
                      </div>
                      <div className="acc-content" eventKey="0">
                        <div className="content">
                          <div className="accordian-text">VehicleCare offers high-quality luxury car services at an affordable price, restoring your trust in car maintenance through fair pricing and transparent processes. Experience live updates throughout the service period. </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '1' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '1' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('1')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>Why choose VehicleCare?
                      </div>
                      <div className="acc-content" eventKey="1">
                        <div className="content">
                          <div className="accordian-text">As a market leader in car servicing, repairs, and insurance claims, VehicleCare stands as your comprehensive solution. With a trusted brand reputation, we've served over 1.5 lakh customers across India through our 1000+ workshops. Expect 100% support and real-time updates via our App, website, and hotline. </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '2' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '2' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('2')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>How can I book a car service with VehicleCare?
                      </div>
                      <div className="acc-content" eventKey="2">
                        <div className="content">
                          <div className="accordian-text">Booking a service is effortless through our modern App or mobile-responsive website. Alternatively, call us at 9911-020-209. Our services are available 24/7 in all our operational cities. </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '3' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '3' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('3')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>What services are available at VehicleCare Workshops?
                      </div>
                      <div className="acc-content" eventKey="3">
                        <div className="content">
                          <div className="accordian-text">At VehicleCare, find all-inclusive car services, repairs, and insurance claim assistance. Our expert mechanics and state-of-the-art workshops ensure a smooth return to the road for your vehicle. </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '4' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '4' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('4')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>In how many cities is VehicleCare available?</div>
                      <div className="acc-content" eventKey="4">
                        <div className="content">
                          <div className="accordian-text">VehicleCare operates in over 200 cities across India, boasting more than 1000 workshops dedicated to car servicing, repairs, and insurance claims. </div>
                        </div>
                      </div>
                    </li>
                    
                    <li className={activeId === '5' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '5' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('5')}>
                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>Does VehicleCare provide car pickup, drop, and doorstep service?
                      </div>
                      <div className="acc-content" eventKey="5">
                        <div className="content">
                          <div className="accordian-text">Absolutely. Enjoy free pickup and drop services at your doorstep. Additionally, we offer doorstep car inspection and basic periodic servicing for your convenience. </div>
                        </div>
                      </div>
                    </li>

                    <li className={activeId === '6' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '6' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('6')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>How can I track the status of my car service?</div>
                      <div className="acc-content" eventKey="6">
                        <div className="content">
                          <div className="accordian-text">Download the VehicleCare App from the Play Store, log in with your registered mobile number, and navigate to the order section. Real-time updates related to your service will be readily available. </div>
                        </div>
                      </div>
                    </li>

                    <li className={activeId === '7' ? 'accordion block active-block' : 'accordion block'}>
                      <div className={activeId === '7' ? 'acc-btn active' : 'acc-btn'} onClick={() => toggleActive('7')}><div className="icon-outer"><span className="icon icon-plus fa fa-plus"></span> <span className="icon icon-minus fa fa-minus"></span></div>Do VehicleCare workshops support online payments?</div>
                      <div className="acc-content" eventKey="7">
                        <div className="content">
                          <div className="accordian-text">Yes, our workshops facilitate online payments. Choose from wallet or card payment options for added convenience. </div>
                        </div>
                      </div>
                    </li>
                    
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </Row>

      <Footer />
    </>
  );
};

export default Faq;
