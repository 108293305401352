import { postLoginApiRequest, loginApiRequest } from "../../server";
import { User_Login, User_SignUp, New_User_Login, SHOW_HIDE_LOADER, Sign_Out, USER_PROFILE, UPDATE_VEHILCE } from "../types";

// eslint-disable-next-line import/prefer-default-export
const userLogin = (params, dispatch) => {
  loginApiRequest(params, (responseData) => {
    if (responseData && responseData.message === "No User Exist") {
      dispatch({ type: New_User_Login, payload: params.phone });
    } else {
      dispatch({ type: User_Login, payload: responseData.data });
    }
    dispatch({ type: SHOW_HIDE_LOADER, payload: false });
  });
};
const userSignup = (params, dispatch) => {
  loginApiRequest(params, (responseData) => {
    dispatch({ type: User_SignUp, payload: responseData.data });
    dispatch({ type: SHOW_HIDE_LOADER, payload: false });
  });
};
const userDelete = (params, dispatch) => {
  postLoginApiRequest(params, (responseData) => {
    dispatch({ type: Sign_Out, payload: responseData });
  });
};
const getUserProfile = (params, dispatch) => {
  postLoginApiRequest(params, (responseData) => {
    dispatch({ type: USER_PROFILE, payload: responseData.data });
  });
};
const updateUserVehicle = (params, dispatch) => {
  postLoginApiRequest(params, (responseData) => {
    dispatch({ type: UPDATE_VEHILCE, payload: responseData.data });
  });
};
export { userLogin, userSignup, userDelete, getUserProfile, updateUserVehicle };
