import { preLoginApiRequest } from "../../server";
import { SERVICE_LIST } from "../types";

// eslint-disable-next-line import/prefer-default-export
const getServiceCateogaries = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({type: SERVICE_LIST, payload: responseData.data});
  });
};
export { getServiceCateogaries };
