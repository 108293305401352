import { insurancePostLoginApiRequest } from "../../server";
import { INSURANCE_COMPANY_LIST } from "../types";

const getInsuranceCompanies = (params, dispatch) => {
  insurancePostLoginApiRequest(params, (responseData) => {
    dispatch({ type: INSURANCE_COMPANY_LIST, payload: responseData.data });
  });
};

export { getInsuranceCompanies };
