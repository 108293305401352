import React, { useState } from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { Appbg,  VehiclecareB1, VehiclecareB2, VehiclecareB3, VehiclecareB4, VehiclecareB5, VehiclecareB6, VehiclecareB7, VehiclecareB8, VehiclecareB9, VehiclecareB10, VehiclecareB11, VehiclecareB12, VehiclecareB13, VehiclecareB14,
  ACRepair, Battery, CarWash, CustumRepair, DentingPainting, InsuranceClaimLanding, PeriodicService, WheelCare 
 } from "./../../../constants/ImageConstants";

import OurApp from "./../../../components/common/ourApp";

import Header from "../../../components/NavigationBar/Header";
import Footer from "../../../components/NavigationBar/Footer";
import Testimonial from "../../../components/common/Testimonial";
import VcBenifits from "../Landing/VcBenifits";

import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import LazyLoad from 'react-lazy-load';

const Cars24 = (props) => {
  
  const [toggle, setToggle] = useState(0);
  let handleToggle = (id) => {
    if (toggle === id) {
      setToggle(null);
      return false;
    }
    setToggle(id);
  };
  
  return (
    <>
      <Helmet>
        <title>Experience The High Quality Service Off All Time In India</title>
        <meta name="description" content="Can You Serach The Best High Quality Service Off All Time - VehicleCare Provide The Best Discount And Offer On Services"
        data-react-helmet="true" />

        <meta name="robots" content="noindex,nofollow" />

        <meta property="og:title" content="Can You Search For A Car Service Near Me - Vehiclecare" />
        <meta property="og:description" content="Can You Serach The Best High Quality Service Off All Time - VehicleCare Provide The Best Discount And Offer On Services" />
        <meta property="og:url" content={`https://vehiclecare.in/car-mechanic-near-me`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
        <meta property="twitter:title" content="Can You Search For A Car Service Near Me - Vehiclecare" />
        <meta property="twitter:description" content="Can You Serach The Best High Quality Service Off All Time - VehicleCare Provide The Best Discount And Offer On Services" />
        <meta property="twitter:url" content={`https://vehiclecare.in/car-mechanic-near-me`} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />

        

      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-5">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Services We Offer</h2>
              <div className="text col-8 mx-auto d-none d-md-block">
              Find the best service packages for your car. Select from a wide range of car services, from general service to
              <a href="https://vehiclecare.in/car-wash-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24">  car wash, </font></a>
              accidental repair to custom repair, cashless insurance claims, and much more.
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <div className="col-lg-10 col-md-11 col-sm-12">
                <div className="row">
                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={CarWash} alt="Car Service" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                        
                            {" "}
                            Car Service{" "}
                         
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={WheelCare} alt="Wheel Care" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                            {" "}
                            Wheel Care
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={ACRepair} alt="Car AC Repair" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                            {" "}
                            Car AC Repair{" "}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={Battery} alt="Car Battery" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                            {" "}
                            Car Battery{" "}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right border-top">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={DentingPainting} alt="Denting & Painting" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                            {" "}
                            Denting & Painting{" "}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right border-top">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={CustumRepair} alt="Custom Repair" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                            {" "}
                            Custom Repair{" "}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right border-top">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={PeriodicService} alt="Periodic Service" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                            {" "}
                            Periodic Service
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-top">
                    <div className="inner-box text-center border-0">
                      <div className="">
                          <img className="w-35 mx-auto pt-4" src={InsuranceClaimLanding} alt="Car Insurance Claim" />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                            {" "}
                            Insurance Claim{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*brand Section*/}
        <section className="sponsors-section style-three">
          <div className="container-fluid text-center" id="splideCarousel">
            <div className="carousel-outer pb-5 d-none d-md-block">
              <Splide
                options={ {
                  updateOnMove: true,
                  type        : 'loop',
                  drag   : 'free',
                  perPage     : 7,
                  perMove     : 1,
                  focus       : 0,
                  interval: 2000,
                  speed: 400,
                  pagination : false,
                  autoplay: "play",
                  autoScroll: {
                    speed: 1,
                  },
                  
                } }
              >
              
                <SplideSlide><img src={VehiclecareB1} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB2} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB3} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB4} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB5} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB6} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB7} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB8} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB9} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB10} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB11} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB12} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB13} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB14} alt="" /></SplideSlide>
                
              </Splide>
            </div>

            <div className="carousel-outer d-md-none">
              <Splide
                options={ {
                  type   : 'loop',
                  drag   : 'free',
                  focus  : 'center',
                  interval: 2000,
                  speed: 200,
                  autoplay: "play",
                  pagination : false,
                  perPage: 3,
                  autoScroll: {
                    speed: 1,
                  },
                } }
              >
              
                <SplideSlide><img src={VehiclecareB1} alt="" /></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB2} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB3} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB4} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB5} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB6} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB7} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB8} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB9} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB10} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB11} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB12} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB13} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB14} alt="" /></LazyLoad></SplideSlide>
                
              </Splide>
            </div>
          </div>
        </section>
        {/* End brand Section */}

        <div className="price-section" style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name="Gurgaon" />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>


      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(Cars24);
