import style from 'styled-components';

export const Avatar = style.img`
    width: ${props => props.width || '42px'};
    min-width: ${props => props.width || '42px'};
    height: ${props => (props.height ? props.height : props.width || '42px')};
    border-radius: ${props => props.borderRadius || '50%'};
    background-size: cover;
    background-position: 50%;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    object-fit: cover;
    margin: ${props => props.margin || '2px'};
    float: ${props => props.float || ''};
    ${props => props.customStyle};
`;

export default Avatar;
